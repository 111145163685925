import React from 'react'
import styles from "./communities-table-head.module.scss"

const CommunitiesTableHead = () => {
  return (
    <div className={ styles["communities__table__head"] }>
        <div className={ styles["communities__table__head__grid"] }>
            <div>Community Name</div>
            <div>Created By</div>
            <div>Members</div>
            <div>Status</div>
            <div>Action</div>
        </div>
    </div>
  )
}

export default CommunitiesTableHead