import { useState, useContext, useEffect } from "react";
import moment from "moment";
import styles from "./user.module.scss";
import profile__icon from "../../assets/images/user.png";
import { FaUserCog } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import AdminContext from "@/context/AdminContext/AdminContext";
import { toast } from "react-toastify";
import PointsModal from "../PointsModal";

const User = ({ singleUser }) => {
  const {
    disableUserAccount,
    enableUserAccount,
    fetchAllUsers,
    makeUserAdmin,
    makeAdminUser,
  } = useContext(AdminContext);

  const pushToSingleUser = (id) => {
    window.location.href = `/dashboard/users/${id}`;
  };

  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(false);
  };

  //Suspend Account
  const suspendAccount = async (id) => {
    try {
      await disableUserAccount({ userId: id });
      toast.success("Account successfully suspended..!");
      await fetchAllUsers();
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        toast.error("User is already suspended..!");
      }
    }
  };

  //Suspend Account
  const enableAccount = async (id) => {
    try {
      await enableUserAccount({ userId: id });
      toast.success("Account successfully unsuspended..!");
      await fetchAllUsers();
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        toast.error("User is already suspended..!");
      }
    }
  };

  //Make a user an admin
  const makeAdmin = async (email) => {
    try {
      await makeUserAdmin({ email: email });
      toast.success("User status updated..!");
      await fetchAllUsers();
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        toast.error("Error making a user as an admin..!");
      }
    }
  };

  //Make a user an admin
  const makeUser = async (email) => {
    try {
      await makeAdminUser({ email: email });
      toast.success("User status updated..!");
      await fetchAllUsers();
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        toast.error("Error making an admin as a user..!");
      }
    }
  };

  return (
    <div className={styles["user"]}>
      {singleUser ? (
        <>
          <span className={styles["user__first__element"]}>
            {singleUser?.avatar ? (
              <img
                src={singleUser?.avatar}
                className={styles["user__img"]}
                alt="user profile"
              />
            ) : (
              <img
                src={profile__icon}
                className={styles["user__img"]}
                alt="user profile"
              />
            )}
            {singleUser?.fullName}
          </span>
          <span>{singleUser?.email}</span>
          {/* <span>{ singleUser?.role }</span> */}
          <span>{moment(singleUser?.createdAt).format("Do MMMM YYYY")}</span>

          <span
            className={
              singleUser?.isSuspended === false
                ? styles["user__active"]
                : styles["user__inactive"]
            }
          >
            {singleUser?.isSuspended === false ? "Active" : "Disabled"}
          </span>
          <span className={styles["user__actions"]}>
            {/* 
       <span onClick={ async () => await suspendAccount(singleUser?.id)} style={{ cursor: "pointer" }} className={ singleUser?.isSuspended === false ? styles["user__action--danger"] : styles["user__action--success"] }>{ singleUser?.isSuspended === false ? "Suspend" : "Enable" }</span> */}
            {singleUser?.role ? (
              <span
                onClick={() => {
                  if (singleUser?.role === "USER") {
                    makeAdmin(singleUser?.email);
                  } else if (singleUser?.role === "ADMIN") {
                    makeUser(singleUser?.email);
                  }
                }}
                style={{ cursor: "pointer" }}
                className={styles["user__action--success"]}
              >
                {singleUser?.role !== "ADMIN" ? "Make Admin" : "Disable Admin"}
              </span>
            ) : null}{" "}
            &nbsp;&nbsp;
            {singleUser?.isSuspended === false ? (
              <span
                onClick={async () => await suspendAccount(singleUser?.id)}
                style={{ cursor: "pointer" }}
                className={styles["user__action--danger"]}
              >
                {singleUser?.isSuspended === false ? "Suspend" : "Enable"}
              </span>
            ) : (
              <span
                onClick={async () => await enableAccount(singleUser?.id)}
                style={{ cursor: "pointer" }}
                className={styles["user__action--success"]}
              >
                {singleUser?.isSuspended === false ? "Suspend" : "Enable"}
              </span>
            )}{" "}
            &nbsp;&nbsp;
            <span
              onClick={() => pushToSingleUser(singleUser?.id)}
              className={styles["user__actions--action"]}
            >
              <FaUserCog size="1.5rem" />
            </span>
            &nbsp;&nbsp;
            <span
              onClick={() => setShowModal(true)}
              className={styles["user__actions--action"]}
            >
              <GiReceiveMoney size="1.5rem" />
            </span>
          </span>

          {showModal ? (
            <PointsModal
              singleUser={singleUser}
              handleClick={handleClick}
              buttonText="Allocate to user"
            />
          ) : null}
        </>
      ) : (
        <p>No users yet</p>
      )}
    </div>
  );
};

export default User;
