import { useContext, useState } from "react";
import styles from "./create_quiz.module.scss";
import { IQuestion } from "@/utils/interface";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";

interface IProps {
  isQuizEdit: boolean;
  quizQuestion: IQuestion;
  setQuizQuestionType: any;
  setCurrentQuizQuestion: any;
  setCurrentTab: any;
  handleDeleteQuestion: any;
  handleQuestionCollapse: any;
}

const DisplayQuestion = ({
  isQuizEdit,
  quizQuestion,
  setQuizQuestionType,
  setCurrentQuizQuestion,
  setCurrentTab,
  handleDeleteQuestion,
  handleQuestionCollapse,
}: IProps) => {
  const correctAnswer = quizQuestion.options.filter(
    (option) => option.isCorrect
  );

  const handleQuizEdit = () => {
    setCurrentQuizQuestion(quizQuestion);
    setQuizQuestionType("edit");
    setCurrentTab("question");
  };

  return (
    <>
      <div className={styles["module__module_tab"]}>
        <h1 className={styles["module__module_tab__title"]}>
          Question {quizQuestion.index + 1}
        </h1>
        <div>
          {quizQuestion.show ? (
            <IoIosArrowDropup
              size="1.3rem"
              cursor="pointer"
              onClick={() => handleQuestionCollapse(quizQuestion.index)}
            />
          ) : (
            <IoIosArrowDropdown
              size="1.3rem"
              cursor="pointer"
              onClick={() => handleQuestionCollapse(quizQuestion.index)}
            />
          )}
          {isQuizEdit && (
            <>
              &nbsp;&nbsp;&nbsp;
              <MdModeEditOutline
                size="1.3rem"
                cursor="pointer"
                onClick={handleQuizEdit}
              />
              &nbsp;&nbsp;&nbsp;
              <MdDelete
                size="1.3rem"
                cursor="pointer"
                fill="red"
                onClick={() => handleDeleteQuestion(quizQuestion.id!)}
              />
            </>
          )}
        </div>
      </div>

      {quizQuestion.show && (
        <div className={styles["module__module_display"]}>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Quiz {quizQuestion.index + 1}</label>
            <input
              type="text"
              disabled={true}
              placeholder="What is your question?"
              className={styles["module__module_display_input"]}
              defaultValue={quizQuestion.text}
            />
          </div>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Setup options for users to select from</label>
            {quizQuestion.options.map((option, optionIndex) => (
              <>
                <input
                  type="text"
                  placeholder="Option 1"
                  className={styles["module__module_display_input"]}
                  defaultValue={option.text}
                  disabled={true}
                />
              </>
            ))}
          </div>
          <div>
            {quizQuestion.options.length > 0 ? (
              <>
                <label htmlFor="name">Answer (s)</label>
                {correctAnswer.map((answer) => (
                  <input
                    type="text"
                    placeholder="Option 1"
                    className={styles["module__module_display_input"]}
                    defaultValue={answer.text}
                    disabled={true}
                  />
                ))}
              </>
            ) : null}
          </div>
          {/* <button className={styles["module__button"]}>Save Quiz</button> */}
        </div>
      )}
    </>
  );
};

export default DisplayQuestion;
