import React from 'react'
import styles from "./users.module.scss"
import UsersWrapper from '@/components/UsersWrapper'

const Users = () => {

  return (
    <div className={ styles["users"] }>
      <h2>Users</h2>
      <p>Manage your users and their permissions here</p>

      
      <UsersWrapper />
    </div>
  )
}

export default Users