import React from "react";
import styles from "./community-card.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Prompt from "../Prompt";
import { useState, useContext } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import AlertContext from "@/context/Alert/AlertContext";

const CommunityCard = ({ community }) => {
  const { deleteCommunity, fetchAllCommunities } = useContext(AdminContext);
  const { setSuccessAlert } = useContext(AlertContext);

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const redirect = (id) => {
    navigate(`/dashboard/communities/${id}`);
  };

  const handleClick = () => {
    setShowModal((showModal) => !showModal);
  };

  const members = ` ${community?.totalUsersJoined} members `;

  const handleDelete = async (id) => {
    await deleteCommunity(id);
    fetchAllCommunities();
    setSuccessAlert("Community successfully deleted");
  };

  return (
    <div className={styles["community__card"]}>
      {showModal ? (
        <Prompt
          id={community?.id}
          title="Delete Community"
          body="Your are about to delete this community and this action is irreversible as the community will be erased from the database"
          handleClick={handleClick}
          handleDelete={handleDelete}
          buttonText="Confirm"
        />
      ) : null}
      <div className={styles["community__card__header"]}>
        <h3>{community?.name}</h3>
      </div>
      <div className={styles["community__card__body"]}>
        <small>
          Created: {moment(community?.createdAt).format("Do MMMM YYYY")}
        </small>
        <p>Created By: {community?.owner?.fullName}</p>
        <div className={styles["community__card__body--tabs"]}>
          <small
            className={styles["community__card__body--tabs--item--visibility"]}
          >
            Visibility: {community?.visibility}
          </small>
          <small
            className={styles["community__card__body--tabs--item--members"]}
          >
            {members}{" "}
          </small>
          <small
            className={styles["community__card__body--tabs--item--status"]}
          >
            Status: {community?.status}
          </small>
          <small
            onClick={() => handleDelete(community?.id)}
            className={styles["community__card__body--tabs--item--delete"]}
          >
            Delete Community
          </small>
          <small
            onClick={() => redirect(community?.id)}
            className={styles["community__card__body--tabs--item--details"]}
          >
            See Details
          </small>
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
