import React from "react";
import styles from "./points-modal.module.scss";
import { MdCancel } from "react-icons/md";
import { useContext, useState, useEffect } from "react";
import user from "../../assets/images/user1.svg";
import AdminContext from "@/context/AdminContext/AdminContext";
import AlertContext from "@/context/Alert/AlertContext";
// import { useNavigate } from 'react-router-dom'

const PointsModal = ({ singleUser, handleClick, buttonText }) => {
  const { setSuccessAlert } = useContext(AlertContext);

  const { topUpPoints, exchangeRates } = useContext(AdminContext);

  // const navigate = useNavigate()

  const rates = exchangeRates[0];

  const topUserPoints = async () => {
    try {
      await topUpPoints(formData);
      setSuccessAlert("Point issued successfully");
      handleClick();
    } catch (error) {
      console.log(error);
    }
  };

  const [formData, setFormData] = useState({
    amount: "0",
    userId: singleUser?.id,
  });

  //Onchange
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const { amount } = formData;

  return (
    <div className={styles["prompt"]}>
      <div className={styles["prompt__content"]}>
        <span onClick={() => handleClick()}>
          <MdCancel size="1.4rem" fill="#333" />
        </span>
        <div className={styles["prompt__content__detaills"]}>
          <h4>Allocate Points</h4>
          <div className={styles["prompt__content__detaills--user"]}>
            <div>
              {singleUser?.avatar === "" ? (
                <img
                  src={user}
                  alt="user"
                  className={styles["prompt__content__detaills--user--img"]}
                />
              ) : (
                <img
                  src={singleUser?.avatar}
                  alt="user"
                  className={styles["prompt__content__detaills--user--img"]}
                />
              )}
            </div>
            <div>
              <h5>{singleUser?.fullName}</h5>
              <h6>{singleUser?.email}</h6>
            </div>
          </div>

          <div className={styles["prompt__content__detaills--input"]}>
            <div
              className={styles["prompt__content__detaills--input--details"]}
            >
              <form>
                <input
                  type="text"
                  id="amount"
                  value={amount}
                  onChange={onChange}
                />
              </form>
              {/* <p>Balance: 14,521,000 points</p> */}
            </div>
            <div>
              <h3>Points</h3>
            </div>
          </div>

          <div className={styles["prompt__content__detaills--conversions"]}>
            <div>
              <p>Redeem conversion rate:</p>
              <p>
                1 Point = {rates?.value} {rates?.network}
              </p>
            </div>
            {/* <div>
                    <p>Redeem conversion rate:</p>
                    <p>100 Points = 1 VLX</p>
                </div> */}
          </div>
        </div>
        <div
          onClick={topUserPoints}
          type="submit"
          className={
            amount === "00.00"
              ? styles["prompt__content__detaills--btn"]
              : styles["prompt__content__detaills--success"]
          }
        >
          {buttonText}
        </div>
      </div>
    </div>
  );
};

export default PointsModal;
