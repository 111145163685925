import React from "react";
import styles from "./quiz.module.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PointsModal from "../PointsModal";
import moment from "moment";

const DailyQuizSubmission = ({ quiz }) => {
  const navigate = useNavigate();

  const pushToCreate = (id) => {
    navigate(`/dashboard/daily-quiz/${id}`);
  };

  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(false);
  };

  return (
    <div className={styles["quiz"]}>
      <>
        <span className={styles["quiz__first__element"]}>
          {quiz?.description.slice(0, 20)}{" "}
          {quiz?.description.length > 20 ? "..." : ""}
        </span>
        <span>{moment(quiz?.createdAt).format("Do MMMM YYYY")}</span>
        <span>{quiz?._count?.submissions}</span>

        <span className={styles["quiz__actions"]}>
          <span
            onClick={async () => pushToCreate(quiz?.id)}
            style={{ cursor: "pointer" }}
            className={styles["quiz__action--success"]}
          >
            View
          </span>
        </span>

        {showModal ? (
          <PointsModal
            quiz={quiz}
            handleClick={handleClick}
            buttonText="Allocate to user"
          />
        ) : null}
      </>
    </div>
  );
};

export default DailyQuizSubmission;
