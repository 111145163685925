import React from 'react'
import styles from "../StatsCardWrapper/stats-card-wrapper.module.scss"
import StatsCard from '@/components/StatsCard'
import { HiUsers } from "react-icons/hi2"
// import { MdOutlineDashboardCustomize } from "react-icons/md"
import { useContext, useEffect } from 'react'
import AdminContext from '@/context/AdminContext/AdminContext'

const CommunitiesStatsCardWrapper = () => {

  const { adminDashboard, getAdminDashboard } = useContext(AdminContext)

  useEffect(() => {
    getAdminDashboard()
    // eslint-disable-next-line
  }, [])

  const { communityStat } = adminDashboard
  
  return (
    <div className={ styles["stats__card__wrapper"] }>
         <div className={ styles["stats__card__wrapper--content"] }>
            <StatsCard 
                title='All Communities' 
                value={ communityStat?.allCommunities } 
                icon={ <HiUsers size="1.5rem" fill='#22bb33' color='red' /> } 
                />
                <StatsCard 
                title='Total Communities Last Month' 
                value={ communityStat?.totalCommunitiesLastMonth } 
                icon={ <HiUsers size="1.5rem" fill='#721C24' color='red' /> } 
                />
                <StatsCard 
                title='Total Communities This Month' 
                value={ communityStat?.totalCommunitiesThisMonth } 
                icon={ <HiUsers size="1.5rem" fill='#3F434A' color='red' /> } 
                />
         </div>
    </div>
  )
}

export default CommunitiesStatsCardWrapper