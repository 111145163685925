import { useState, useContext, useEffect } from "react";
import AdventuresDetails from "@/components/EditAdventure/AdventureDetails";
import AboutAdventure from "@/components/EditAdventure/AboutAdeventure";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./create.module.scss";
import {
  AdventureDifficultyLevel,
  ICreateAdventure,
  IModule,
} from "@/utils/interface";
import ModuleAdventure from "@/components/EditAdventure/ModuleAdventure";
import AdventureContext from "@/context/Adventure/AdventureContext";
import Loader from "@/components/Loader";
import { BsArrowLeft } from "react-icons/bs";

const initialModule: IModule[] = [
  {
    index: 0,
    show: true,
    name: "",
    description: "",
    lessons: [
      {
        index: 0,
        show: true,
        name: "",
        text: "",
        videoUrl: "",
        quizzes: [],
      },
    ],
  },
];

const Edit = () => {
  const { adventureId } = useParams();

  const navigate = useNavigate();

  const {
    adventure,
    fetchSingleAdventure,
    fetchModulesByAdventure,
    fetchLessonsByModule,
    fetchTaskByModule,
  } = useContext(AdventureContext);

  const [activeTab, setActiveTab] = useState<"details" | "about" | "modules">(
    "details"
  );

  const [adventureImage, setAdventureImage] = useState({
    imageUrl: "",
    image: {},
    imageChanged: false,
  });

  const [adventureData, setAdventureData] = useState<ICreateAdventure>({
    id: null,
    name: "",
    imageUrl: "",
    description: "",
    difficultyLevel: AdventureDifficultyLevel.BEGINNER,
    gains: "",
    earnings: "",
    expectations: "",
    rewardPoint: 0,
    badgeId: ""
  });

  const [modules, setModules] = useState<IModule[]>(initialModule);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchSingleAdventure(adventureId).then((res: any) => {
      setAdventureData({
        id: adventure?.id,
        name: res.name,
        imageUrl: res.imageUrl,
        description: res.description,
        difficultyLevel: res.difficultyLevel,
        gains: res.gains,
        earnings: res.earnings,
        expectations: res.expectations,
        rewardPoint: res.rewardPoint,
        badgeId: res.badgeId
      });
      setAdventureImage({
        ...adventureImage,
        imageUrl: res.imageUrl,
      });
    });
    fetchModulesByAdventure(adventureId).then(async (res: any) => {
      const modules = res?.result?.sort((a: any, b: any) => a.index - b.index);
      const newModules = [];
      for (let moduleIndex = 0; moduleIndex < modules.length; moduleIndex++) {
        const module = modules[moduleIndex];
        let lessons: any = [];
        let task: any = {};
        await fetchLessonsByModule(module.id).then((res: any) => {
          const lessonFetched = res?.result?.sort(
            (a: any, b: any) => a.index - b.index
          );
          for (
            let lessonIndex = 0;
            lessonIndex < lessonFetched.length;
            lessonIndex++
          ) {
            const lesson = lessonFetched[lessonIndex];

            const quizzes = lesson?.Quiz[0]?.questions.map(
              (question: any, index: number) => {
                return {
                  id: question.id,
                  index,
                  show: false,
                  text: question.text || "",
                  options: question.options || [],
                };
              }
            );
            lessons.push({
              id: lesson.id,
              index: lessonIndex,
              show: false,
              name: lesson.name || "",
              text: lesson.text || "",
              videoUrl: lesson.video.url || "",
              quizId: lesson?.Quiz[0]?.id,
              quizzes,
            });
          }
        });
        await fetchTaskByModule(module.id).then((res: any) => {
          task = {
            id: res?.id,
            isActive: res ? true : false,
            show: false,
            link: res ? res.linkUrl : "",
            text: res ? res.description : "",
          };
        });
        newModules.push({
          id: module.id,
          index: moduleIndex,
          show: false,
          name: module.name,
          description: module.description,
          lessons,
          task,
        });
      }
      setModules(newModules);
      setIsLoading(false);
    });
  }, [adventureId, isChanged]);

  const handleActiveTab = (tab: "details" | "about" | "modules") => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    navigate(`/dashboard/adventure/${adventureId}`);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles["create"]}>
      <h5 className={styles["create__back"]} onClick={handleBack}>
        <BsArrowLeft /> Back
      </h5>
      <br />
      <h2 className={styles["create__header"]}>Edit adventure</h2>

      <div className={styles["create__content"]}>
        <div className={styles["create__tabs"]}>
          <span
            onClick={() => handleActiveTab("details")}
            data-active={activeTab === "details"}
          >
            Adventure Details
          </span>
          <span
            onClick={() => {
              handleActiveTab("about");
            }}
            data-active={activeTab === "about"}
          >
            About Adventure
          </span>
          <span
            onClick={() => adventure?.id && handleActiveTab("modules")}
            data-active={activeTab === "modules"}
          >
            Modules
          </span>
        </div>

        <div className={styles["create__form"]}>
          {activeTab === "details" && (
            <AdventuresDetails
              adventureData={adventureData}
              setAdventureData={setAdventureData}
              handleActiveTab={handleActiveTab}
              adventureImage={adventureImage}
              setAdventureImage={setAdventureImage}
            />
          )}
          {activeTab === "about" && (
            <AboutAdventure
              adventureData={adventureData}
              setAdventureData={setAdventureData}
              handleActiveTab={handleActiveTab}
              adventureImage={adventureImage}
              setAdventureImage={setAdventureImage}
            />
          )}
          {activeTab === "modules" && (
            <ModuleAdventure
              modules={modules}
              setModules={setModules}
              adventureId={adventureId!}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Edit;
