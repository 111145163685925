import axios from "./axios-base-url";
import { gatewayToken, decode } from "../constants";

axios.interceptors.request.use(
  async (config) => {
    const encodedToken = window.localStorage.getItem(gatewayToken);
    let accessToken = "";

    if (encodedToken) {
      try {
        accessToken = decode(encodedToken);
      } catch (error) {
        console.error("Token decoding error:", error);
        // Handle token decoding errors, possibly log out user
      }
    }

    config.headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-client-type": "web",
      "x-access-token": process.env.REACT_APP_ACCESS_TOKEN,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 Unauthorized errors (e.g., token expired)
    // if (error.response && error.response.status === 401) {
    //   // Logic for refreshing the token
    //   // Example: const newToken = await refreshTokenFunction();
    //   // if (newToken) {
    //   //   window.localStorage.setItem(gatewayToken, newToken);
    //   //   axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
    //   //   return axios(originalRequest);
    //   // }

    //   console.log("this is the error here ")
    //   // push users to yhr home page /
    //   // window.location.href = '/'
    // }

    // Handle 403 Forbidden errors
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const encodedToken = window.localStorage.getItem(gatewayToken);
      const accessToken = decode(encodedToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axios;
