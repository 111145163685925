import styles from "./dashboard-layout.module.scss";
import Sidebar from "../Sidebar";
import DashboardNavbar from "../DashboardNavbar";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  return (
    <div>
      <DashboardNavbar />
      <div className={styles["dashboard__layout"]}>
        <div className="container">
          <div className={styles["dashboard__layout__content"]}>
            <div className={styles["dashboard__layout__content--sidebar"]}>
              <Sidebar />
            </div>
            <div className={styles["dashboard__layout__content--children"]}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["quick__actions"]}></div>
    </div>
  );
};

export default DashboardLayout;
