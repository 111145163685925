import styles from "./adventure-card.module.scss";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AdventureContext from "@/context/Adventure/AdventureContext";

const AdventureCard = ({ adventure }) => {
  const { makeFeaturedAdventure } = useContext(AdventureContext);
  const navigate = useNavigate();

  const { id, name, imageUrl, level, enrollments, isFeatured } = adventure;

  return (
    <div className={styles["card"]}>
      <div className={styles["card__image"]}>
        <img src={imageUrl} alt={name} />
      </div>
      <div className={styles["card__body"]}>
        <h4>{name}</h4>
        <p className={styles["card__body__level"]}>{level} Level</p>
        <div>{enrollments} comrades have joined</div>
        <div className={styles["card__body__buttons"]}>
          <div
            onClick={() => navigate(`/dashboard/adventure/${id}`)}
            className={styles["card__body__btn"]}
          >
            Adventures Details
          </div>
          {!isFeatured && (
            <div
              onClick={async () =>
                await makeFeaturedAdventure({ adventureId: id })
              }
              className={styles["card__body__btn--feature"]}
            >
              Feature Adventure
            </div>
          )}
        </div>
        <br />
      </div>
    </div>
  );
};

export default AdventureCard;
