import { useContext, useState, useEffect } from "react";
import styles from "./adventure_module.module.scss";
import { ILesson } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdVideoLibrary } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { openUploadWidget } from "@/utils/cloudinaryService";
import AlertContext from "@/context/Alert/AlertContext";

interface IProps {
  currentLesson: ILesson;
  isChanged: boolean;
  setIsChanged: any;
  setEditType: any;
}

const EditLesson = ({
  isChanged,
  setIsChanged,
  currentLesson,
  setEditType,
}: IProps) => {
  const { updateLesson } = useContext(AdventureContext);
  const { setErrorAlert, setSuccessAlert } = useContext(AlertContext);

  const initialState = {
    index: 0,
    show: true,
    name: "",
    text: "",
    videoUrl: "",
  };
  const [formData, setFormData] = useState<ILesson>(initialState);

  useEffect(() => {
    setFormData({
      ...formData,
      index: currentLesson.index,
      show: currentLesson.show,
      text: currentLesson.text,
      name: currentLesson.name,
      videoUrl: currentLesson.videoUrl,
    });
  }, [currentLesson]);

  const handleShowUpload = async () => {
    let success = false;
    let myUploadWidget = openUploadWidget(
      {
        tags: ["adventure"],
        maxFiles: 1,
        multiple: false,
        resourceType: ["video"],
      },
      function (error: any, result: any) {
        if (!error && result.event === "success") {
          success = true;
          setSuccessAlert("Video uploaded successfully.");
          setFormData({
            ...formData,
            videoUrl: result.info.url,
          });
        } else if (result.event === "abort" && !result.info.id && !success) {
          setErrorAlert("Error uploading video..");
        } else if (
          result.event === "close" &&
          result.info.message &&
          !success
        ) {
          setErrorAlert("Upload cancelled..");
        }
      }
    );
    myUploadWidget.open();
  };

  const handleLessonTextChange = (type: string, e: any) => {
    if (type === "body") {
      setFormData({
        ...formData,
        text: e.target.value,
      });
    } else if (type === "name") {
      setFormData({
        ...formData,
        name: e.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    const data = {
      name: formData.name,
      text: formData.text,
      videoUrl: formData.videoUrl,
    };
    await updateLesson(currentLesson.id, data).then((res: any) => {
      setIsChanged(!isChanged);
    });
  };

  return (
    <>
      <h5
        className={styles["module__back"]}
        onClick={() => setEditType("normal")}
      >
        <BsArrowLeft /> Back
      </h5>
      <br />
      <h3>Edit Lesson</h3>
      <div className={styles["module__module_display"]}>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="name">Lesson Name</label>
          <input
            type="text"
            placeholder="Lesson name"
            className={styles["module__module_display_input"]}
            defaultValue={formData.name}
            onChange={(e) => handleLessonTextChange("name", e)}
          />
        </div>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="body">Body Text</label>
          <textarea
            className={styles["module__module_display_text_area"]}
            rows={8}
            name="text"
            defaultValue={formData.text}
            onChange={(e) => handleLessonTextChange("body", e)}
          ></textarea>
        </div>

        <div className={styles["module__module_display__under_tab"]}>
          <p onClick={handleShowUpload}>
            <MdVideoLibrary /> {formData.videoUrl ? "Edit Video" : "Add Video"}
          </p>
        </div>

        <button onClick={handleSubmit} className={styles["module__button"]}>
          Save Lesson
        </button>
      </div>
    </>
  );
};

export default EditLesson;
