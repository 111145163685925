import React, { useContext, useEffect, useState } from 'react'
// import ProductContext from '../../context/ProductHunt/ProductContext'
import styles from "./index.module.scss"
import {FaRegCommentDots} from "react-icons/fa"
import ProductContext from '@/context/Product/ProductContext'



const PeopleContainer = ({comment}) => {
    const {  getCommentsOnProductHuntComment } = useContext(ProductContext)
   
    const [allReply, setAllReply] = useState()

    useEffect(() => {
        const ReplyFxn = async () => {
            const rply = await getCommentsOnProductHuntComment(comment?.id)
            setAllReply(rply)
          }
        ReplyFxn()
    }, [comment?.id])

    // console.log({allReply})


    return (


        <>
            {allReply?.result?.length === 0 ? null : (
                <div className={styles.peopleContainer}>
                    <div className={styles.people}>
        
                        {allReply?.result.map((item) =>(
                            <div className={styles.usersUrl}>
                                <img src={item?.user?.avatar ? item.user.avatar : "https://res.cloudinary.com/dj0rcdagd/image/upload/v1685283952/Ellipse_159_ewbfdy.svg"} alt="user avatar" />
                            </div>
                        ))}
                        {/* <div className={styles.usersUrl}>
                            <img src="https://res.cloudinary.com/dj0rcdagd/image/upload/v1685283952/Ellipse_159_ewbfdy.svg" alt="comments" />
                        </div>
                        <div className={styles.usersUrl}>
                            <img src="https://res.cloudinary.com/dj0rcdagd/image/upload/v1685283952/Ellipse_155_gwmee1.svg" alt="comments" />
                        </div>
                        <div className={styles.usersUrl}>
                            <img src="https://res.cloudinary.com/dj0rcdagd/image/upload/v1685283952/Ellipse_156_wgxc8s.svg" alt="comments" />
                        </div>
                        <div className={styles.usersUrl}>
                            <img src="https://res.cloudinary.com/dj0rcdagd/image/upload/v1685283951/Ellipse_157_bndj6x.svg" alt="comments" />
                        </div>
                        <div>
                            <img src="https://res.cloudinary.com/dj0rcdagd/image/upload/v1685283951/Ellipse_158_lharfq.svg" alt="comments" />
                        </div> */}
                    </div>
                    <div className={styles.commentIcon}>
                        <FaRegCommentDots/>
                    </div>
                </div>
            )}
        </>
    )
}

export default PeopleContainer