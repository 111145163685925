import React from "react"
import styles from "./users-wrapper.module.scss"
import User from "../User"
import Spinner from "../Spinner"
import AdminContext from "@/context/AdminContext/AdminContext"
import { useContext, useEffect, useState } from "react"
import Search from "../Search"

const UsersWrapper = () => {
  const { 
    loading, 
    fetchAllUsers, 
    pagination, 
    getExchangeRates, 
    users,
    usersByEmail,
    usersByAddress,
    fetchAllUsersByEmail,
    fetchAllUsersByAddress,
  } =  useContext(AdminContext)
  const [searchTerm, setSearchTerm] = useState("")
  
  const [searchTermEmail, setSearchTermEmail] = useState("")

  useEffect(() => {
    fetchAllUsers(searchTerm)
    getExchangeRates()
    fetchAllUsersByEmail(searchTerm)
    fetchAllUsersByAddress(searchTerm)
  }, [searchTerm, searchTermEmail])

  
  const handleSearch = (name) => {
    setSearchTerm(name)
    setSearchTermEmail(name)
  }

  let searchByEmail = searchTerm === "" || searchTermEmail === "" ? users : usersByEmail;

  const checkAddressInUsers = usersByAddress?.user?.id
    ? users?.filter(user => user?.id === usersByAddress.user.id)
    : []
  
  const newUserList = searchByEmail?.length === 0 ? checkAddressInUsers : searchByEmail
  

  const handlePaginationPrev = async () => {
    const query = `pageNumber=${pagination?.currentPage - 1}&pageSize=${
      pagination?.pageSize
    }`
    await fetchAllUsers(query)
  }

  const handlePaginationNext = async () => {
    const query = `pageNumber=${pagination?.currentPage + 1}&pageSize=${
      pagination?.pageSize
    }`
    await fetchAllUsers(query)
  }


  return loading && users?.length === 0  ? <Spinner /> : (
    <div className={styles["users__wrapper"]}>
      <Search handleChange={handleSearch} label="user by email or address" />

      <div className={styles["users__wrapper__container"]}>
        <div className={styles["users__wrapper__grid"]}>
          <div>Name</div>
          <div>Email Address</div>
          {/* <div>User Type</div> */}
          <div>Date Added</div>
          <div>Status</div>
          <div>Action</div>
        </div>
        {/* {loading && users?.length === 0 ? (
          <Spinner />
        ) : (
          newUserList.map((user) => <User key={user.id} singleUser={user} />)
        )} */}
          
          { newUserList?.map((user) => <User key={user.id} singleUser={user} />)}
        
      </div>

      {searchTerm === "" || searchTermEmail === "" ? (
        <>
          <div className={styles["users__wrapper__pagination"]}>
            <span>
              Page {pagination?.currentPage} - {pagination?.totalPages}
            </span>
            <span>
              {(pagination?.currentPage - 1) * pagination?.pageSize + 1} -
              {pagination?.hasNext
                ? pagination?.pageSize * pagination?.currentPage
                : pagination?.totalCount}
            </span>
          </div>
          
          <div className={styles["users__wrapper__pagination"]}>
            {pagination?.hasPrevious ? (
              <button
                className={styles["users__wrapper__pagination__button"]}
                onClick={handlePaginationPrev}
                disabled={!pagination?.hasPrevious}
              >
                Prev
              </button>
            ) : null}
            {pagination?.hasNext ? (
              <button
                className={styles["users__wrapper__pagination__button"]}
                onClick={handlePaginationNext}
                disabled={!pagination?.hasNext}
              >
                Next
              </button>
            ) : null}
          </div>
        </>
      ) : null}        
      
    </div>
  
  )
}

export default UsersWrapper
