import React from "react";
import DailyQuizWrapper from "@/components/DailyQuizWrapper";
import styles from "./quiz.module.scss";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const DailyQuiz = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/dashboard/daily-quiz/create");
  };

  return (
    <div className={styles["quizzes"]}>
      <div className={styles["quizzes__header"]}>
        <div>
          <h2>Daily Quiz</h2>
          <p>Create and manage all your daily quizzes.</p>
        </div>
        <div
          onClick={() => handleRedirect()}
          className={styles["quizzes__header__create"]}
        >
          <FaPlus /> &nbsp; Create New Quiz
        </div>
      </div>
      <DailyQuizWrapper />
    </div>
  );
};

export default DailyQuiz;
