import React from "react";
import styles from "./point_allocation-modal.module.scss";
import { MdCancel } from "react-icons/md";
import { useContext, useState, useEffect } from "react";
import user from "../../assets/images/user1.svg";
import AdminContext from "@/context/AdminContext/AdminContext";
import AlertContext from "@/context/Alert/AlertContext";
// import { useNavigate } from 'react-router-dom'

const CreateWalletModal = () => {
  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);

  const { setExchangeRates } = useContext(AdminContext);

  const [formData, setFormData] = useState({
    token: "",
    value: "0",
    network: "",
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!formData?.value || formData.value.length === 0) {
      setErrorAlert("Please input a value");
      setIsLoading(false);
      return;
    } else if (!formData?.token) {
      setErrorAlert("Please add a token");
      setIsLoading(false);
      return;
    } else if (!formData?.network) {
      setErrorAlert("Please add a network");
      setIsLoading(false);
      return;
    }
    const data = {
      token: formData.token.toLowerCase(),
      network: formData.network.toLowerCase(),
      value: formData.value,
    };
    setExchangeRates(data).then((res) => {
      setSuccessAlert("Wallet created successfully");
      setIsLoading(false);
    });
  };

  // handle change for the input field, where user input the amount to redeem
  const handleChange = (type, e) => {
    if (type === "value") {
      setFormData({
        ...formData,
        value: e.target.value,
      });
    } else if (type === "network") {
      setFormData({
        ...formData,
        network: e.target.value,
      });
    } else if (type === "token") {
      setFormData({
        ...formData,
        token: e.target.value,
      });
    }
  };

  return (
    <div className={styles["prompt"]}>
      <div className={styles["prompt__content"]}>
        <div className={styles["prompt__content__detaills"]}>
          <h4>Create wallet-point ratio</h4>

          <div className={styles["prompt__content__detaills"]}>
            <label htmlFor="">Network name</label>
            <div className={styles["prompt__content__detaills--input"]}>
              <input
                type="text"
                id={formData.network}
                name="network"
                defaultValue={formData?.network}
                placeholder="Enter network name"
                onChange={(e) => handleChange("network", e)}
              />
            </div>
          </div>

          <div className={styles["prompt__content__detaills"]}>
            <label htmlFor="">Token name</label>
            <div className={styles["prompt__content__detaills--input"]}>
              <input
                type="text"
                id={formData.token}
                name="token"
                defaultValue={formData?.token}
                placeholder="Enter token name"
                onChange={(e) => handleChange("token", e)}
              />
            </div>
          </div>

          <div className={styles["prompt__content__detaills"]}>
            <label htmlFor="">Token value</label>
            <div className={styles["prompt__content__detaills--input"]}>
              <input
                type="number"
                id={formData.value}
                name="value"
                defaultValue={formData?.value}
                placeholder="Enter token value"
                onChange={(e) => handleChange("value", e)}
                min={0}
              />
            </div>
          </div>

          <div className={styles["prompt__content__detaills--conversions"]}>
            <div>
              <p>Redeem conversion rate:</p>
              <p>
                1 Point = {formData?.value} {formData?.network}
              </p>
            </div>
          </div>
        </div>
        <div>
          <button
            className={styles["prompt__content__detaills--success"]}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateWalletModal;
