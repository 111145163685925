import React, { useContext, useEffect, useState } from 'react'
// import PeopleContainer from './PeopleContainer'
// import ProductContext from '@/context/Product/ProductContext'
import styles from "./index.module.scss"
import moment from 'moment'
import { IoMdThumbsUp } from 'react-icons/io'
// import ShowComment from './ShowComment'
import ProductContext from '@/context/Product/ProductContext'
import ShowComment from './ShowComment'
import PeopleContainer from './PeopleContainer'

export const formatNumber = (number) => {
    if (number === 0) {
      return "0";
    } else if (number < 1000) {
      return number.toLocaleString();
    } else if (number < 1000000) {
      return `${(number / 1000).toFixed(1)}k`;
    } else if (number < 1000000000) {
      return `${(number / 1000000).toFixed(1)}m`;
    } else {
      return `${(number / 1000000000).toFixed(1)}b`;
    }
  }

const CommentsContainer = () => {

    const { 
    
        getCommentsOnProductHunt,
        productHuntId,
        likeComment,
        comments
    }  = useContext(ProductContext)



    const [showComment, setShowComment] = useState({})


    const toggleComment = (commentId) => {
        setShowComment((prevShowComment) => ({
            ...prevShowComment,
            [commentId]: !prevShowComment[commentId]
        }));
    };



    useEffect(() => {
        getCommentsOnProductHunt(productHuntId)
    }, [productHuntId])
   
   

    const handleCommentLike = async (commentId) => {
        // console.log("this is the comment id ", commentId)
        await likeComment(commentId)
        await getCommentsOnProductHunt(commentId)
    }

    


    const [loadLike, setLoadLike]  = useState(null)
  
   

    return (
        <div>
              {comments?.result?.length === 0 ? <p>There are no comments</p> : (

                <>

                    {comments?.result?.filter(comment => comment.parentId === null)?.map((comment, index) => (
                        <div className={styles.chatContainer} key={comment.id}>
                            <div className={styles.top}>
                                <div className={styles.userImage}>
                                    <img src={comment?.user?.avatar ? comment?.user?.avatar : "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="} alt="users avatar" />
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.name}>
                                        <p>{comment?.user?.fullName}</p>
                                        <p>{moment(comment?.createdAt).fromNow()}</p>
                                    </div>

                                    <p className={styles.commnt}>{comment?.content}</p>
  
                                </div>
                            </div>

                            <div className={styles.reactContainer}>
                                <PeopleContainer comment={comment}   />

                                <p onClick={() => toggleComment(comment.id)} style={{cursor: "pointer"}} >Reply</p>

                                <div 
                                    style={{cursor: "pointer"}} 
                                    onClick={async () => {
                                        setLoadLike(index)
                                        await likeComment(comment?.id)
                                        await getCommentsOnProductHunt(productHuntId)
                                        setLoadLike(null)
                                    }}
                                    >
                                    <p>Thumbs up</p>
                                    <div className={`${styles.thumbsUpContainer} ${loadLike === index ? styles.thumbsUpAnimating : ''}`}>
                                        <IoMdThumbsUp color="#686868" />
                                    </div>
                                    <span>{formatNumber(comment?.commentLikesCount)}</span>
                                </div>

                                {/* <p>Share</p>
                                <p className={styles.reportComment}>Report</p> */}
                            </div>

                            {showComment[comment.id] &&  (
                                <>
                                    <ShowComment 
                                        comment={comment} 
                                        productHuntId={productHuntId}
                                    />
                                </>



                            ) }

                        </div> 
                    ))}




                </>

) }
        </div>
    )
}

export default CommentsContainer