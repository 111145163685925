import React, { useContext } from 'react'
import styles from "./product.module.scss"
import Avatar from "../../assets/images/avatar.svg"
import ProductContext from '@/context/Product/ProductContext';

const team = [
    { id: 1, name: 'Declan Rice', avatar: Avatar },
    { id: 2, name: 'Declan Rice', avatar: Avatar },
    { id: 3, name: 'Declan Rice', avatar: Avatar },
    { id: 4, name: 'Declan Rice', avatar: Avatar },
  ];
  
const tags = ['Sass', 'NFT', 'Defi'];


const About = () => {

    const { 
     
        singleProduct
    }  = useContext(ProductContext)


    return (
        <div className={styles.aboutContainer}>
            <div className={styles.aboutProduct}>
                <h2>About &nbsp; {singleProduct?.name}</h2>
                <p> 
                    {singleProduct?.description}
                </p>
            </div>

            <div className={styles.aboutProduct}>
                <p className={styles.supportCountries}>Supported Countries</p>

                <div className={styles.tagsContainer} >
                    {singleProduct?.supportedCountries?.map((country, index) => (
                        <p className={styles.tag} key={index}> 
                            {country?.name}
                        </p>
                    ))}
                </div>


                <p className={styles.supportTags}>Categories</p>

                <div className={styles.tagsContainer}>
                    {singleProduct?.categories?.map((tag, index) => (
                        <p key={index} className={styles.tag}>
                            {tag?.name}
                        </p>
                    ))}
                </div>

                <p className={styles.supportTags}>Tags</p>

                <div className={styles.tagsContainer}>
                    <p className={styles.tag}>
                        {singleProduct?.tagline}
                    </p>
                </div>
            </div>



            <h2>Team</h2>
            <div className={styles.avatarContainer}>
                <div className={styles.userContainer}>
                    <div
                         style={{
                            background: `grey url(${singleProduct?.owner?.avatar})`
                        }}

                    >
                        {/* <img 
                            src={singleProdu  ct?.owner?.avatar} 
                            alt={`${singleProduct?.owner?.fullName} avatar`} 
                        /> */}
                    </div>
                    <p>{singleProduct?.owner?.fullName}</p>
                </div>
            </div>
            
            
        </div>
    )
}

export default About