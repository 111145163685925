import React, { useContext, useState } from "react"
import styles from "./addProductComment.module.scss"
import { FiSend } from "react-icons/fi"
import { Form, Formik } from "formik"
import ProductContext from "@/context/Product/ProductContext"
import AuthContext from "@/context/Auth/AuthContext"

const AddProductComment = ({ productHuntId, parentId, onCommentAdded }) => {
  const { addCommentToProductHuntComment, getCommentsOnProductHuntComment }   = useContext(ProductContext)
  const {user} = useContext(AuthContext)

  const [loading, setLoading] = useState(false)

  // console.log("productCommentsOnComments", productCommentsOnComments)
  
  return (
    <Formik
      initialValues={{
        content: "",
      }}
      onSubmit={async (values, actions) => {
        setLoading(true)


        const formData = {
          productHuntId,
          parentId,
          content: values?.content,
          imageUrl: user?.avatar
        }


        try {


          if (values.content !== "") {
            await addCommentToProductHuntComment(formData)
            await getCommentsOnProductHuntComment(parentId)
  
            if (onCommentAdded) {
              onCommentAdded()
            }
            actions.resetForm()

          }
          
        } catch (error) {}

        setLoading(false)
      }}
    >
      {(props) => (
        <Form>
          <div className={styles["addProductComment"]}>
            <div>
              <img
                src={user?.avatar ? user.avatar : "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="}
                alt="user"
                style={{height: "30px", width:"30px"}}
              />
            </div>
            <div className={styles["addProductComment__message"]}>
              <textarea
                name="content"
                id="content"
                cols="1"
                value={props.values.content}
                rows="5"
                onChange={props.handleChange}
                className="form-control"
              ></textarea>

              <button type="submit">
                {loading ? ". . . ": <FiSend />}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddProductComment
