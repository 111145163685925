import React from "react";
import styles from "./leader.module.scss";
import Reward1 from "../../assets/images/reward1.svg"
import Reward2 from "../../assets/images/reward2.svg"
import Reward3 from "../../assets/images/reward3.svg"

const Leader = ({ leader, index }) => {
  const { points, lastReward, user } = leader;
  
  const rewardImages = {
    1: Reward1,
    2: Reward2,
    3: Reward3
  };

  return (
    <div className={styles["leader"]}>
      <div>
        {rewardImages[index] ? (
          <img src={rewardImages[index]} alt={`Reward Icon ${index}`} />
        ) : (
          `# ${index}`
        )}
      </div>
      <div>{user?.username}</div>
      <div>{points?.toLocaleString()} points</div>
      <div>{lastReward?.toLocaleString()} points</div>
    </div>
  );
};

export default Leader;
