import { fail } from "@/assets/icons/fail";
import { success } from "@/assets/icons/success";
import { showPassword } from "@/assets/icons/showPassword";
import { hidePassword } from "@/assets/icons/hidePassword";
import { loadingIndicator } from "@/assets/icons/loadingIndicator";
import { google } from "@/assets/icons/google";
import { close } from "@/assets/icons/close";
import { user } from "@/assets/icons/user";
import { users } from "@/assets/icons/users";
import { search } from "@/assets/icons/search";
import { admin } from "@/assets/icons/admin";
import { view } from "@/assets/icons/view";
import { dashboard } from "@/assets/icons/dashboard";
import { switchIcon } from "@/assets/icons/switch";
import { notification } from "@/assets/icons/notification ";
import { settings } from "@/assets/icons/settings";
import { point } from "@/assets/icons/point";
import { appeal } from "@/assets/icons/appeal";
import { menu } from "@/assets/icons/menu";

export type IconType =
  | "fail"
  | "hidePassword"
  | "showPassword"
  | "success"
  | "loader"
  | "google"
  | "close"
  | "user"
  | "users"
  | "search"
  | "dashboard"
  | "notification"
  | "admin"
  | "view"
  | "switch"
  | "settings"
  | "points"
  | "appeal"
  | "menu";

export const icons: Record<IconType, string> = {
  fail: fail,
  success: success,
  showPassword: showPassword,
  hidePassword: hidePassword,
  loader: loadingIndicator,
  google,
  close,
  user,
  users,
  dashboard,
  admin,
  switch: switchIcon,
  notification,
  view,
  search,
  settings,
  points: point,
  appeal,
  menu,
};
