import { useContext, useState, useEffect } from "react";
import styles from "./config.module.scss";
import AdminContext from "@/context/AdminContext/AdminContext";
import AlertContext from "@/context/Alert/AlertContext";

const CreateConfig = ({ closeModal }) => {
  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);

  const { setConfig, getConfigs } = useContext(AdminContext);

  const [formData, setFormData] = useState({
    key: "",
    value: "",
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!formData?.key || formData.key.length === 0) {
      setErrorAlert("Please input a description of the app.");
      setIsLoading(false);
      return;
    } else if (!formData?.value || formData.value.length === 0) {
      setErrorAlert("Please add a link to apple store");
      setIsLoading(false);
      return;
    }

    setConfig([formData]).then((res) => {
      if (res) {
        setSuccessAlert("Key and value created successfully");
      } else {
        setErrorAlert("Error creating a key and value.");
      }
      setIsLoading(false);
      getConfigs();
      closeModal();
    });
  };

  // handle change for the input field, where user input the amount to redeem
  const handleChange = (type, e) => {
    if (type === "key") {
      setFormData({
        ...formData,
        key: e.target.value,
      });
    } else if (type === "value") {
      setFormData({
        ...formData,
        value: e.target.value,
      });
    }
  };

  return (
    <div className={styles["prompt"]}>
      <div className={styles["prompt__content"]}>
        <div className={styles["prompt__content__details_container"]}>
          <h4>Create a config</h4>

          <div className={styles["prompt__content__details"]}>
            <label htmlFor="">Key</label>
            <div className={styles["prompt__content__details--input"]}>
              <input
                type="text"
                id={formData.key}
                name="key"
                defaultValue={formData?.key}
                placeholder="Enter key"
                onChange={(e) => handleChange("key", e)}
              />
            </div>
          </div>

          <div className={styles["prompt__content__details"]}>
            <label htmlFor="">Value</label>
            <div className={styles["prompt__content__details--input"]}>
              <input
                type="text"
                id={formData.value}
                name="value"
                defaultValue={formData?.value}
                placeholder="Enter the value of the key"
                onChange={(e) => handleChange("value", e)}
              />
            </div>
          </div>
        </div>
        <div>
          <button
            className={styles["prompt__content__details--success"]}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateConfig;
