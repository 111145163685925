import React from 'react';
import styles from "./spinner.module.scss";

const Spinner = () => {
    return (
        <div className={styles['loader']}>
            <div className={styles['loader__spinner-container']}>
                <div className={styles['loader__loading-spinner']}></div>
            </div>
        </div>
    );
};

export default Spinner;