const BlogsReducer = (state, action) => {
    switch (action.type) {
      case "SET_LOADING":
        return {
          ...state,
          loading: true,
        };
  
      case "CLEAR_LOADING":
        return {
          ...state,
          loading: false,
        };
  
      case "SET_BLOG":
        return {
          ...state,
          blog: action.payload,
        };
  
        case "SET_BLOGS":
          return {
            ...state,
            blogs: action.payload,
          };

          case "SET_POST_COVER":
          return {
            ...state,
            postCover: action.payload,
          };
  
      default:
        return state;
    }
  };
  
  export default BlogsReducer;
  