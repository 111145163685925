import { useContext, useState } from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import styles from "./adventure_module.module.scss";
import { validationSchema } from "@/utils/helpers";
import { ICreateAdventure, IModule } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete, MdQuiz, MdVideoLibrary } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsArrowLeft, BsInfoCircle } from "react-icons/bs";
import AlertContext from "@/context/Alert/AlertContext";
import Loader from "@/components/Loader";
import CreateQuizAdventure from "./CreateQuiz";
import { openUploadWidget } from "@/utils/cloudinaryService";

interface IProps {
  adventureId: string;
  isChanged: boolean;
  setIsChanged: any;
  setEditType: any;
}

let initialModule = {
  index: 0,
  show: true,
  name: "",
  description: "",
  lessons: [
    {
      index: 0,
      show: true,
      name: "",
      text: "",
      videoUrl: "",
      quizzes: [],
    },
  ],
};

const Module = ({
  adventureId,
  isChanged,
  setIsChanged,
  setEditType,
}: IProps) => {
  const { setErrorAlert, setSuccessAlert } = useContext(AlertContext);

  const [moduleToAdd, setModuleToAdd] = useState<IModule>(initialModule);

  const { loading, adventure, createModule, updateAdventure } =
    useContext(AdventureContext);

  const { dynamic, dynamicNumber } = validationSchema;

  const handleSubmit = async () => {
    for (let i = 0; i < moduleToAdd.lessons.length; i++) {
      const lesson = moduleToAdd.lessons[i];
      if (lesson.name === "" || !lesson.name) {
        setErrorAlert(`Lesson ${lesson.index + 1} does not have a name.`);
        return;
      } else if (lesson.text === "" && lesson.videoUrl === "") {
        setErrorAlert(`Lesson ${lesson.index + 1} must have a video or text.`);
        return;
      }

      if (lesson?.quizzes && lesson?.quizzes?.length > 0) {
        for (let i = 0; i < lesson?.quizzes.length; i++) {
          const quiz = lesson?.quizzes[i];

          if (quiz.text === "" || !quiz.text) {
            setErrorAlert(
              `Lesson ${lesson.index + 1}, Quiz ${
                quiz.index + 1
              } does not have a question.`
            );
            return;
          } else if (quiz.options.length === 0) {
            setErrorAlert(
              `Lesson ${lesson.index + 1}, Quiz ${
                quiz.index + 1
              } does not have an option.`
            );
            return;
          } else if (quiz.options.length === 1) {
            setErrorAlert(
              `Lesson ${lesson.index + 1}, Quiz ${
                quiz.index + 1
              } must have more than one option.`
            );
            return;
          } else if (quiz.options.length > 1) {
            let isCorrectOption = false;
            let isTextEmpty = false;
            for (let i = 0; i < quiz.options.length; i++) {
              const option = quiz.options[i];
              if (option.isCorrect) {
                isCorrectOption = true;
              }
              if (option.text === "" || option.text.length === 0) {
                isTextEmpty = true;
              }
            }

            if (!isCorrectOption) {
              setErrorAlert(
                `Lesson ${lesson.index + 1}, Quiz ${
                  quiz.index + 1
                } must have at least one option marked as an answer.`
              );
              return;
            }
            if (isTextEmpty) {
              setErrorAlert(`Quiz must not have an empty option.`);
              return;
            }
          }
        }
      }
    }

    let lessons: any = [];
    for (let i = 0; i < moduleToAdd.lessons.length; i++) {
      const element = moduleToAdd.lessons[i];

      // map quizzes
      const questions = element.quizzes?.map((moduleToAdd) => {
        return {
          text: moduleToAdd.text,
          options: moduleToAdd.options,
        };
      });

      let quiz: any = {};

      if (element.quizzes && element.quizzes?.length > 0) {
        quiz = {
          description: `${element.name} Quiz`,
          questions,
        };
      }

      lessons.push({
        name: element.name,
        text: element.text,
        videoUrl: element.videoUrl,
        quiz,
      });
    }

    const formData = {
      name: `Module`,
      description: "",
      lessons,
      adventureId,
    };

    await createModule(formData).then((res: any) => {
      if (res) {
        initialModule = {
          index: 0,
          show: true,
          name: "",
          description: "",
          lessons: [
            {
              index: 0,
              show: true,
              name: "",
              text: "",
              videoUrl: "",
              quizzes: [],
            },
          ],
        };
        setModuleToAdd(initialModule);
        setIsChanged(!isChanged);
      }
    });
  };

  const handleShowUpload = async (lessonIndex: number) => {
    let success = false;
    let myUploadWidget = openUploadWidget(
      {
        tags: ["adventure"],
        maxFiles: 1,
        multiple: false,
        resourceType: ["video"],
      },
      function (error: any, result: any) {
        if (!error && result.event === "success") {
          success = true;
          setSuccessAlert("Video uploaded successfully.");
          const lesson = moduleToAdd?.lessons.find(
            (lesson) => lesson.index === lessonIndex
          )!;
          lesson.videoUrl = result.info.url;
          setModuleToAdd({ ...moduleToAdd });
        } else if (result.event === "abort" && !result.info.id && !success) {
          setErrorAlert("Error uploading video..");
        } else if (
          result.event === "close" &&
          result.info.message &&
          !success
        ) {
          setErrorAlert("Upload cancelled..");
        }
      }
    );
    myUploadWidget.open();
  };

  const handleSingleModuleCollapse = () => {
    setModuleToAdd({
      ...moduleToAdd,
      show: !moduleToAdd.show,
    });
  };

  const handleLessonAdd = () => {
    for (let i = 0; i < moduleToAdd.lessons.length; i++) {
      const element = moduleToAdd.lessons[i];
      element.show = false;
    }

    moduleToAdd.lessons.push({
      index: moduleToAdd.lessons.length,
      show: true,
      name: "",
      text: "",
      videoUrl: "",
      quizzes: [],
    });
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleLessonCollapse = (lessonIndex: number) => {
    for (let i = 0; i < moduleToAdd.lessons.length; i++) {
      const element = moduleToAdd.lessons[i];

      if (element.index !== lessonIndex) {
        element.show = false;
      } else {
        element.show = !element.show;
      }
    }
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleLessonDelete = (lessonIndex: number) => {
    if (moduleToAdd.lessons.length === 1) {
      setErrorAlert("You must have at least one lesson in a modules.");
      return;
    }

    const lessons = moduleToAdd.lessons
      .filter((item) => item.index !== lessonIndex)
      .map((item, index) => {
        return {
          ...item,
          index,
        };
      });
    moduleToAdd.lessons = lessons;

    setModuleToAdd({ ...moduleToAdd });
  };

  const handleLessonTextChange = (
    lessonIndex: number,
    type: string,
    e: any
  ) => {
    const lesson = moduleToAdd?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    )!;
    if (type === "body") {
      lesson.text = e.target.value;
    } else if (type === "name") {
      lesson.name = e.target.value;
    }
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleAddQuiz = (lessonIndex: number) => {
    const lesson = moduleToAdd.lessons.find(
      (lesson) => lesson.index === lessonIndex
    )!;
    lesson.quizzes?.push({
      index: lesson.quizzes.length,
      show: false,
      text: "",
      options: [],
    });
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleDeleteQuiz = (lessonIndex: number, quizIndex: number) => {
    const lesson = moduleToAdd.lessons.filter(
      (lesson) => lesson.index === lessonIndex
    );
    const filteredQuiz = lesson[0].quizzes
      ?.filter((item) => item.index !== quizIndex)
      .map((item, index) => {
        return {
          ...item,
          index,
        };
      });
    lesson[0].quizzes = filteredQuiz;
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleQuizAddOption = (lessonIndex: number, quizIndex: number) => {
    const lesson = moduleToAdd?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex);
    quiz?.options.push({
      text: "",
      isCorrect: false,
    });
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleQuizDeleteOption = (
    lessonIndex: number,
    quizIndex: number,
    optionIndex: number
  ) => {
    const lesson = moduleToAdd?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex)!;
    const filteredOptions = quiz?.options.filter(
      (option, index) => index !== optionIndex
    )!;
    quiz.options = filteredOptions;
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleQuizCollapse = (lessonIndex: number, quizIndex: number) => {
    const lesson = moduleToAdd?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex)!;
    quiz.show = !quiz?.show;

    setModuleToAdd({ ...moduleToAdd });
  };

  const handleQuizOptionChange = (
    lessonIndex: number,
    quizIndex: number,
    optionIndex: number,
    e: CheckboxChangeEvent
  ) => {
    const lesson = moduleToAdd?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex);
    const selectedOption = quiz?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.isCorrect = e.target.checked;
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleQuizOptionTextChange = (
    lessonIndex: number,
    quizIndex: number,
    optionIndex: number,
    e: any
  ) => {
    const lesson = moduleToAdd?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex);
    const selectedOption = quiz?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.text = e.target.value;
    setModuleToAdd({ ...moduleToAdd });
  };

  const handleQuizTextChange = (
    lessonIndex: number,
    quizIndex: number,
    e: any
  ) => {
    const lesson = moduleToAdd?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex)!;
    quiz.text = e.target.value;
    setModuleToAdd({ ...moduleToAdd });
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={styles["module"]}>
      <h5
        className={styles["module__back"]}
        onClick={() => setEditType("normal")}
      >
        <BsArrowLeft /> Back
      </h5>
      <br />
      <h3>Add Module</h3>
      <div className={styles["module__info_tab"]}>
        <BsInfoCircle /> &nbsp; Every module must have at least one lesson
      </div>
      <>
        <div className={styles["module__module_tab"]}>
          <h1 className={styles["module__module_tab__title"]}>Module</h1>
          <div>
            <span onClick={() => handleSingleModuleCollapse()}>
              {moduleToAdd.show ? (
                <IoIosArrowDropup size="1.3rem" cursor="pointer" />
              ) : (
                <IoIosArrowDropdown size="1.3rem" cursor="pointer" />
              )}
            </span>{" "}
            &nbsp;&nbsp;
            {/* <MdDelete
                size="1.3rem"
                cursor="pointer"
                onClick={() => handleModuleDelete(moduleToAdd.index)}
              /> */}
          </div>
        </div>
        {moduleToAdd.show && (
          <>
            <div className={styles["module__module_display"]}>
              {moduleToAdd.lessons.map((lesson) => (
                <>
                  <div className={styles["module__module_tab"]}>
                    <h1 className={styles["module__module_tab__title"]}>
                      Lesson {lesson.index + 1}
                    </h1>
                    <div>
                      <span onClick={() => handleLessonCollapse(lesson.index)}>
                        {lesson.show ? (
                          <IoIosArrowDropup size="1.3rem" cursor="pointer" />
                        ) : (
                          <IoIosArrowDropdown size="1.3rem" cursor="pointer" />
                        )}
                      </span>{" "}
                      &nbsp;&nbsp;
                      <MdDelete
                        size="1.3rem"
                        cursor="pointer"
                        onClick={() => handleLessonDelete(lesson.index)}
                      />
                    </div>
                  </div>
                  {lesson.show && (
                    <>
                      <div
                        className={
                          styles["module__module_display__input_container"]
                        }
                      >
                        <label htmlFor="name">Lesson Name</label>
                        <input
                          type="text"
                          placeholder="Lesson name"
                          className={styles["module__module_display_input"]}
                          defaultValue={lesson.name}
                          onChange={(e) =>
                            handleLessonTextChange(lesson.index, "name", e)
                          }
                        />
                      </div>
                      <div
                        className={
                          styles["module__module_display__input_container"]
                        }
                      >
                        <label htmlFor="body">Body Text</label>
                        <textarea
                          className={styles["module__module_display_text_area"]}
                          rows={8}
                          name="text"
                          defaultValue={lesson.text}
                          onChange={(e) =>
                            handleLessonTextChange(lesson.index, "body", e)
                          }
                        ></textarea>
                      </div>

                      <div
                        className={styles["module__module_display__under_tab"]}
                      >
                        <p onClick={() => handleAddQuiz(lesson.index)}>
                          <MdQuiz /> Add Quiz
                        </p>
                        <p onClick={() => handleShowUpload(lesson.index)}>
                          <MdVideoLibrary /> Add Video
                        </p>
                      </div>

                      {/* QUIZ */}
                      {lesson.quizzes?.map((quiz) => (
                        <CreateQuizAdventure
                          quiz={quiz}
                          lessonIndex={lesson.index}
                          handleQuizAddOption={handleQuizAddOption}
                          handleQuizOptionChange={handleQuizOptionChange}
                          handleQuizOptionTextChange={
                            handleQuizOptionTextChange
                          }
                          handleQuizTextChange={handleQuizTextChange}
                          handleDeleteQuiz={handleDeleteQuiz}
                          handleQuizCollapse={handleQuizCollapse}
                          handleQuizDeleteOption={handleQuizDeleteOption}
                        />
                      ))}
                    </>
                  )}

                  {moduleToAdd.lessons.length === lesson.index + 1 ? (
                    <></>
                  ) : (
                    <hr className={styles["module__line"]} />
                  )}
                </>
              ))}
              <br />
              <div className={styles["module__another_option"]}>
                <span onClick={() => handleLessonAdd()}>
                  <AiFillPlusCircle fill="#1579E4" /> Add another lesson
                </span>
              </div>
            </div>
          </>
        )}
      </>
      <br />
      <button
        onClick={() => handleSubmit()}
        className={styles["module__button"]}
      >
        Continue
      </button>
    </div>
  );
};

export default Module;
