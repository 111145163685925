import React from "react";
import styles from "./onboarding.module.scss";
import onboardingImg from "../../assets/images/onboarding-img.png"

const Onboarding = () => {
  return (
    <div className={styles["onboarding"]}>
      <div className={ styles["onboarding__images"] }>
        <img src={ onboardingImg } alt="Onboarding Svg" />
      </div>
      <h1 className={styles["onboarding__title"]}>
        Create Enjoyable Adventures for Users
      </h1>
      <p>The platform to create modules, missions, lessons or tasks to accommodate for the learnability of all users.</p>
      <div className={styles["onboarding__images"]}>
        <img src="../../assets/images/stacked-users.svg" alt="" />
      </div>
    </div>
  );
};

export default Onboarding;
