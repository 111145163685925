import React from "react";
import styles from "./edit-modal.module.scss";
import { MdCancel } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { useContext, useState } from "react";
import BlogsContext from "@/context/Blogs/BlogsContext";
import { useNavigate } from "react-router-dom";
import { UploadImage } from "@/utils/helpers";
import AlertContext from "@/context/Alert/AlertContext";
import RichTextEditor from "../RichTextEditor";

const EditModal = ({ id, blog }) => {
  const navigate = useNavigate();

  const { loading, updateBlog, fetchAllBlogs } = useContext(BlogsContext);

  const { setErrorAlert } = useContext(AlertContext);

  const [formData, setFormData] = useState({
    title: blog?.title,
    description: blog?.description,
    thumbnailUrl: blog?.thumbnailUrl,
    content: blog?.content,
    // communityId: ""
  });

  //Onchange
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const uploadImage = async (e) => {
    const res = await UploadImage(e.currentTarget.files[0]);

    setFormData((prevState) => ({
      ...prevState,
      thumbnailUrl: res?.url,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title || !formData.description || !formData.content) {
      setErrorAlert("Please, fill the form and try again");
    } else {
      await updateBlog(id, formData);
      fetchAllBlogs();
      navigate("/dashboard/blogs");
    }
  };

  return (
    <div className={styles["prompt"]}>
      <div className={styles["prompt__content"]}>
        <div className={styles["prompt__content__detaills"]}>
          <FiEdit size="3rem" />
          <h2 style={{ color: "#000" }}>Edit Blog Post</h2>

          <div className={styles["add__blog"]}>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="title">Post Title *</label>
                <input
                  type="text"
                  // placeholder='Post title'
                  value={formData.title}
                  id="title"
                  onChange={onChange}
                />
              </div>
              <div>
                <label htmlFor="title">Post Description *</label>
                <input
                  type="text"
                  placeholder="Post description"
                  value={formData.description}
                  id="description"
                  onChange={onChange}
                />
              </div>
              <div>
                <label htmlFor="title">Post Content *</label>
                <div className={ styles["prompt__content__detaills--editor"] }>
                  <RichTextEditor
                    onChange={ (val) => setFormData({ ...formData, content: val })  }
                    value={formData?.content}
                    placeholder={`Thinking about anything? Well, put it out here.`}
                />
                </div>
                {/* <textarea
                  id="content"
                  placeholder="Post content...."
                  value={formData.content}
                  onChange={onChange}
                ></textarea> */}
              </div>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  id="thumbnailUrl"
                  onChange={uploadImage}
                />
              </div>
              <button type="submit">
                {loading ? "Updating Blog Post...!" : "Update Blog"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
