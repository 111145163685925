import { useContext, useState, useEffect } from "react";
import styles from "./adventure_module.module.scss";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { UploadImage, validationSchema } from "@/utils/helpers";
import { ICreateAdventure, ILesson, IQuestion } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete, MdQuiz, MdVideoLibrary } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsArrowLeft, BsInfoCircle } from "react-icons/bs";
import QuizForLesson from "./QuizForLesson";
import AlertContext from "@/context/Alert/AlertContext";
import { openUploadWidget } from "@/utils/cloudinaryService";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

interface IProps {
  moduleId: string;
  isChanged: boolean;
  setIsChanged: any;
  setEditType: any;
}

const AddLesson = ({
  moduleId,
  isChanged,
  setIsChanged,
  setEditType,
}: IProps) => {
  const { addLessonFromModule } = useContext(AdventureContext);
  const { setErrorAlert, setSuccessAlert } = useContext(AlertContext);

  const initialState = {
    index: 0,
    show: true,
    name: "",
    text: "",
    videoUrl: "",
    quizzes: [],
  };
  const [formData, setFormData] = useState<ILesson>(initialState);

  const handleShowUpload = async () => {
    let success = false;
    let myUploadWidget = openUploadWidget(
      {
        tags: ["adventure"],
        maxFiles: 1,
        multiple: false,
        resourceType: ["video"],
      },
      function (error: any, result: any) {
        if (!error && result.event === "success") {
          success = true;
          setSuccessAlert("Video uploaded successfully.");
          setFormData({
            ...formData,
            videoUrl: result.info.url,
          });
        } else if (result.event === "abort" && !result.info.id && !success) {
          setErrorAlert("Error uploading video..");
        } else if (
          result.event === "close" &&
          result.info.message &&
          !success
        ) {
          setErrorAlert("Upload cancelled..");
        }
      }
    );
    myUploadWidget.open();
  };

  const handleLessonTextChange = (type: string, e: any) => {
    if (type === "body") {
      setFormData({
        ...formData,
        text: e.target.value,
      });
    } else if (type === "name") {
      setFormData({
        ...formData,
        name: e.target.value,
      });
    }
  };

  const handleAddQuiz = () => {
    formData.quizzes?.push({
      index: formData.quizzes.length,
      show: false,
      text: "",
      options: [],
    });
    setFormData({ ...formData });
  };

  const handleDeleteQuiz = (quizIndex: number) => {
    const filteredQuiz = formData.quizzes
      ?.filter((item) => item.index !== quizIndex)
      .map((item, index) => {
        return {
          ...item,
          index,
        };
      });
    setFormData({ ...formData, quizzes: filteredQuiz });
  };

  const handleQuizAddOption = (quizIndex: number) => {
    const quiz = formData?.quizzes?.find((item) => item.index === quizIndex);
    quiz?.options.push({
      text: "",
      isCorrect: false,
    });
    setFormData({ ...formData });
  };

  const handleQuizDeleteOption = (quizIndex: number, optionIndex: number) => {
    const quiz = formData?.quizzes?.find((item) => item.index === quizIndex)!;
    const filteredOptions = quiz?.options.filter(
      (option, index) => index !== optionIndex
    )!;
    quiz.options = filteredOptions;
    setFormData({ ...formData });
  };

  const handleQuizOptionChange = (
    quizIndex: number,
    optionIndex: number,
    e: CheckboxChangeEvent
  ) => {
    const quiz = formData?.quizzes?.find((item) => item.index === quizIndex);
    const selectedOption = quiz?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.isCorrect = e.target.checked;
    setFormData({ ...formData });
  };

  const handleQuizCollapse = (quizIndex: number) => {
    const quiz = formData?.quizzes?.find((item) => item.index === quizIndex)!;
    quiz.show = !quiz?.show;
    setFormData({ ...formData });
  };

  const handleQuizOptionTextChange = (
    quizIndex: number,
    optionIndex: number,
    e: any
  ) => {
    const quiz = formData?.quizzes?.find((item) => item.index === quizIndex);
    const selectedOption = quiz?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.text = e.target.value;
    setFormData({ ...formData });
  };

  const handleQuizTextChange = (quizIndex: number, e: any) => {
    const quiz = formData?.quizzes?.find((item) => item.index === quizIndex)!;
    quiz.text = e.target.value;
    setFormData({ ...formData });
  };

  const handleSubmit = async () => {
    if (formData.name === "" || !formData.name) {
      setErrorAlert(`Lesson does not have a name.`);
      return;
    } else if (formData.text === "" && formData.videoUrl === "") {
      setErrorAlert(`Lesson must have a video or text.`);
      return;
    }

    if (formData?.quizzes && formData?.quizzes?.length > 0) {
      for (let i = 0; i < formData?.quizzes.length; i++) {
        const quiz = formData?.quizzes[i];

        if (quiz.text === "" || !quiz.text) {
          setErrorAlert(`Quiz ${quiz.index + 1} does not have a question.`);
          return;
        } else if (quiz.options.length === 0) {
          setErrorAlert(`Quiz ${quiz.index + 1} does not have an option.`);
          return;
        } else if (quiz.options.length === 1) {
          setErrorAlert(
            `Quiz ${quiz.index + 1} must have more than one option.`
          );
          return;
        } else if (quiz.options.length > 1) {
          let isCorrectOption = false;
          let isTextEmpty = false;
          for (let i = 0; i < quiz.options.length; i++) {
            const option = quiz.options[i];
            if (option.isCorrect) {
              isCorrectOption = true;
            }
            if (option.text === "" || option.text.length === 0) {
              isTextEmpty = true;
            }
          }

          if (!isCorrectOption) {
            setErrorAlert(
              `Quiz ${
                quiz.index + 1
              } must have at least one option marked as an answer.`
            );
            return;
          }
          if (isTextEmpty) {
            setErrorAlert(`Quiz must not have an empty option.`);
            return;
          }
        }
      }
    }

    const questions = formData.quizzes?.map((item) => {
      return {
        text: item.text,
        options: item.options,
      };
    });

    let quiz: any = {};

    if (formData.quizzes && formData.quizzes?.length > 0) {
      quiz = {
        description: `${formData.name} Quiz`,
        questions,
      };
    }

    const lesson = {
      moduleId,
      name: formData.name,
      text: formData.text,
      videoUrl: formData.videoUrl,
      quiz,
    };

    await addLessonFromModule(lesson).then((res: any) => {
      setIsChanged(!isChanged);
    });
  };

  return (
    <>
      <h5
        className={styles["module__back"]}
        onClick={() => setEditType("normal")}
      >
        <BsArrowLeft /> Back
      </h5>
      <br />
      <h3>Add Lesson</h3>
      <div className={styles["module__module_display"]}>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="name">Lesson Name</label>
          <input
            type="text"
            placeholder="Lesson name"
            className={styles["module__module_display_input"]}
            defaultValue={formData.name}
            onChange={(e) => handleLessonTextChange("name", e)}
          />
        </div>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="body">Body Text</label>
          <textarea
            className={styles["module__module_display_text_area"]}
            rows={8}
            name="text"
            defaultValue={formData.text}
            onChange={(e) => handleLessonTextChange("body", e)}
          ></textarea>
        </div>

        <div className={styles["module__module_display__under_tab"]}>
          <p onClick={() => handleAddQuiz()}>
            <MdQuiz /> Add Quiz
          </p>
          <p onClick={handleShowUpload}>
            <MdVideoLibrary /> Add Video
          </p>
        </div>

        {formData.quizzes?.map((quiz, quizIndex) => (
          <QuizForLesson
            quiz={quiz}
            quizIndex={quizIndex}
            handleDeleteQuiz={handleDeleteQuiz}
            handleQuizCollapse={handleQuizCollapse}
            handleQuizTextChange={handleQuizTextChange}
            handleQuizOptionTextChange={handleQuizOptionTextChange}
            handleQuizDeleteOption={handleQuizDeleteOption}
            handleQuizAddOption={handleQuizAddOption}
            handleQuizOptionChange={handleQuizOptionChange}
          />
        ))}

        <button onClick={handleSubmit} className={styles["module__button"]}>
          Save Lesson
        </button>
      </div>
    </>
  );
};

export default AddLesson;
