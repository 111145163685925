import { useContext, useState } from "react";
import styles from "./adventure_module.module.scss";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { UploadImage, validationSchema } from "@/utils/helpers";
import { ICreateAdventure, IQuestion } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete, MdQuiz, MdVideoLibrary } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { Checkbox } from "antd";

interface IProps {
  quiz: IQuestion;
  moduleIndex: number;
  lessonIndex: number;
  handleQuizAddOption: any;
  handleQuizOptionChange: any;
  handleQuizOptionTextChange: any;
  handleQuizTextChange: any;
  handleDeleteQuiz: any;
  handleQuizCollapse: any;
  handleQuizDeleteOption: any;
}

const QuizAdventure = ({
  quiz,
  moduleIndex,
  lessonIndex,
  handleQuizAddOption,
  handleQuizOptionChange,
  handleQuizOptionTextChange,
  handleQuizTextChange,
  handleDeleteQuiz,
  handleQuizCollapse,
  handleQuizDeleteOption,
}: IProps) => {
  return (
    <>
      <div className={styles["module__module_tab"]}>
        <h1 className={styles["module__module_tab__title"]}>
          Quiz {quiz.index + 1}
        </h1>
        <div>
          {quiz.show ? (
            <IoIosArrowDropup
              size="1.3rem"
              cursor="pointer"
              onClick={() =>
                handleQuizCollapse(moduleIndex, lessonIndex, quiz.index)
              }
            />
          ) : (
            <IoIosArrowDropdown
              size="1.3rem"
              cursor="pointer"
              onClick={() =>
                handleQuizCollapse(moduleIndex, lessonIndex, quiz.index)
              }
            />
          )}
          &nbsp;&nbsp;
          {/* <IoIosArrowDropup /> */}
          <MdDelete
            size="1.3rem"
            cursor="pointer"
            fill="red"
            onClick={() =>
              handleDeleteQuiz(moduleIndex, lessonIndex, quiz.index)
            }
          />
        </div>
      </div>

      {/* QUIZ */}
      {quiz.show && (
        <div className={styles["module__module_display"]}>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Quiz {quiz.index + 1}</label>
            <input
              type="text"
              placeholder="What is your question?"
              className={styles["module__module_display_input"]}
              defaultValue={quiz.text}
              onChange={(e) =>
                handleQuizTextChange(moduleIndex, lessonIndex, quiz.index, e)
              }
            />
          </div>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Setup options for users to select from</label>
            {quiz.options.map((option, optionIndex) => (
              <>
                <input
                  type="text"
                  placeholder="Option 1"
                  className={styles["module__module_display_input"]}
                  defaultValue={option.text}
                  onChange={(e) =>
                    handleQuizOptionTextChange(
                      moduleIndex,
                      lessonIndex,
                      quiz.index,
                      optionIndex,
                      e
                    )
                  }
                />
                <div className={styles["module__remove_option"]}>
                  <span
                    onClick={() =>
                      handleQuizDeleteOption(
                        moduleIndex,
                        lessonIndex,
                        quiz.index,
                        optionIndex
                      )
                    }
                  >
                    Remove option
                  </span>
                </div>
              </>
            ))}
          </div>
          <div className={styles["module__another_option"]}>
            <span
              onClick={() =>
                handleQuizAddOption(moduleIndex, lessonIndex, quiz.index)
              }
            >
              <AiFillPlusCircle fill="#1579E4" /> Add option
            </span>
          </div>
          <div>
            {quiz.options.length > 0 ? (
              <>
                <label htmlFor="name">Choose answer(s)</label>
                <div className={styles["quiz__option"]}>
                  {quiz.options.map((item, index) => (
                    <Checkbox
                      onChange={(e) =>
                        handleQuizOptionChange(
                          moduleIndex,
                          lessonIndex,
                          quiz.index,
                          index,
                          e
                        )
                      }
                      checked={item.isCorrect}
                    >
                      {item.text}
                    </Checkbox>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          {/* <button className={styles["module__button"]}>Save Quiz</button> */}
        </div>
      )}
    </>
  );
};

export default QuizAdventure;
