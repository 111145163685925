export const google = `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
<g clip-path="url(#clip0_308:84)">
<path d="M20.1667 10.2222C20.1667 9.39995 20.1009 8.79995 19.9584 8.17773H10.6973V11.8888H16.1334C16.0238 12.811 15.432 14.1999 14.1167 15.1332L14.0983 15.2575L17.0265 17.5572L17.2294 17.5777C19.0926 15.8332 20.1667 13.2666 20.1667 10.2222Z" fill="#4285F4"/>
<path d="M10.6967 20C13.3599 20 15.5957 19.1111 17.2288 17.5778L14.1162 15.1333C13.2833 15.7222 12.1653 16.1333 10.6967 16.1333C8.08826 16.1333 5.87437 14.3889 5.08518 11.9778L4.9695 11.9877L1.92471 14.3766L1.88489 14.4889C3.50694 17.7555 6.83878 20 10.6967 20Z" fill="#34A853"/>
<path d="M5.0858 11.9778C4.87756 11.3555 4.75705 10.6888 4.75705 9.99997C4.75705 9.31104 4.87756 8.6444 5.07484 8.02218L5.06932 7.88967L1.98637 5.4624L1.8855 5.51104C1.21698 6.86661 0.833374 8.38886 0.833374 9.99997C0.833374 11.6111 1.21698 13.1332 1.8855 14.4888L5.0858 11.9778Z" fill="#FBBC05"/>
<path d="M10.6967 3.86664C12.5489 3.86664 13.7983 4.67774 14.5108 5.35557L17.2946 2.6C15.5849 0.988892 13.36 0 10.6967 0C6.83878 0 3.50695 2.24442 1.88489 5.51106L5.07423 8.0222C5.87437 5.6111 8.08827 3.86664 10.6967 3.86664Z" fill="#EB4335"/>
</g>
<defs>
<clipPath id="clip0_308:84">
<rect width="20" height="20" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>`;