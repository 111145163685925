import React from "react";
import styles from "./create_quiz.module.scss";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "@/context/Alert/AlertContext";
import { IQuestion } from "@/utils/interface";
import Quiz from "./Quiz";
import { MdQuiz } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import AdventureContext from "@/context/Adventure/AdventureContext";
import Spinner from "@/components/Spinner";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

interface IFormData {
  description: string;
  questions: IQuestion[];
}

const CreateDailyQuiz = () => {
  const { createQuiz, loading } = useContext(AdventureContext);

  const [formData, setFormData] = useState<IFormData>({
    description: "",
    questions: [],
  });

  const { setErrorAlert } = useContext(AlertContext);

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/dashboard/daily-quiz");
  };

  //Onchange
  const onChange = (e: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  const handleAddQuestion = () => {
    formData.questions?.push({
      index: formData.questions.length,
      show: false,
      text: "",
      options: [],
    });
    setFormData({ ...formData });
  };

  const handleDeleteQuestion = (quizIndex: number) => {
    const filteredQuestions = formData.questions
      ?.filter((item) => item.index !== quizIndex)
      .map((item, index) => {
        return {
          ...item,
          index,
        };
      });
    setFormData({ ...formData, questions: filteredQuestions });
  };

  const handleQuestionAddOption = (quizIndex: number) => {
    const quiz = formData?.questions?.find((item) => item.index === quizIndex);
    quiz?.options.push({
      text: "",
      isCorrect: false,
    });
    setFormData({ ...formData });
  };

  const handleQuestionDeleteOption = (
    quizIndex: number,
    optionIndex: number
  ) => {
    const question = formData?.questions?.find(
      (item) => item.index === quizIndex
    )!;
    const filteredOptions = question?.options.filter(
      (option, index) => index !== optionIndex
    )!;
    question.options = filteredOptions;
    setFormData({ ...formData });
  };

  const handleQuestionOptionChange = (
    quizIndex: number,
    optionIndex: number,
    e: CheckboxChangeEvent
  ) => {
    const questions = formData?.questions?.find(
      (item) => item.index === quizIndex
    );
    const selectedOption = questions?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.isCorrect = e.target.checked;
    setFormData({ ...formData });
  };

  const handleQuestionCollapse = (quizIndex: number) => {
    const question = formData?.questions?.find(
      (item) => item.index === quizIndex
    )!;
    question.show = !question?.show;
    setFormData({ ...formData });
  };

  const handleQuestionOptionTextChange = (
    quizIndex: number,
    optionIndex: number,
    e: any
  ) => {
    const question = formData?.questions?.find(
      (item) => item.index === quizIndex
    );
    const selectedOption = question?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.text = e.target.value;
    setFormData({ ...formData });
  };

  const handleQuestionTextChange = (quizIndex: number, e: any) => {
    const quiz = formData?.questions?.find((item) => item.index === quizIndex)!;
    quiz.text = e.target.value;
    setFormData({ ...formData });
  };

  const handleSubmit = async () => {
    const data = {
      description: formData.description,
      questions: formData.questions.map((question) => {
        return {
          text: question.text,
          options: question.options,
        };
      }),
    };
    await createQuiz(data).then((res: any) => {
      navigate("/dashboard/daily-quiz");
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <span onClick={() => goBack()} className={styles["module__back"]}>
        <BsArrowLeft /> &nbsp; Go Back
      </span>

      <div className={styles["module__module_tab"]}>
        <h1 className={styles["module__module_tab__title"]}>Create Quiz</h1>
      </div>

      {/* QUIZ */}
      <div className={styles["module__module_display"]}>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="name">Quiz description</label>
          <input
            type="text"
            placeholder="Enter quiz description"
            className={styles["module__module_display_input"]}
            defaultValue={formData.description}
            onChange={(e) => onChange(e)}
          />
        </div>

        <div className={styles["module__module_display__under_tab"]}>
          <p onClick={() => handleAddQuestion()}>
            <MdQuiz /> Add Question
          </p>
        </div>

        {formData.questions?.map((question, questionIndex) => (
          <Quiz
            question={question}
            questionIndex={questionIndex}
            handleDeleteQuestion={handleDeleteQuestion}
            handleQuestionCollapse={handleQuestionCollapse}
            handleQuestionTextChange={handleQuestionTextChange}
            handleQuestionOptionTextChange={handleQuestionOptionTextChange}
            handleQuestionDeleteOption={handleQuestionDeleteOption}
            handleQuestionAddOption={handleQuestionAddOption}
            handleQuestionOptionChange={handleQuestionOptionChange}
          />
        ))}
      </div>
      <button onClick={handleSubmit} className={styles["module__button"]}>
        Create Quiz
      </button>
    </>
  );
};

export default CreateDailyQuiz;
