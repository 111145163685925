import { useState } from "react";
import styles from "./single-adventure.module.scss";
import { useContext, useEffect } from "react";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import moment from "moment";
import Swal from "sweetalert2";
import Spinner from "@/components/Spinner";
import TasksSubmissionWrapper from "@/components/TasksSubmissionWrapper";

const SingleAdventureDetails = () => {
  const params = useParams();
  const id = params.id;

  const navigate = useNavigate();

  const {
    loading,
    adventure,
    fetchSingleAdventure,
    deleteAdventure,
    featureAdventure,
    fetchAdventureTask,
  } = useContext(AdventureContext);

  useEffect(() => {
    fetchSingleAdventure(id);
    fetchAdventureTask(id);
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    navigate("/dashboard/adventures");
  };

  const handleFeatureAdventure = () => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to feature this adventure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, continue!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          adventureId: adventure?.id,
        };
        await featureAdventure(data).then((res) => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      }
    });
  };

  const handleDeleteAdventure = () => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete this adventure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result) {
          await deleteAdventure(adventure?.id).then((res) => {
            navigate("/dashboard/adventures");
          });
        }
      }
    });
  };

  return (
    <>
      {adventure?.id !== id || loading ? (
        <Spinner />
      ) : (
        <div className={styles["single__adventure"]}>
          <span
            onClick={() => goBack()}
            className={styles["single__adventure--back"]}
          >
            <BsArrowLeft /> &nbsp; Go Back
          </span>
          <div className={styles["single__adventure__header"]}>
            <div className={styles["single__adventure__header__img"]}>
              <img
                className={styles["single__adventure__header__img--img"]}
                src={adventure.imageUrl}
                alt="Adventure"
              />
            </div>
            <div className={styles["single__adventure__header__body"]}>
              <h1>{adventure?.name}</h1>
              <div className={styles["single__adventure__header__body--tabs"]}>
                <span>Level: {adventure?.difficultyLevel}</span>
                <span>Enrollments: {adventure?.enrollments}</span>
                <span>Reward Points: {adventure?.rewardPoint}</span>
                <span>
                  Created At:{" "}
                  {moment(adventure?.createdAt).format("Do MMMM, YYYY")}
                </span>
              </div>
              {adventure?.isFeatured && (
                <>
                  <h3>Featured Adventure</h3>
                  <br />
                </>
              )}
              <p>
                <strong>Description</strong>: {adventure?.description}
              </p>
              <p>
                <strong>Expectations</strong>: {adventure?.expectations}
              </p>
              <p>
                <strong>Gains</strong>: {adventure?.gains}
              </p>
              <p>
                <strong>Earnings</strong>: {adventure?.earnings}
              </p>
              <br />
              <div className={styles["single__adventure__header__body__btn"]}>
                <span
                  onClick={() => handleDeleteAdventure()}
                  className={
                    styles["single__adventure__header__body--btn-danger"]
                  }
                >
                  Delete Adventure
                </span>
                <span
                  onClick={() =>
                    navigate(`/dashboard/adventure/edit/${adventure?.id}`)
                  }
                  className={
                    styles["single__adventure__header__body--btn-success"]
                  }
                >
                  Edit Adventure
                </span>
                {!adventure?.isFeatured ? (
                  <span
                    onClick={handleFeatureAdventure}
                    className={
                      styles["single__adventure__header__body--btn-primary"]
                    }
                  >
                    Feature Adventure
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <br />
          <h3>Tasks Submitted</h3>
          <TasksSubmissionWrapper adventureId={id} />
        </div>
      )}
    </>
  );
};

export default SingleAdventureDetails;
