import React, { useState, useMemo, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

// import {Quill} from "react-quill";
import Styles from "./editor.module.scss";

const RichTextEditor = ({ value, id, onChange, placeholder }) => {

  return (
    <div className={Styles["editor"]}>
      <ReactQuill
        placeholder={placeholder}
        id={ id }
        modules={{
          
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            ["code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link"],
            ["clean"]
          ],

        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "code-block",
        ]}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default RichTextEditor;