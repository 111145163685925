import React from "react";
import styles from "./create_quiz.module.scss";
import { useState, useContext, useEffect } from "react";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { UploadImage } from "@/utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "@/context/Alert/AlertContext";
import { AiFillPlusCircle } from "react-icons/ai";
import { IQuestion } from "@/utils/interface";
import { MdModeEditOutline, MdQuiz, MdVideoLibrary } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import DisplayQuestion from "./DisplayQuestion";
import EditQuestion from "./EditQuestion";
import Swal from "sweetalert2";
import Spinner from "@/components/Spinner";
import DailyQuizSubmissionWrapper from "@/components/DailyQuizSubmissionWrapper";
import { Switch } from "antd";

interface IFormData {
  id: string;
  description: string;
  questions: IQuestion[];
}

const initialQuestion = {
  index: 0,
  show: true,
  text: "",
  options: [],
};

const EditDailyQuiz = () => {
  const params = useParams();
  const id = params.id;
  const {
    loading,
    quiz,
    getQuizById,
    deleteQuizQuestion,
    fetchQuizSubmissions,
    switchDailyQuiz,
    makeDailyQuiz,
  } = useContext(AdventureContext);
  const { setErrorAlert } = useContext(AlertContext);

  const [isChanged, setIsChanged] = useState(false);
  const [isQuizEdit, setIsQuizEdit] = useState(false);

  const [formData, setFormData] = useState<IFormData>({
    id: "",
    description: "",
    questions: [],
  });

  useEffect(() => {
    fetchQuizSubmissions(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getQuizById(id).then((res: any) => {
      const questions = res?.questions.map((question: any, index: number) => {
        return {
          id: question.id,
          index,
          show: false,
          text: question.text || "",
          options: question.options || [],
        };
      });
      setFormData({
        ...formData,
        description: res.description,
        questions,
        id: res.id,
      });
    });
  }, [id, isChanged]);

  const [currentQuizQuestion, setCurrentQuizQuestion] =
    useState<IQuestion>(initialQuestion);
  const [currentTab, setCurrentTab] = useState<"quiz" | "question">("quiz");
  const [quizQuestionType, setQuizQuestionType] = useState<"edit" | "add">(
    "edit"
  );

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/dashboard/daily-quiz");
  };

  const handleQuizChange = () => {
    if (quiz.isDailyQuiz) {
      switchDailyQuiz().then(() => {
        setIsChanged(!isChanged);
      });
    } else {
      makeDailyQuiz(quiz.id).then(() => {
        setIsChanged(!isChanged);
      });
    }
  };

  const handleAddQuestion = () => {
    setCurrentQuizQuestion(initialQuestion);
    setQuizQuestionType("add");
    setCurrentTab("question");
  };

  const handleQuizEdit = () => {
    setIsQuizEdit(!isQuizEdit);
  };

  const handleDeleteQuestion = (questionId: number) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        if (formData.questions.length > 0) {
          await deleteQuizQuestion(questionId).then((res: any) => {
            setIsChanged(!isChanged);
          });
        } else {
          setErrorAlert("Quiz must have at least one question.");
        }
      }
    });
  };

  const handleQuestionCollapse = (questionIndex: number) => {
    const question = formData?.questions?.find(
      (item) => item.index === questionIndex
    )!;
    question.show = !question?.show;
    setFormData({ ...formData });
  };

  return loading ? (
    <Spinner />
  ) : currentTab === "quiz" ? (
    <>
      <span onClick={() => goBack()} className={styles["module__back"]}>
        <BsArrowLeft /> &nbsp; Go Back
      </span>

      <div>
        <h3 className={styles["quiz__header"]}>
          Daily Quiz status: &nbsp;&nbsp;&nbsp;
          <Switch
            checked={quiz && quiz?.isDailyQuiz}
            onChange={handleQuizChange}
          />
        </h3>
      </div>

      <div className={styles["module__module_tab"]}>
        <h1 className={styles["module__module_tab__title"]}>
          {isQuizEdit ? "Edit Quiz" : "Quiz"}
        </h1>
        <span>
          &nbsp;&nbsp;&nbsp;
          <MdModeEditOutline
            size="1.3rem"
            cursor="pointer"
            onClick={handleQuizEdit}
          />
        </span>
      </div>

      {/* QUIZ */}
      <div className={styles["module__module_display"]}>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="name">Quiz description</label>
          <input
            type="text"
            placeholder="Enter quiz description"
            className={styles["module__module_display_input"]}
            defaultValue={formData.description}
            disabled={!isQuizEdit}
            // onChange={(e) => handleQuestionTextChange(lessonIndex, quiz.index, e)}
          />
        </div>

        {isQuizEdit && (
          <div className={styles["module__module_display__under_tab"]}>
            <p onClick={() => handleAddQuestion()}>
              <MdQuiz /> Add Question
            </p>
          </div>
        )}

        {formData.questions?.map((question, questionIndex) => (
          <DisplayQuestion
            isQuizEdit={isQuizEdit}
            quizQuestion={question}
            handleDeleteQuestion={handleDeleteQuestion}
            handleQuestionCollapse={handleQuestionCollapse}
            setQuizQuestionType={setQuizQuestionType}
            setCurrentTab={setCurrentTab}
            setCurrentQuizQuestion={setCurrentQuizQuestion}
          />
        ))}
      </div>
      {isQuizEdit && (
        <button className={styles["module__button"]}>Save Quiz</button>
      )}

      <br />
      <br />
      <br />
      <h3>Quiz Submissions</h3>
      <DailyQuizSubmissionWrapper id={id} />
    </>
  ) : currentTab === "question" ? (
    <>
      <EditQuestion
        quizId={id}
        currentQuizQuestion={currentQuizQuestion}
        quizQuestionType={quizQuestionType}
        isChanged={isChanged}
        setIsChanged={setIsChanged}
        setCurrentTab={setCurrentTab}
      />
    </>
  ) : null;
};

export default EditDailyQuiz;
