import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import styles from "./leader.module.scss";
import GamesStatModal from "../GamesCard/GamesStatModal";
import { useState } from "react";
// import Player1 from "../../assets/images/user.svg"
// import Player2 from "../../assets/images/user2.svg"


export const getInitials = (str) => {
  if (str.length >= 2) {
    const firstLetter = str[0].toUpperCase();
    const secondLetter = str[1].toUpperCase();
    return `${firstLetter}${secondLetter}`;
  } else {
    return "";
  }
};

const LiveGameSessionsContent = ({ liveGame }) => {
  const { game,   currentRound, wager, users } = liveGame;
  

  const [open, setOpen ] = useState(false)

  const onClose = () => {
    setOpen(false);
  }

  const onOpen = () => {
    setOpen(true)
  }

  // console.log({users}, {id})

  // console.log({gameId})

  return (
    <>

      <div className={styles["leader"]}>
        <div className={styles["leader__user__pp"]}   >
          {/* <img src={game?.imageUrl} alt={user} style={{height: "55px", width: "48px"}} /> */}

          <div  className={styles["leader__user__pp__img"]}  style={{ backgroundImage: `url(${game?.imageUrl})` }} />

          <div >
            <p >Game Session</p>
            <p className={styles["leader__user__pp__value"]}>{game?.id}</p>
          </div>
        </div>
        <div className={styles["leader__user"]}> Current Round: {currentRound ? currentRound : 1}</div>
        <div className={styles["leader__user"]}>Stake: {wager?.amount} {wager?.type}</div>
        
        <div className={styles["leader__user"]}>
          {users.length === 1 ? (
            <div className={styles["leader__user__img"]}>
              { users[0]?.avatar === "" ?  <p>{getInitials(users[0]?.username)}</p> : <img src={users[0].avatar} alt={users[0].username} />}
            </div>
          ) : users.length > 1 ? (
            <>
              <div className={styles["leader__user__img"]}>
                { users[0]?.avatar === "" ? <p>{getInitials(users[0]?.username)}</p> : <img src={users[0].avatar} alt={users[0].username} />}
              </div>
              <p className={styles["leader__vs"]}>Vs</p>
              <div className={styles["leader__user__img"]}>
                { users[1]?.avatar === "" ? <p>{getInitials(users[1]?.username)}</p>  : <img src={users[1].avatar} alt={users[1].username} />}
              </div>
              </>
            ) : (
              <p>No users available</p>
            )}
        </div>


        <div className={styles["leader__user"]} onClick={onOpen} style={{cursor: "pointer"}}>
          <p style={{fontWeight: "700"}}>Game Stats</p>
          <HiOutlineArrowNarrowRight size="28px" />   
        </div>
      </div>



      { open && (
        <GamesStatModal
          onClose={onClose}

          liveGame={liveGame}
        />
      )}


    
    </>
  );
};

export default LiveGameSessionsContent;
