import React, { useContext } from 'react'
import styles from "./product.module.scss"
// import Avatar from "../../assets/images/avatar.svg"
import ProductContext from '@/context/Product/ProductContext';

// const team = [
//     { id: 1, name: 'Declan Rice', avatar: Avatar },
//     { id: 2, name: 'Declan Rice', avatar: Avatar },
//     { id: 3, name: 'Declan Rice', avatar: Avatar },
//     { id: 4, name: 'Declan Rice', avatar: Avatar },
//   ];
  
// const tags = ['Sass', 'NFT', 'Defi'];


const Steps = () => {

    const { 
     
        singleProduct
    }  = useContext(ProductContext)


    return (
        <div className={styles.stepsContainer}>

            {singleProduct?.productSteps?.map((step, index)=> (
                <div className={styles.stepsProduct}>
                    <h2>Step {index + 1}</h2>


                    <div 
                        className={styles.stepsBox}
                        style={{
                            background: `url(${step.imageUrl})`
                        }}

                    />
                
                </div>
            ))}
            


           


            
            
        </div>
    )
}

export default Steps