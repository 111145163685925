import { createContext, useReducer, useContext, useEffect, useState } from "react"
import axios from "../../utils/axios"
import ProductReducers from "./ProductReducers"
import AlertContext from "../Alert/AlertContext"
import { toast } from "react-toastify"

const ProductContext = createContext()

export const ProductProvider = ({ children }) => {
  const initialState = {
    loading: false,
    approveLoading: false,
    declineLoading: false,
    allProducts: [],
    singleProduct: {},
    singleProductLoading: false,


    comment: {},
    comments: [],


    productComment: {},
    productCommentsOnComments: [],


    commentLoading: false,
    likeLoading: false,


  }

  const setLoadingToFalse = (payload) => {
    dispatch({ type: "CLEAR_LOADING", payload: payload ? payload : null });
  };

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(ProductReducers, initialState)

  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext)


  const initialProductHuntId = () => {
    if (typeof window !== 'undefined') {
      const storedId = localStorage.getItem('productHuntId')
      return storedId ? storedId : null
    }
    return null
  }


  const [productHuntId, setProductHuntId] = useState(initialProductHuntId)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('productHuntId', productHuntId)
    }
  }, [productHuntId])
  

  const updateProductHuntId = (newId) => {
    setProductHuntId(newId)
  }
  




  // Fetch All Adventures
  const fetchAllProducts = async () => {
    dispatch({
      type: "SET_LOADING",
    })

    return await axios
      .get(`/admin/product-hunt/`)
      .then((res) => {
        const data = res.data.data
        dispatch({
          type: "SET_PRODUCT_HUNT",
          payload: data,
        })
        return data
        
      })
      .finally(() => {
        setLoadingToFalse()
      })
  }

    // Fetch Single Games
    const getSingleProduct = async (productId) => {
      dispatch({
        type: "SET_SINGLE_PRODUCT_LOADING",
      });
  
      try {
     
        const response = await axios.get(`/product-hunt/${productId}`);
    
        const data = response?.data?.data
  
        // console.log("this is ther",response?.data)
    
        dispatch({
          type: "SET_SINGLE_PRODUCT",
          payload: data,
        })

   
    
        setLoadingToFalse()
      } catch (error) {
        setLoadingToFalse();
      }
    };
  


  
  // approve project
  const approveProject = async (productId) => {
    dispatch({
      type: "APPROVE_LOADING",
    })

    const response = await axios
      .post(`/admin/product-hunt/${productId}/approve`)
      .then((res) => {
        setSuccessAlert("Product approved successfully!!!")
        fetchAllProducts()
        return true
      })
      .catch((err) => {
        return false
      })
      .finally(() => {
        setLoadingToFalse()
      })

    return response
  }


  
  
  // decline project
  const declineProject = async (productId) => {
    
    dispatch({
      type: "DECLINE_LOADING",
    })

    const response = await axios
      .post(`/admin/product-hunt/${productId}/decline`)
      .then((res) => {
        setSuccessAlert("Product declined successfully!!!")
        fetchAllProducts()
        return true
      })
      .catch((err) => {
        return false
      })
      .finally(() => {
        setLoadingToFalse()
      })

    return response
  }


  const upvoteProject = async (id) => {
    // dispatch({
    //   type: 'SET_UPVOTE_LOADING',
    // })
  
    try {
      // Make the actual API call
      const response = await axios.post(`/product-hunt/${id}/upvote`).then((res) => {
        setSuccessAlert("liked successfully!!!")
        // fetchAllProducts()
        return true
      })
      return response
    } catch (error) {
      // If API call fails, revert the local state to the previous state
      // if (error?.response?.status === 401) {
      //   setErrorAlert('Signin to upvote a product')
      //   
      // }
      setErrorAlert("Failed to upvote, try again")
      setLoadingToFalse()
    } finally {
      setLoadingToFalse()
    }
  }

  const downVoteProject = async (id) => {
    // dispatch({
    //   type: 'SET_UPVOTE_LOADING',
    // })
  
    try {
      // Make the actual API call
      const response = await axios.post(`/product-hunt/${id}/downvote`).then((res) => {
        setSuccessAlert("Un-liked successfully!!!")
        // fetchAllProducts()
        return true
      })
      return response
    } catch (error) {
      // If API call fails, revert the local state to the previous state
      // if (error?.response?.status === 401) {
      //   setErrorAlert('Signin to upvote a product')
      //   
      // }
      setErrorAlert("Failed to downvote, try again")
      setLoadingToFalse()
    } finally {
      setLoadingToFalse()
    }
  }




  // get comments on product Hunt Post
  const getCommentsOnProductHunt = async (productHuntId) => {
    const response = await axios.get(`/product-hunt/comment/${productHuntId}`)
    const data = await response.data.data

    dispatch({
      type: "SET_COMMENTS",
      payload: data,
    })
    setLoadingToFalse()

    return data
  }





  const likeComment = async (commentId) => {
    dispatch({
      type: "SET_LIKE_LOADING",
    })

    try {
        
      const response = await axios.post(`/product-hunt/comment/like/${commentId}`);
      const message = await response.data?.message;
      setSuccessAlert(message);
      const data = await response.data.data;
    } catch (error) {
      if(error.response.status === 401) {
        setErrorAlert("Sigin to comment on product")
      } else  {
        setErrorAlert(error?.response?.data?.message)
      }

    }


  
    setLoadingToFalse();
  };



    // get comments on product Hunt Post
    const getCommentsOnProductHuntComment = async (commentId) => {
      const response = await axios.get(`/product-hunt/comment/replies/${commentId}`)
      const data = await response.data.data
  
      dispatch({
        type: "SET_PRODUCT_COMMENTS_ON_COMMENTS",
        payload: data,
      })
      setLoadingToFalse()
  
      return data
    }


    const addCommentToProductHuntComment = async (formData) => {
      dispatch({
        type: "SET_COMMENT_LOADING",
      })
  
      try {
        const response = await axios.post(`/product-hunt/comment`, formData)
        const message = await response.data?.message
        setSuccessAlert(message)
        const data = await response.data.data
        await getCommentsOnProductHuntComment(formData?.parentId)
        
  
        dispatch({
          type: "SET_PRODUCT_HUNT_COMMENT",
          payload: data,
        })
      } catch (error) {
        if(error.response.status === 401) {
          setErrorAlert("Sigin to comment on product")
        } else  {
          setErrorAlert(error?.response?.data?.message)
        }
  
      }
  
      setLoadingToFalse()
    }
  
  
  






  return (
    <ProductContext.Provider
      value={{
        loading: state.loading,
        allProducts: state.allProducts,
        approveLoading: state.approveLoading,
        declineLoading: state.declineLoading,
        singleProductLoading: state.singleProductLoading,

        singleProduct: state.singleProduct,
        productHuntId,

        getSingleProduct,
        getCommentsOnProductHunt,

        approveProject,
        declineProject,
        fetchAllProducts,
        upvoteProject,
        downVoteProject,


        comment: state.comment,
        comments: state.comments,
        commentLoading: state.commentLoading,

        productComment: state.productComment,
        productCommentsOnComments: state.productCommentsOnComments,



        updateProductHuntId,
        getCommentsOnProductHuntComment,

        likeComment,

        addCommentToProductHuntComment
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export default ProductContext
