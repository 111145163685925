import React from "react";
import styles from "./single-user.module.scss";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import { BsArrowLeft } from "react-icons/bs";
import Spinner from "@/components/Spinner";

const SingleUser = () => {
  const params = useParams();
  const id = params.id;
  const { user, fetchSingleUser } = useContext(AdminContext);

  const goBack = () => {
    window.location.href = "/dashboard/users";
  };

  useEffect(() => {
    fetchSingleUser(id);
    // eslint-disable-next-line
  }, []);

  const { point } = user;

  return (
    <>
      {user?.id === id ? (
        <div className={styles["single__user"]}>
          <span
            onClick={() => goBack()}
            className={styles["single__user--back"]}
          >
            <BsArrowLeft /> &nbsp; Go Back
          </span>
          <div className={styles["single__user__header"]}>
            {/* { user?.avatar ? <img src={ user?.avatar } className={ styles["single__user--img"] } alt='user profile' /> : <img src={ profile__icon } className={ styles["single__user--img"] } alt='user profile' /> } */}
            <h1>{user?.fullName} </h1>
          </div>
          <div className={styles["single__user__grid"]}>
            <div className={styles["single__user__grid__item"]}>
              <h3>User Information</h3>
              <br />
              <p>Full Name: {user?.fullName}</p>
              <p>Email Address: {user?.email}</p>
              <p>Phone Number: {user?.phone}</p>
              <p>Role: {user?.role}</p>
            </div>
            <div className={styles["single__user__grid__item"]}>
              <h3>Account Information</h3>
              <br />
              <p>
                Account Verified:{" "}
                {user?.emailConfirmed && user?.phoneConfirmed
                  ? "Verified"
                  : "Not Verified"}
              </p>
              <p>
                Email Confirmed:{" "}
                {user?.emailConfirmed === true
                  ? "Email Verified"
                  : "Not Verified"}
              </p>
              <p>
                Phone Confirmed:{" "}
                {user?.phoneConfirmed === true
                  ? "Phone Verified"
                  : "Not Verified"}
              </p>
              <p>
                Account Suspended:{" "}
                {user?.isSuspended === true
                  ? "Account Suspended"
                  : "Not Suspended"}
              </p>
            </div>
            <div className={styles["single__user__grid__item"]}>
              <h3>Point Information</h3>
              <br />
              <p>Total Points: {point?.totalAccruedPoint}</p>
              <p>Last Points: {point?.lastPoint}</p>
              <p>Last Day Streak: {user?.lastDayStreak}</p>
              <p>Current Day Streak: {user?.currentDayStreak}</p>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default SingleUser;
