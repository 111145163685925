import styles from "./single-community.module.scss";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import BadgeContext from "@/context/Badge/BadgeContext"
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Spinner from "@/components/Spinner";
import { countries } from 'countries-list';

const SingleCommunityDetails = () => {
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const { singleCommunity, fetchSingleCommunity } = useContext(AdminContext);
  const { allBadges, fetchAllBadges } = useContext(BadgeContext);
  

  const goBack = () => {
    navigate("/dashboard/communities");
  };

  useEffect(() => {
    fetchSingleCommunity(id);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    fetchAllBadges()
    // eslint-disable-next-line
  }, [])


  // console.log(singleCommunity);

  const getBadgeName = () => {
    // Find the object with matching badgeId
    const badge = allBadges?.find(obj => obj?.id === singleCommunity?.badgeId);
      if (badge) {
        return (badge?.title); // Print the badge name
    }
    return "No Badge Required"
  
  };


  const getCountryNameByPhoneCode = (phoneCode) => {
    const countryName = Object.values(countries).find(
      country => country.phone === phoneCode
    );
    return countryName ? countryName.name : 'Country Not Found';
  }

  const phoneNumber = singleCommunity?.owner?.countryCode;
  const phoneCode = phoneNumber?.replace("+", "");
  const countryName = getCountryNameByPhoneCode(phoneCode);



  return (
    <>
      {singleCommunity?.id === id ? (
        <div className={styles["single__user"]}>
          <span
            onClick={() => goBack()}
            className={styles["single__user--back"]}
          >
            <BsArrowLeft /> &nbsp; Go Back
          </span>
          <div className={styles["single__user__header"]}>
            <h1>{singleCommunity?.name}</h1>
          </div>
          <div className={styles["single__user__result"]}>
            <h3>Community Information</h3>
            <br />
            <p>** Community Name: {singleCommunity?.name}</p>
            <p>
              ** Created At:{" "}
              {moment(singleCommunity?.createdAt).format("Do MMMM, YYYY")}
            </p>
            <p>** Community visibility: {singleCommunity?.visibility}</p>
            <p>** Community Status: {singleCommunity?.status}</p>
            <p>** Community Description: {singleCommunity?.description}</p>
            
            <p>** Community Required Badge:  {getBadgeName()}</p>
            <p>** Community Required Badge Amount:  {singleCommunity?.accessNFTBadgeAmount}</p>


            <p>
              ** Community Owner's Username: {singleCommunity?.owner?.username || "No Username"}
            </p>
            <p>** Community Owner's Name: {singleCommunity?.owner?.fullName || singleCommunity?.owner?.email}</p>
          
            <p>** Community Owner's Country: {countryName}</p>

            
            <p>** Community Owner's Verification: {singleCommunity?.owner?.emailConfirmed ? "TRUE" : "FALSE"}</p>

          
          
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default SingleCommunityDetails;
