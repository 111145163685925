import { createContext, useReducer, useState, useEffect } from "react";
import { gatewayToken, decode } from "@/utils/constants";
import axios from "../../utils/axios";
import AdminReducers from "./AdminReducers";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const initialState = {
    loading: false,
    adminDashboard: {},
    currentUser: {},
    users: [],
    usersByEmail: [],
    usersByAddress: [],
    user: {},
    allCommunities: [],
    pendingCommunities: [],
    approvedCommunities: [],
    declinedCommunities: [],
    singleCommunity: {},
    pagination: {},
    exchangeRates: [],
    wallets: [],
    configs: [],
  };
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(AdminReducers, initialState);

  // Check if user is authenticated
  useEffect(() => {
    const encodedToken = window.localStorage.getItem(gatewayToken);
    const token = decode(encodedToken);

    if (token) setIsUserAuthenticated(true);
  }, [isUserAuthenticated]);

  useEffect(() => {
    getCurrentUser();
    getAdminDashboard();
    fetchAllCommunities();
    fetchApprovedCommunities();
    fetchPendingCommunities();
    fetchDeclinedCommunities();
    // eslint-disable-next-line
  }, []);

  // Get Admin Dashboard
  const getAdminDashboard = async () => {
    const response = await axios.get(`/admin/dashboard`);
    const data = await response.data.data;
    dispatch({
      type: "SET_ADMIN_DETAILS",
      payload: data,
    });
  };

  // Get Current User
  const getCurrentUser = async () => {
    const response = await axios.get(`/user/me`);
    const data = await response.data.data;
    dispatch({
      type: "SET_CURRENT_ADMIN",
      payload: data,
    });
  };

  // Get User WALLET
  const getUserWallet = async () => {
    const response = await axios.get(`/wallet`);
    const data = await response.data.data;
    dispatch({
      type: "SET_WALLET",
      payload: data,
    });
  };

  // Fetch all users
  const fetchAllUsers = async (query) => {
    dispatch({
      type: "SET_LOADING",
    });
    const queryText = query ? query : "pageNumber=1&pageSize=20";
    const response = await axios.get(`/admin/user?${queryText}`);
    
    const data = await response.data.data.result;
    dispatch({
      type: "SET_ALL_USERS",
      payload: data,
    });
    const pagination = {
      currentPage: response.data.data.currentPage,
      pageSize: response.data.data.pageSize,
      totalPages: response.data.data.totalPages,
      totalCount: response.data.data.totalCount,
      hasPrevious: response.data.data.hasPrevious,
      hasNext: response.data.data.hasNext,
    };
    dispatch({
      type: "SET_PAGINATION",
      payload: pagination,
    });
    setLoadingToFalse();
  };

  // Fetch all users By Email or Address
  const fetchAllUsersByEmail = async (query) => {
    dispatch({
      type: "SET_LOADING",
    });
  

    const queryText = query ?  `/admin/user/email/${query}` : `/admin/user/address/${query}` ;
    const response = await axios.get(queryText);

    const data = response.data.data.result || response.data.data;
    dispatch({
      type: "SET_ALL_USERS_BY_EMAIL",
      payload: data,
    });
    setLoadingToFalse();
  };


  // Fetch all users By Address
  const fetchAllUsersByAddress = async (query) => {
    try {
      dispatch({
        type: "SET_LOADING",
      })
  
      const queryText = `/admin/user/address/${query}`;
      const response = await axios.get(queryText);
  
      const data = response.data.data
  
      dispatch({
        type: "SET_ALL_USERS_BY_ADDRESS",
        payload: data,
      })
  
      setLoadingToFalse()
    } catch (error) {
      dispatch({
        type: "RESET_USERS_BY_ADDRESS",
        payload: null,
      })
      setLoadingToFalse();
    }
  }
  
  // Fetch a single user
  const fetchSingleUser = async (id) => {
    const response = await axios.get(`/admin/user/${id}`);
    const data = await response.data.data;
    dispatch({
      type: "SET_SINGLE_USER",
      payload: data,
    });
  };

  // Admin Disable Users Account Endpoint
  const disableUserAccount = async (formData) => {
    const response = await axios.put(`/admin/user/suspend`, formData);
    const data = await response.data.data;
    dispatch({
      type: "SET_SINGLE_USERS",
      payload: data,
    });
  };

  // Admin Enable Users Account Endpoint
  const enableUserAccount = async (formData) => {
    const response = await axios.put(`/admin/user/unsuspend`, formData);
    const data = await response.data.data;
    dispatch({
      type: "SET_SINGLE_USERS",
      payload: data,
    });
  };

  // Make User An Admin
  const makeUserAdmin = async (formData) => {
    const response = await axios.put(`/admin/make-user-admin`, formData);
    const data = await response.data.data;
    dispatch({
      type: "SET_SINGLE_USERS",
      payload: data,
    });
  };

  // Make Admin A User
  const makeAdminUser = async (formData) => {
    const response = await axios.put(`/admin/make-admin-user`, formData);
    const data = await response.data.data;
    dispatch({
      type: "SET_SINGLE_USERS",
      payload: data,
    });
  };

  // Fetch All Communities
  const fetchAllCommunities = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.get(`/admin/community`);
    const data = await response.data.data.result;
    dispatch({
      type: "SET_ALL_COMMUNITIES",
      payload: data,
    });
    setLoadingToFalse();
  };

  // Fetch Pending Communities
  const fetchPendingCommunities = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.get(
      `/admin/community/applications/status?status=PENDING`
    );
    const data = await response.data.data.result;
    dispatch({
      type: "SET_PENDING_COMMUNITIES",
      payload: data,
    });
    setLoadingToFalse();
  };

  // Delete a single community
  const deleteCommunity = async (id) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.delete(`/admin/community/${id}`);
    const data = await response.data.data;
    setLoadingToFalse();
  };

  // Fetch Approved Communities
  const fetchApprovedCommunities = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.get(
      `/admin/community/applications/status?status=APPROVED`
    );
    const data = await response.data.data.result;
    dispatch({
      type: "SET_APPROVED_COMMUNITIES",
      payload: data,
    });
    setLoadingToFalse();
  };

  // Fetch Declined Communities
  const fetchDeclinedCommunities = async () => {
    // dispatch({
    //   type: "SET_LOADING"
    // })

    const response = await axios.get(
      `/admin/community/applications/status?status=DECLINED`
    );
    const data = await response.data.data.result;
    dispatch({
      type: "SET_DECLINED_COMMUNITIES",
      payload: data,
    });
    setLoadingToFalse();
  };

  // Fetch Single Community
  const fetchSingleCommunity = async (id) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.get(`/admin/community/id/${id}`);
    const data = await response.data.data;
    dispatch({
      type: "SET_COMMUNITY",
      payload: data,
    });
    setLoadingToFalse();
  };

  // Add Points To A User
  const topUpPoints = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.post(`/admin/point/issue`, formData);
    const data = await response.data.data;
    dispatch({
      type: "SET_SINGLE_USER",
      payload: data,
    });
    setLoadingToFalse();
  };

  // Get Exchange Rates
  const getExchangeRates = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.get(`/point/exchange/rates`);
    const data = await response.data.data;
    dispatch({
      type: "SET_EXCHANGE_RATES",
      payload: data,
    });
    setLoadingToFalse();
  };

  // Set Exchange Rates
  const setExchangeRates = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios.post(`/admin/point/exchange/rates`, formData);
    const data = await response.data.data;

    setLoadingToFalse();
    return data;
  };

  // get configs
  const getConfigs = async (query) => {
    dispatch({
      type: "SET_LOADING",
    });
    const queryText = query ? query : "pageNumber=1&pageSize=20";
    const data = await axios
      .get(`/admin/configs?${queryText}`)
      .then((res) => {
        const result = res.data.data.result;

        const pagination = {
          currentPage: res.data.data.currentPage,
          pageSize: res.data.data.pageSize,
          totalPages: res.data.data.totalPages,
          totalCount: res.data.data.totalCount,
          hasPrevious: res.data.data.hasPrevious,
          hasNext: res.data.data.hasNext,
        };
        dispatch({
          type: "SET_CONFIGS",
          payload: result,
        });
        dispatch({
          type: "SET_PAGINATION",
          payload: pagination,
        });
        return true;
      })
      .catch(() => {
        dispatch({
          type: "SET_CONFIGS",
          payload: [],
        });
        dispatch({
          type: "SET_PAGINATION",
          payload: {},
        });
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });
    return data;
  };

  // set configs
  const setConfig = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios
      .post(`/admin/config`, formData)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });

    return response;
  };

  // update configs
  const updateConfig = async (id, formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios
      .put(`/admin/config/${id}`, formData)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });

    return response;
  };

  // Delete configs
  const deleteConfig = async (id) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios
      .delete(`/admin/config/${id}`)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });

    return response;
  };

  // Decline Approved Communities
  const declineApprovedCommunities = async (formData) => {
    setLoadingToFalse();

    const response = await axios.put(`/admin/community/status`, formData);
    await response.data.data;
  };

  // Approve Pending or Declined Communities
  const approveCommunities = async (formData) => {
    setLoadingToFalse();

    const response = await axios.put(`/admin/community/status`, formData);
    await response.data.data;
  };

  // Method to Set Loading to False
  const setLoadingToFalse = (payload) => {
    dispatch({ type: "CLEAR_LOADING", payload: payload ? payload : null });
  };

  return (
    <AdminContext.Provider
      value={{
        admin: state.admin,
        wallets: state.wallets,
        configs: state.configs,
        loading: state.loading,
        adminDashboard: state.adminDashboard,
        currentUser: state.currentUser,
        users: state.users,
        user: state.user,
        pagination: state.pagination,
        allCommunities: state.allCommunities,
        pendingCommunities: state.pendingCommunities,
        approvedCommunities: state.approvedCommunities,
        declinedCommunities: state.declinedCommunities,
        singleCommunity: state.singleCommunity,
        isUserAuthenticated: state.isUserAuthenticated,
        exchangeRates: state.exchangeRates,

        usersByEmail: state.usersByEmail,
        usersByAddress: state.usersByAddress,

        fetchAllUsersByAddress,
        
        
        makeUserAdmin,
        makeAdminUser,
        getUserWallet,
        fetchAllCommunities,
        fetchPendingCommunities,
        fetchApprovedCommunities,
        fetchDeclinedCommunities,
        fetchSingleCommunity,
        fetchAllUsers,
        fetchAllUsersByEmail,
        fetchSingleUser,
        getAdminDashboard,
        getCurrentUser,
        disableUserAccount,
        enableUserAccount,
        setLoadingToFalse,
        declineApprovedCommunities,
        approveCommunities,
        topUpPoints,
        getExchangeRates,
        setExchangeRates,
        deleteCommunity,
        getConfigs,
        setConfig,
        updateConfig,
        deleteConfig,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
