import React from "react";
import CommunityCard from "../CommunityCard";
import { useContext } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import Spinner from "../Spinner";
import styles from "./community-card-wrapper.module.scss";

const CommunityCardWrapper = () => {
  const { allCommunities } = useContext(AdminContext);

  return (
    <div className={styles["community__card__wrapper"]}>
      {allCommunities.length > 0 ? (
        allCommunities.map((community) => (
          <CommunityCard key={community?.id} community={community} />
        ))
      ) : (
        <>No community</>
      )}
    </div>
  );
};

export default CommunityCardWrapper;
