import { useContext, useState, useEffect } from "react";
import styles from "./adventure_module.module.scss";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { validationSchema } from "@/utils/helpers";
import { ILesson, IQuestion } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import AlertContext from "@/context/Alert/AlertContext";
import Swal from "sweetalert2";
import Loader from "@/components/Loader";
import { AiFillPlusCircle } from "react-icons/ai";

interface IProps {
  currentQuizQuestion: IQuestion;
  quizQuestionType: "edit" | "add" | "";
  currentLesson: ILesson;
  isChanged: boolean;
  setIsChanged: any;
  setEditType: any;
}

const EditQuiz = ({
  currentQuizQuestion,
  quizQuestionType,
  currentLesson,
  isChanged,
  setIsChanged,
  setEditType,
}: IProps) => {
  const {
    createQuizQuestion,
    createQuiz,
    updateQuizQuestion,
    deleteQuizQuestion,
    deleteQuizQuestionOption,
  } = useContext(AdventureContext);
  const { setErrorAlert } = useContext(AlertContext);

  const initialState = {
    index: 0,
    show: true,
    text: "",
    options: []!,
  };
  const [formData, setFormData] = useState<IQuestion>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (quizQuestionType === "edit") {
      setFormData({
        ...formData,
        index: currentQuizQuestion.index,
        show: currentQuizQuestion.show,
        text: currentQuizQuestion.text,
        options: currentQuizQuestion.options.map((option) => option),
      });
    }
  }, [currentQuizQuestion]);

  const { dynamic, dynamicNumber } = validationSchema;

  const handleCheckBoxChange = (e: CheckboxChangeEvent, id: number) => {
    const selectedOption = formData?.options.find(
      (option, index) => index === id
    )!;
    selectedOption.isCorrect = e.target.checked;
    setFormData({ ...formData });
  };

  const handleQuizTextChange = (e: any) => {
    setFormData({
      ...formData,
      text: e.target.value,
    });
  };

  const handleQuizAddOption = () => {
    const options = formData.options;
    options.push({
      text: "",
      isCorrect: false,
    });
    setFormData({ ...formData, options });
  };

  const handleQuizOptionTextChange = (optionIndex: number, e: any) => {
    const selectedOption = formData?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.text = e.target.value;
    setFormData({ ...formData });
  };

  const handleQuizDeleteOption = async (
    optionId: string,
    optionIndex: number
  ) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        if (optionId) {
          await deleteQuizQuestionOption(optionId).then((res: any) => {
            const filteredOptions = formData?.options.filter(
              (option, index) => index !== optionIndex
            )!;
            setFormData({ ...formData, options: filteredOptions });
          });
        } else {
          const filteredOptions = formData?.options.filter(
            (option, index) => index !== optionIndex
          )!;
          setFormData({ ...formData, options: filteredOptions });
        }
        setIsLoading(false);
      }
    });
  };

  const handleDeleteQuizQuestion = () => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (quizQuestionType === "add") {
          setEditType("normal");
        } else if (quizQuestionType === "edit") {
          await deleteQuizQuestion(currentQuizQuestion.id!).then((res: any) => {
            setIsChanged(!isChanged);
          });
        }
      }
    });
  };

  const checkForSubmission = () => {
    if (formData.text === "" || !formData.text) {
      setErrorAlert(`Quiz does not have a question.`);
      return false;
    } else if (formData.options.length === 0) {
      setErrorAlert(`Quiz does not have an option.`);
      return false;
    } else if (formData.options.length === 1) {
      setErrorAlert(`Quiz must have more than one option.`);
      return false;
    } else if (formData.options.length > 1) {
      let isCorrectOption = false;
      let isTextEmpty = false;
      for (let i = 0; i < formData.options.length; i++) {
        const option = formData.options[i];
        if (option.isCorrect) {
          isCorrectOption = true;
        }
        if (option.text === "" || option.text.length === 0) {
          isTextEmpty = true;
        }
      }

      if (!isCorrectOption) {
        setErrorAlert(
          `Quiz must have at least one option marked as an answer.`
        );
        return false;
      }
      if (isTextEmpty) {
        setErrorAlert(`Quiz must not have an empty option.`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const result = checkForSubmission();
    if (result) {
      if (quizQuestionType === "add") {
        if (currentLesson.quizId) {
          const data = {
            quizId: currentLesson.quizId,
            text: formData.text,
            options: formData.options,
          };
          await createQuizQuestion(data).then((res: any) => {
            setIsChanged(!isChanged);
          });
        } else {
          const data = {
            lessonId: currentLesson.id,
            description: `${currentLesson.name} Quiz`,
            questions: [
              {
                text: formData.text,
                options: formData.options,
              },
            ],
          };
          await createQuiz(data).then((res: any) => {
            setIsChanged(!isChanged);
          });
        }
      } else {
        const data = {
          text: formData.text,
          options: formData.options,
        };
        await updateQuizQuestion(currentQuizQuestion.id, data).then(
          (res: any) => {
            setIsChanged(!isChanged);
          }
        );
      }
    }
  };

  const handleBack = () => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to cancel changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = checkForSubmission();
        if (result) {
          setEditType("normal");
        }
      }
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <h5 className={styles["module__back"]} onClick={handleBack}>
        <BsArrowLeft /> Back
      </h5>
      <br />
      <div className={styles["module__module_tab"]}>
        <h1 className={styles["module__module_tab__title"]}>
          {quizQuestionType === "add" ? "Add Quiz" : "Edit Quiz"}
        </h1>
        <div>
          &nbsp;&nbsp;
          {/* <IoIosArrowDropup /> */}
          <MdDelete
            size="1.3rem"
            cursor="pointer"
            fill="red"
            onClick={handleDeleteQuizQuestion}
          />
        </div>
      </div>

      {/* QUIZ */}
      <div className={styles["module__module_display"]}>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="name">Quiz</label>
          <input
            type="text"
            placeholder="What is your question?"
            className={styles["module__module_display_input"]}
            defaultValue={formData.text}
            onChange={(e) => handleQuizTextChange(e)}
          />
        </div>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="name">Setup options for users to select from</label>
          {formData.options.map((option, optionIndex) => (
            <>
              <input
                type="text"
                placeholder="Option 1"
                className={styles["module__module_display_input"]}
                defaultValue={option.text}
                onChange={(e) => handleQuizOptionTextChange(optionIndex, e)}
              />
              <div className={styles["module__remove_option"]}>
                <span
                  onClick={() =>
                    handleQuizDeleteOption(option.id!, optionIndex)
                  }
                >
                  Remove option
                </span>
              </div>
            </>
          ))}
        </div>
        <div className={styles["module__another_option"]}>
          <span onClick={() => handleQuizAddOption()}>
            <AiFillPlusCircle fill="#1579E4" /> Add option
          </span>
        </div>
        <div>
          {formData.options.length > 0 ? (
            <>
              <label htmlFor="name">Choose answer(s)</label>
              <div className={styles["quiz__option"]}>
                {formData.options.map((item, index) => (
                  <Checkbox
                    onChange={(e) => handleCheckBoxChange(e, index)}
                    checked={item.isCorrect}
                  >
                    {item.text}
                  </Checkbox>
                ))}
              </div>
            </>
          ) : null}
        </div>
        <button onClick={handleSubmit} className={styles["module__button"]}>
          Save Quiz
        </button>
      </div>
    </>
  );
};

export default EditQuiz;
