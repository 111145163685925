import React from 'react'
import styles from "../StatsCardWrapper/stats-card-wrapper.module.scss"
import StatsCard from '@/components/StatsCard'
import { HiUsers } from "react-icons/hi2"
// import { MdOutlineDashboardCustomize } from "react-icons/md"
import { useContext, useEffect } from 'react'
import AdminContext from '@/context/AdminContext/AdminContext'

const AdventuresStatsCardWrapper = () => {

  const { adminDashboard, getAdminDashboard } = useContext(AdminContext)

  useEffect(() => { 
    getAdminDashboard()
    // eslint-disable-next-line
  }, [])

  const { adventureStat } = adminDashboard
  
  return (
    <div className={ styles["stats__card__wrapper"] }>
         <div className={ styles["stats__card__wrapper--content"] }>
            <StatsCard 
                title='All Adventures' 
                value={ adventureStat?.allAdventures } 
                icon={ <HiUsers size="1.5rem" fill='#22bb33' color='red' /> } 
              />
              <StatsCard 
                title='Total Adventures Last Month' 
                value={ adventureStat?.totalAdventuresLastMonth } 
                icon={ <HiUsers size="1.5rem" fill='#721C24' color='red' /> } 
              />
              <StatsCard 
                title='Total Adventures This Month' 
                value={ adventureStat?.totalAdventuresThisMonth } 
                icon={ <HiUsers size="1.5rem" fill='#3F434A' color='red' /> } 
              />
         </div>
    </div>
  )
}

export default AdventuresStatsCardWrapper