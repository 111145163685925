import { Cloudinary as CoreCloudinary, Util } from "cloudinary-core";

export const url = (publicId, options) => {
  try {
    const scOptions = Util.withSnakeCaseKeys(options);
    const cl = CoreCloudinary.new();
    return cl.url(publicId, scOptions);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const openUploadWidget = (options, callback) => {
  const option = {
    ...options,
    cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
    uploadPreset:
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "ml_default",
  };
  return window.cloudinary.openUploadWidget(option, callback);
};
