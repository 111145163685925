import React from 'react'
import styles from "./stats-card-wrapper.module.scss"
import UsersStatsCardWrapper from '../UsersStatsCardWrapper'
import AdventuresStatsCardWrapper from '../AdventuresStatWrapper'
import CommunitiesStatsCardWrapper from '../CommunitiesStatsCardWrapper'
import { useState } from 'react'
const StatsCardWrapper = () => {
  const [usersStats, setUsersStats] = useState(true)
  const [adventuresStats, setAdventuresStats] = useState(false)
  const [communityStats, setCommunityStats] = useState(false)

  const showUsersStats = () => {
    setUsersStats(true)
    setAdventuresStats(false)
    setCommunityStats(false)
  }

  const showAdventuresStats = () => {
    setUsersStats(false)
    setAdventuresStats(true)
    setCommunityStats(false)
  }

  const showCommunitiesStats = () => {
    setUsersStats(false)
    setAdventuresStats(false)
    setCommunityStats(true)
  }

  return (
    <div className={ styles["stats__card__wrapper"] }>
      <div className={ styles["stats__card__wrapper__tabs"] }>
        <span onClick={ () => showUsersStats() } className={ styles["stats__card__wrapper__tabs--users"] }>Users Stats</span>
        <span onClick={ () => showCommunitiesStats() } className={ styles["stats__card__wrapper__tabs--communities"] }>Communities Stats</span>
        <span onClick={ () => showAdventuresStats() } className={ styles["stats__card__wrapper__tabs--adventures"] }>Adventures Stats</span>
      </div>
         <div className={ styles["stats__card__wrapper--content"] }>
           { usersStats ? <UsersStatsCardWrapper /> : null }
           { adventuresStats ? <AdventuresStatsCardWrapper /> : null }
           { communityStats ? <CommunitiesStatsCardWrapper /> : null }
         </div>
    </div>
  )
}

export default StatsCardWrapper