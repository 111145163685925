import React from "react";
import styles from "./quizzes-wrapper.module.scss";
import Spinner from "../Spinner";
import { useContext, useEffect, useState } from "react";
import Search from "../Search";
import AdventureContext from "@/context/Adventure/AdventureContext";
import DailyQuizSubmission from "../DailyQuizSubmission";

const DailyQuizSubmissionWrapper = ({ id }) => {
  const { loading, fetchQuizSubmissions, pagination, submissions } =
    useContext(AdventureContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleSearch = (name) => {
    setSearchTerm(name);

    const filteredSubmissions = submissions.filter((submission) => {
      return Object.values(submission.user)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setSearchResult(filteredSubmissions);
  };

  const handlePaginationPrev = async () => {
    const query = `pageNumber=${pagination?.currentPage - 1}&pageSize=${
      pagination?.pageSize
    }`;
    await fetchQuizSubmissions(id, query);
  };

  const handlePaginationNext = async () => {
    const query = `pageNumber=${pagination?.currentPage + 1}&pageSize=${
      pagination?.pageSize
    }`;
    await fetchQuizSubmissions(id, query);
  };

  const submissionLists = searchTerm === "" ? submissions : searchResult;

  return loading ? (
    <Spinner />
  ) : (
    <div className={styles["quiz__wrapper"]}>
      <Search handleChange={handleSearch} label="quiz" />
      <div className={styles["quiz__wrapper__container"]}>
        <div className={styles["quiz__wrapper__grid"]}>
          <div>User name</div>
          <div>Quiz description</div>
          <div>Date Submitted</div>
          <div>Action</div>
        </div>
        {loading ? (
          <Spinner />
        ) : submissionLists?.length > 0 ? (
          submissionLists?.map((submission) => (
            <DailyQuizSubmission key={submission.id} submission={submission} />
          ))
        ) : (
          <>No submitted quizzes available</>
        )}
      </div>
      <div className={styles["quiz__wrapper__pagination"]}>
        <span>
          Page {pagination?.currentPage} - {pagination?.totalPages}
        </span>
        <span>
          {(pagination?.currentPage - 1) * pagination?.pageSize + 1} -
          {pagination?.hasNext
            ? pagination?.pageSize * pagination?.currentPage
            : pagination?.totalCount}
        </span>
      </div>
      <div className={styles["quiz__wrapper__pagination"]}>
        {pagination?.hasPrevious ? (
          <button
            className={styles["quiz__wrapper__pagination__button"]}
            onClick={handlePaginationPrev}
            disabled={!pagination?.hasPrevious}
          >
            Prev
          </button>
        ) : null}
        {pagination?.hasNext ? (
          <button
            className={styles["quiz__wrapper__pagination__button"]}
            onClick={handlePaginationNext}
            disabled={!pagination?.hasNext}
          >
            Next
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default DailyQuizSubmissionWrapper;
