import React from 'react'
import styles from "./dashboard-navbar.module.scss"
import { Link } from 'react-router-dom'
import logo from "../../assets/images/gateway-logo.png"

const DashboardNavbar = () => {
    
  return (
    <div className={ styles["navbar"] }>
        <div className="container">
            <div className={ styles["navbar__content"] }>
                <div className={ styles["navbar__content__logo"] }>
                        <a href='/'><img src={ logo } height={50} width={150} alt='Gateway Logo' /></a>
                    <Link to="/">
                    </Link>
                </div>
                
             
                    <div className={ styles["navbar__content__menu"] }>
                        <div className={ styles["navbar__content__menu__left"] }></div>
                        {/* <div className={ styles["navbar__content__menu__right"] }>
                            <Link to="/auth/sign-up"><a a href='/'>Join Gateway</a></Link>
                            <Link to="/auth/sign-in"><a a href='/'>Sign In</a></Link>
                        </div> */}
                    </div>
    
            </div>
        </div>
    </div>
  )
}

export default DashboardNavbar