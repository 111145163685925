import React, { useContext, useState } from 'react'
// import Input from '@/components/Input'
import styles from './games.module.scss'
import refresh from '../../assets/icons/refresh.svg'
import exportIcon from '../../assets/icons/export.svg'
import ActivitiesContent from '@/components/ActivitiesContent';
import User from "../../assets/images/pp.svg"
import GameContext from '@/context/Games/GameContext';

// const allActivities = [
//   { profileImg: User, user: 'David Iris', email: "d.iris@gmail.com",  username: "@diris34543", date: "24th Jan, 2024", actionDone: "Bought 300 GP" },
//   { profileImg: User, user: 'David Iris', email: "d.iris@gmail.com",  username: "@diris34543", date: "24th Jan, 2024", actionDone: "Upgraded Amadioh" },
//   { profileImg: User, user: 'David Iris', email: "d.iris@gmail.com",  username: "@diris34543", date: "24th Jan, 2024", actionDone: "Won Game Session" },
//   { profileImg: User, user: 'David Iris', email: "d.iris@gmail.com",  username: "@diris34543", date: "24th Jan, 2024",  actionDone: "Upgraded Amadioh"},
//   { profileImg: User, user: 'David Iris', email: "d.iris@gmail.com",  username: "@diris34543", date: "24th Jan, 2024", actionDone: "Bought 900 GP" },
//   { profileImg: User, user: 'David Iris', email: "d.iris@gmail.com",  username: "@diris34543", date: "24th Jan, 2024", actionDone: "Won Game Session" },
//   { profileImg: User, user: 'David Iris', email: "d.iris@gmail.com",  username: "@diris34543", date: "24th Jan, 2024", actionDone: "Bought 300 GP" }
// ];




const Activities = () => {

  const [rotate, setRotate] = useState(false)

  const handleRefresh = () => {
    setRotate(true)
  }
  
  const handleClick = () => {
    setRotate(false)
  }


  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

  const handleCheckboxChange = (index) => {
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };



  const { allActivities }  = useContext(GameContext)

    
    return (
        
      <div className={styles["games__container__available"]}>
        <h2>Activities</h2>


        <nav>

          <select name='menu' id='menu'>
            <optgroup>
              <option value='ALL'>Current Activities</option>
              <option value='PREVIOUS'>Previous Activities</option>
              <option value='NEXT'>Next Activities</option>
            </optgroup>
          </select>

          {/* refresh */}
          <div className={styles['refresh']} onClick={handleRefresh}>
            <div className={`anime ${rotate ? styles.rotate : ''}`}>
              <img src={refresh} alt='refresh' />
            </div>
            <p>Refresh</p>
          </div>

          {/* export */}
          <div className={styles['export']} onClick={handleClick}>
            <div>
              <img src={exportIcon} alt='export' />
            </div>
            <p>Export</p>
          </div>
          {/* </Form> */}
        </nav>

        <div className={styles["activities"]}>
            <>
              <div className={styles["activities__container"]}>
                <div className={styles["activities__rank"]}>
                  <div className={styles["activities__rank__header"]}>
                    <div className={styles["activities__rank__header--item1"]}>
                      User
                    </div>
                    <div className={styles["activities__rank__header--item2"]}>
                      Email
                    </div>
                    <div className={styles["activities__rank__header--item3"]}>
                      Username
                    </div>
                    <div className={styles["activities__rank__header--item3"]}>
                      Date
                    </div>

                    <div className={styles["activities__rank__header--item3"]}>
                      Action Date
                    </div>
                  </div>
                </div>
                {allActivities &&
                  allActivities?.length > 0 &&
                  allActivities?.map((activity, index) => (
                    <ActivitiesContent 
                      key={activity.id} 
                      activity={activity} 
                      index={index}
                      isSelected={!!selectedCheckboxes[index]}
                      onCheckboxChange={handleCheckboxChange}
                    />
                  ))}
              </div>
            </>
        </div>




      
      </div>  
    )
}

export default Activities