import React from 'react'
import BlogsWrapper from '@/components/BlogWrapper'
import styles from "./blogs.module.scss"
import { FaPlus } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'

const Blogs = () => {

  const navigate = useNavigate()

  const handleRedirect = () => {
    navigate("/dashboard/blogs/new")
  }

  return (
    <div className={ styles["blogs"] }>
      <div className={ styles["blogs__header"] }>
        <div>
          <h2>Blogs</h2>
          <p>Create and manage all your blog posts from one dashboard</p>
        </div>
          <div onClick={ () => handleRedirect() } className={ styles["blogs__header__create"] }><FaPlus /> &nbsp; Create New Post</div>
        </div>
      <BlogsWrapper />
    </div>
  )
}

export default Blogs