import { Route, Routes } from 'react-router-dom';
import Alert from '@/components/Alert';
import SignIn from '@/pages/sign-in';
import Overview from '@/pages/Overview';
import DashboardLayout from './components/DashboardLayout';
import Users from '@/pages/Users';
import SingleUser from './pages/SingleUser';
import Communities from '@/pages/Communities';
import SingleCommunityDetails from './pages/Singlecommunity';
import SingleAdventureDetails from './pages/SingleAdventure';
import Adventures from '@/pages/Adventures';
import Create from '@/pages/Adventures/Create';
import Blogs from './pages/Blogs.jsx';
import Products from './pages/Products';
import AddBlog from './pages/AddBlog';
import SingleBlog from './pages/SingleBlog';
import EditBlog from './pages/EditBlog';
// import EditBlog from "./pages/EditBlog";
import { AdminProvider } from './context/AdminContext/AdminContext';
import { AuthProvider } from './context/Auth/AuthContext';
import { BlogsProvider } from './context/Blogs/BlogsContext';
import { AdventureProvider } from './context/Adventure/AdventureContext';
import { BadgeProvider } from './context/Badge/BadgeContext';

import { AlertProvider } from './context/Alert/AlertContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Edit from './pages/Adventures/Edit';
import Settings from './pages/Settings';
import DailyQuiz from './pages/DailyQuiz';
import CreateDailyQuiz from './pages/CreateDailyQuiz';
import EditDailyQuiz from './pages/EditDailyQuiz';
import { ProductProvider } from './context/Product/ProductContext';
import Games from './pages/Games';
import GameDetails from './pages/GamesDetails';
import { GamesProvider } from './context/Games/GameContext';
import ProductDetails from './pages/Products/ProductDetails';

function App() {
  return (
    <AlertProvider>
      <AdminProvider>

        <AuthProvider>
          <BlogsProvider>
            <GamesProvider>
              <BadgeProvider>
                <ProductProvider>
                  <AdventureProvider>
                    <Alert />
                    <ToastContainer
                      position='top-right'
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme='dark'
                    />
                    <Routes>
                      <Route path='/' element={<SignIn />} />
                      <Route path='/sign-in' element={<SignIn />} />
                      <Route path='/dashboard' element={<DashboardLayout />}>
                        <Route path='/dashboard/overview' element={<Overview />} />
                        <Route path='/dashboard/users' element={<Users />} />
                        <Route
                          path='/dashboard/users/:id'
                          element={<SingleUser />}
                        />
                        <Route
                          path='/dashboard/communities'
                          element={<Communities />}
                        />
                        <Route
                          path='/dashboard/communities/:id'
                          element={<SingleCommunityDetails />}
                        />
                        <Route
                          path='/dashboard/adventures'
                          element={<Adventures />}
                        />

                        <Route 
                          path='/dashboard/products' 
                          element={<Products />} 
                        />
                         <Route 
                          path='/dashboard/products/:id' 
                          element={<ProductDetails />} 
                        />



                        <Route path='/dashboard/games' element={<Games />} />
                        <Route
                          path='/dashboard/games/:id'
                          element={<GameDetails />}
                        />




                        <Route path='/dashboard/settings' element={<Settings />} />

                        <Route
                          path='/dashboard/adventure/create'
                          element={<Create />}
                        />

                        <Route
                          path='/dashboard/adventure/edit/:adventureId'
                          element={<Edit />}
                        />

                        <Route
                          path='/dashboard/adventure/:id'
                          element={<SingleAdventureDetails />}
                        />

                        <Route path='/dashboard/blogs' element={<Blogs />} />

                        <Route path='/dashboard/blogs/new' element={<AddBlog />} />

                        <Route
                          path='/dashboard/blogs/:slug'
                          element={<SingleBlog />}
                        />

                        <Route
                          path='/dashboard/blogs/edit/:slug'
                          element={<EditBlog />}
                        />

                        <Route
                          path='/dashboard/daily-quiz'
                          element={<DailyQuiz />}
                        />

                        <Route
                          path='/dashboard/daily-quiz/create'
                          element={<CreateDailyQuiz />}
                        />

                        <Route
                          path='/dashboard/daily-quiz/:id'
                          element={<EditDailyQuiz />}
                        />
                      </Route>
                    </Routes>
                  </AdventureProvider>
                </ProductProvider>
              </BadgeProvider>
            </GamesProvider>
            
          </BlogsProvider>
        </AuthProvider>
      </AdminProvider>
    </AlertProvider>
  );
}

export default App;
