import styles from './product.module.scss';
import OwnerInfo from './ownerInfo';
import etherVault from '../../assets/icons/etherVault.svg';
import options from '../../assets/icons/options.svg';
import { FaFacebookF, FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import { useContext, useState } from 'react';
import ProductContext from '@/context/Product/ProductContext';
import { useNavigate } from 'react-router-dom';

const SocialMediaIcon = ({ telegramLink, twitterLink, facebookLink }) => {
  const openLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      {telegramLink && (
        <FaTelegramPlane onClick={() => openLink(telegramLink)} style={{ cursor: 'pointer' }} />
      )}
      {twitterLink && <FaTwitter onClick={() => openLink(twitterLink)} style={{ cursor: 'pointer' }} />}
      {facebookLink && <FaFacebookF onClick={() => openLink(facebookLink)} style={{ cursor: 'pointer' }} />}
    </>
  );
};

const Box = ({
  status,
  productLogo,
  description,
  owner,
  supportedCountries,
  appleStoreUrl,
  name,
  id,
  openModalId,
  setOpenModalId,
  slug
}) => {
  let statusClassName = '';

  if (status === 'PENDING') {
    statusClassName = styles['status__pending'];
  } else if (status === 'APPROVED') {
    statusClassName = styles['status__active'];
  } else if (status === 'DECLINED') {
    statusClassName = styles['status__denied'];
  }




  const { declineProject, approveProject, approveLoading, declineLoading, 
    updateProductHuntId }  = useContext(ProductContext)

  const handleOption = () => {
    if (openModalId === id) {
      setOpenModalId(null); // Close the modal if it is already open
    } else {
      setOpenModalId(id); // Open the modal for the current product
    }
  };

  const navigate = useNavigate();

  const handleView = (id, slug)  => {
    navigate('/dashboard/products/' + slug); 
    updateProductHuntId(id)
  }

  const handleApprove = async (id) => {
  
    try {
      const response = await approveProject(id);
      setOpenModalId(!id)
      return true;
    } catch (error) {
      // Handle the error as needed
      return false;
    }
  };
  

  const handleDecline= async (id) => {
    try {
      const response = await declineProject(id);
      setOpenModalId(!id)
      return response;
    } catch (error) {
      console.error(`Failed to approve product ${id}.`, {error});
      // Handle the error as needed
      // return false;
    }
  };

  return (
    <div className={styles['flex__box']}>
      {/* top bar */}
      <div className={styles['top__bar']}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <div className={styles['top__bar__logo']}>
            <img src={productLogo} alt='company logo' />
          </div>
          <p style={{ fontWeight: '900' }}>{name}</p>
        </div>

        <div className={statusClassName}>
          {status === 'PENDING' && <div className={styles.pending}></div>}
          {status === 'APPROVED' && <div className={styles.approved}></div>}
          {status === 'DENIED' && <div className={styles.denied}></div>}
          <p>{status}</p>
        </div>
      </div>

      {/* product description */}
      <p className={styles['description']}>{description}</p>

      <OwnerInfo owner={owner} countries={supportedCountries} />

      <footer>
        <div className={styles['footer__icons']}>
          <SocialMediaIcon telegramLink='https://example.com/telegram' />
          <SocialMediaIcon twitterLink='https://example.com/twitter' />
          <SocialMediaIcon facebookLink='https://example.com/facebook' />
        </div>

        <div className={styles.optionsContainer}>
          <img src={options} alt='options' onClick={handleOption} />

          {openModalId === id && (
            <div className={styles.modal}>
              <p onClick={() => handleView(id, slug)}>View Product</p>
              <p onClick={() => handleApprove(id)}>{approveLoading ?  "approving. . .": "Approve Product"}</p>
              <p className={styles.denyProduct} onClick={() => handleDecline(id)}>{declineLoading ? "Declining. . ." :  "Deny Product"}</p>
            </div>
          )}
        </div>
      </footer>
    </div>
  );
};

export default Box;
