import React, { useState } from 'react';
import styles from "./communities.module.scss";
import AllCommunities from '@/components/AllCommunities';
import ApprovedCommunities from '@/components/ApprovedCommunities';
import DisapprovedCommunities from '@/components/DisapprovedCommunities';
import PendingCommunities from '@/components/PendingCommunities';

const Communities = () => {
  const [activeTab, setActiveTab] = useState('all');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles["communities"]}>
      <h2>Communities</h2>
      <p>Manage your communities and their contents, posts etc...!</p>

      <div className={styles["communities__tabs"]}>
        <span
          onClick={() => handleTabChange('all')}
          className={`${styles["communities__tabs--all"]} ${activeTab === 'all' ? styles.active : ''}`}
        >
          All Communities
        </span>
        <span
          onClick={() => handleTabChange('approved')}
          className={`${styles["communities__tabs--users"]} ${activeTab === 'approved' ? styles.active : ''}`}
        >
          Approved Communities
        </span>
        <span
          onClick={() => handleTabChange('pending')}
          className={`${styles["communities__tabs--communities"]} ${activeTab === 'pending' ? styles.active : ''}`}
        >
          Pending Communities
        </span>
        <span
          onClick={() => handleTabChange('disapproved')}
          className={`${styles["communities__tabs--adventures"]} ${activeTab === 'disapproved' ? styles.active : ''}`}
        >
          Declined Communities
        </span>
      </div>

      {activeTab === 'all' && <AllCommunities />}
      {activeTab === 'approved' && <ApprovedCommunities />}
      {activeTab === 'pending' && <PendingCommunities />}
      {activeTab === 'disapproved' && <DisapprovedCommunities />}
    </div>
  );
};

export default Communities;
