import React from "react";
import styles from "./quiz.module.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import DailyQuizSubmissionModal from "../DailyQuizSubmissionModal";
import ModalComponent from "../ModalComponent";

const DailyQuiz = ({ submission }) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={styles["submission"]}>
      <>
        <span className={styles["submission__first__element"]}>
          {submission?.user?.fullName}
        </span>
        <span>{submission?.quiz?.description}</span>
        <span>{moment(submission?.createdAt).format("Do MMMM YYYY")}</span>

        <span className={styles["submission__actions"]}>
          <span
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            className={styles["submission__action--success"]}
          >
            View
          </span>
        </span>

        <ModalComponent show={showModal} closeModal={handleClick}>
          <DailyQuizSubmissionModal submission={submission} />
        </ModalComponent>
      </>
    </div>
  );
};

export default DailyQuiz;
