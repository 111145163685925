import { createContext, useReducer } from "react";
import AlertReducers from "./AlertReducers";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const initialState = {
    toggleAlert: false,
    type: "success",
    message: "",
  };

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(AlertReducers, initialState);

  const setSuccessAlert = async (payload) => {
    dispatch({
      type: "SET_SUCCESS_ALERT",
      payload,
    });
    setTimeout(() => {
      closeAlert()
    }, 5000)
  };

  const setErrorAlert = async (payload) => {
    dispatch({
      type: "SET_ERROR_ALERT",
      payload,
    });
    setTimeout(() => {
      closeAlert()
    }, 5000)
  };

  const closeAlert = async () => {
    dispatch({
      type: "CLOSE_ALERT",
    });
  };

  return (
    <AlertContext.Provider
      value={{
        toggleAlert: state.toggleAlert,
        type: state.type,
        message: state.message,
        setSuccessAlert,
        setErrorAlert,
        closeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
