import { createContext, useReducer, useContext } from "react";
import axios from "../../utils/axios";
import AuthReducers from "./AuthReducers";
import AlertContext from "../Alert/AlertContext";
import { encode, gatewayToken } from "../../utils/constants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const initialState = {
    loading: false,
    user: {},
    isUserAuthenticated: false,
  };

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(AuthReducers, initialState);

  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext);

  // User Login Endpoint
  const adminLogin = async (formData) => {
    dispatch({
      type: "SET_AUTH_LOADING",
    });

    await axios
      .post("/admin/signin", formData)
      .then((response) => {
        const data = response.data.data;

        const encodedToken = encode(data.token);

        window.localStorage.setItem(gatewayToken, encodedToken);

        setSuccessAlert("Sign in successful.");
        setTimeout(() => {
          window.location.href = "/dashboard/overview";
        }, 2000);
      })
      .catch((err) => {
        setErrorAlert(err?.response?.data?.message || "Error signing in!");
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // logout admin
  const adminLogout = () => {
    localStorage.removeItem(gatewayToken);
    setSuccessAlert("Logout successful.");
    setTimeout(() => {
      window.location.href = "/sign-in";
    }, 2000);
    return;
  };

  // Method to Set Loading to False
  const setLoadingToFalse = (payload) => {
    dispatch({ type: "CLEAR_LOADING", payload: payload ? payload : null });
  };

  return (
    <AuthContext.Provider
      value={{
        loading: state.loading,
        adminLogin,
        adminLogout,
        isUserAuthenticated: state.isUserAuthenticated,
        setLoadingToFalse,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
