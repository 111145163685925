//Constants/Mocks

export const emailRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;
export const cityZipCodeRegex = /^[^,\\]+[,\\\s]+(.+?)\s*(\d{5})?$/;
export const phoneRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://prod.gatewayapp.co";
export const gatewayToken = "g_^A%d&T";
export const gatewayCaptchaToken = process.env.REACT_APP_CAPTCHA_TOKEN;


export const encode = (jwt: string) => {
  if (typeof jwt === "string") {
    const [header, payload, signature] = jwt.split(".");

    const encodedHeader = btoa(header);
    const encodedPayload = btoa(payload);
    const encodedSig = btoa(signature);

    return btoa(`${encodedHeader}::${encodedPayload}::${encodedSig}`);
  }

  return;
};

export const decode = (text: string) => {
  if (typeof text === "string") {
    const decodedText = atob(text);
    const [header, payload, signature] = decodedText.split("::");

    const decodedHeader = atob(header);
    const decodedPayload = atob(payload);
    const decodedSig = atob(signature);
    return `${decodedHeader}.${decodedPayload}.${decodedSig}`;
  }

  return;
};