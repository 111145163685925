import React from "react";
import { useContext, useEffect } from "react";
import BlogsContext from "@/context/Blogs/BlogsContext";
import Spinner from "../Spinner";
import Blog from "../Blog";
import styles from "./blogs-wrapper.module.scss";

const BlogsWrapper = () => {
  const { loading, blogs, fetchAllBlogs } = useContext(BlogsContext);

  useEffect(() => {
    fetchAllBlogs();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles["blogs__wrapper"]}>
      {loading ? (
        <Spinner />
      ) : blogs.length > 0 ? (
        blogs?.map((blog) => <Blog key={blog?.id} blog={blog} />)
      ) : (
        <>No blog posts</>
      )}
    </div>
  );
};

export default BlogsWrapper;
