import styles from './about.module.scss';
import Input from '@/components/Input';
import Button from '@/components/Button';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { UploadImage, validationSchema } from '@/utils/helpers';
import { ICreateAdventure } from '@/utils/interface';
import { useContext, useState } from 'react';
import AdventureContext from '@/context/Adventure/AdventureContext';

interface IProps {
  createAdventure: ICreateAdventure;
  setCreateAdventure: any;
  handleActiveTab: any;
  adventureImage: any;
  setAdventureImage: any;
}

const AboutAdventure = ({
  createAdventure,
  setCreateAdventure,
  handleActiveTab,
  adventureImage,
  setAdventureImage,
}: IProps) => {
  const { loading, adventure, createNewAdventure, updateAdventure } =
    useContext(AdventureContext);

  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    expect: createAdventure.expectations,
    gain: createAdventure.gains,
    earn: createAdventure.earnings,
    reward: createAdventure.rewardPoint,
  };

  const { dynamic, dynamicNumber } = validationSchema;

  const handleSubmit = async (
    values: typeof initialState,
    { setSubmitting }: FormikHelpers<typeof initialState>
  ) => {
    setIsLoading(true);
    let image: any;
    if (adventureImage.imageChanged) {
      image = await UploadImage(adventureImage.image);
      if (image) {
        setAdventureImage({
          ...adventureImage,
          imageChanged: false,
        });
      }
    }
    const formData = {
      ...createAdventure,
      imageUrl: image ? image.url : createAdventure.imageUrl,
      expectations: values.expect,
      gains: values.gain,
      earnings: values.earn,
      rewardPoint: values.reward,
    };
    setCreateAdventure({
      ...createAdventure,
      imageUrl: image ? image.url : createAdventure.imageUrl,
      expectations: values.expect,
      gains: values.gain,
      earnings: values.earn,
      rewardPoint: values.reward,
    });

    if (createAdventure?.id) {
      await updateAdventure(createAdventure?.id, formData).then((res: any) => {
        handleActiveTab('modules');
      });
    } else {
      await createNewAdventure(formData).then((res: any) => {
        setCreateAdventure({
          ...createAdventure,
          id: res.id,
        });
        handleActiveTab('modules');
      });
    }
    setIsLoading(false);
  };

  return (
    <div className={styles['details']}>
      <div className={styles['details__container']}>
        <Formik
          initialValues={initialState}
          validationSchema={yup.object({
            expect: dynamic(),
            gain: dynamic(),
            earn: dynamic(),
            reward: dynamicNumber(),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className={styles['details__content']}>
              <div className={styles['details__content-container']}>
                <div className={styles['details__content-header']}>
                  <div className={styles['details__content-header-container']}>
                    <h3>About Adventure</h3>
                  </div>
                </div>

                <div>
                  <Form>
                    <div className={styles['details__form']}>
                      <div className={styles['details__form-input']}>
                        <div>
                          <label>What to Expect</label>
                          <Input
                            id='expect'
                            type='text'
                            name='expect'
                            label=''
                            placeholder='e.g This adventure will take you on the journey to becoming a better blockchain user.'
                          />
                        </div>
                        <div>
                          <label>What to Gain</label>
                          <Input
                            id='gain'
                            type='text'
                            name='gain'
                            label=''
                            placeholder='e.g This adventure will take you on the journey to becoming a better blockchain user.'
                          />
                        </div>

                        <div>
                          <label>What to Earn</label>
                          <Input
                            id='earn'
                            type='text'
                            name='earn'
                            label=''
                            placeholder='e.g This adventure will take you on the journey to becoming a better blockchain user..'
                          />
                        </div>

                        <div>
                          <label>Reward Point</label>
                          <Input
                            id='reward'
                            type='number'
                            name='reward'
                            label=''
                            placeholder='0'
                          />
                        </div>

                        {/* adding this field */}

                        {/* <div
                          className={styles["create__adventure__content__container"]}
                        >
                          <label>Badge Type</label>
                          <select
                            name="badgeId"
                            id=""
                            className={styles["level_select"]}
                            onChange={(e) => {
                              props.setFieldValue("badgeId", e.target.value);
                            }}
                          >
                            <option value="BEGINNER">BEGINNERing</option>
                            <option value="INTERMEDIATE">INTERMEkDIATE</option>
                            <option value="EXPERT">EXPERT</option>
                          </select>
                        </div>
                      </div> */}
                      </div>

                      <div className={styles['details__form-button']}>
                        <Button
                          type='submit'
                          label={
                            isLoading || loading ? 'Loading...' : 'Continue'
                          }
                          variant='primary'
                          disabled={isLoading || loading}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AboutAdventure;
