import { useContext } from "react";
import QuizAdventure from "./quiz";
import styles from "./adventure_module.module.scss";
import { ICreateAdventure, IModule } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete, MdQuiz, MdVideoLibrary } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import AlertContext from "@/context/Alert/AlertContext";
import Loader from "@/components/Loader";
import { openUploadWidget } from "@/utils/cloudinaryService";
import Swal from "sweetalert2";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

interface IProps {
  modules: IModule[];
  setModules: any;
  createAdventure: ICreateAdventure;
}

const ModuleAdventure = ({ createAdventure, modules, setModules }: IProps) => {
  const { setErrorAlert, setSuccessAlert } = useContext(AlertContext);

  const { loading, createModules } = useContext(AdventureContext);

  const handleShowUpload = async (moduleIndex: number, lessonIndex: number) => {
    let success = false;
    let myUploadWidget = openUploadWidget(
      {
        tags: ["adventure"],
        maxFiles: 1,
        multiple: false,
        resourceType: ["video"],
      },
      function (error: any, result: any) {
        if (!error && result.event === "success") {
          success = true;
          setSuccessAlert("Video uploaded successfully.");
          const filteredModule = modules.find(
            (item) => item.index === moduleIndex
          );
          const lesson = filteredModule?.lessons.find(
            (lesson) => lesson.index === lessonIndex
          )!;
          lesson.videoUrl = result.info.url;
          setModules([...modules]);
        } else if (result.event === "abort" && !result.info.id && !success) {
          setErrorAlert("Error uploading video..");
        } else if (
          result.event === "close" &&
          result.info.message &&
          !success
        ) {
          setErrorAlert("Upload cancelled..");
        }
      }
    );
    myUploadWidget.open();
  };

  const handleSubmit = async () => {
    for (let i = 0; i < modules.length; i++) {
      const element = modules[i];

      for (let i = 0; i < element.lessons.length; i++) {
        const lesson = element.lessons[i];
        if (lesson.name === "" || !lesson.name) {
          setErrorAlert(
            `Module ${element.index + 1}, lesson ${
              lesson.index + 1
            } does not have a name.`
          );
          return;
        } else if (lesson.text === "" && lesson.videoUrl === "") {
          setErrorAlert(
            `Module ${element.index + 1}, lesson ${
              lesson.index + 1
            } must have a video or text.`
          );
          return;
        }

        if (lesson?.quizzes && lesson?.quizzes?.length > 0) {
          for (let i = 0; i < lesson?.quizzes.length; i++) {
            const quiz = lesson?.quizzes[i];

            if (quiz.text === "" || !quiz.text) {
              setErrorAlert(
                `Module ${element.index + 1}, lesson ${
                  lesson.index + 1
                }, Quiz ${quiz.index + 1} does not have a question.`
              );
              return;
            } else if (quiz.options.length === 0) {
              setErrorAlert(
                `Module ${element.index + 1}, lesson ${
                  lesson.index + 1
                }, Quiz ${quiz.index + 1} does not have an option.`
              );
              return;
            } else if (quiz.options.length === 1) {
              setErrorAlert(
                `Module ${element.index + 1}, lesson ${
                  lesson.index + 1
                }, Quiz ${quiz.index + 1} must have more than one option.`
              );
              return;
            } else if (quiz.options.length > 1) {
              let isCorrectOption = false;
              let isTextEmpty = false;
              for (let i = 0; i < quiz.options.length; i++) {
                const option = quiz.options[i];
                if (option.isCorrect) {
                  isCorrectOption = true;
                }
                if (option.text === "" || option.text.length === 0) {
                  isTextEmpty = true;
                }
              }

              if (!isCorrectOption) {
                setErrorAlert(
                  `Module ${element.index + 1}, lesson ${
                    lesson.index + 1
                  }, Quiz ${
                    quiz.index + 1
                  } must have at least one option marked as an answer.`
                );
                return;
              }
              if (isTextEmpty) {
                setErrorAlert(`Quiz must not have an empty option.`);
                return;
              }
            }
          }
        }
      }

      if (element?.task?.isActive && element?.task?.link?.length! === 0) {
        setErrorAlert(
          `Module ${element.index + 1} task does not have a link URL.`
        );
        return;
      } else if (
        element?.task?.isActive &&
        element?.task?.text?.length! === 0
      ) {
        setErrorAlert(
          `Module ${element.index + 1} task does not have a description.`
        );
        return;
      }
    }

    const newModule = modules.map((item) => {
      let lessons: any = [];
      for (let i = 0; i < item.lessons.length; i++) {
        const element = item.lessons[i];

        // map quizzes
        const questions = element.quizzes?.map((item) => {
          return {
            text: item.text,
            options: item.options,
          };
        });

        let quiz: any = {};

        if (element.quizzes && element.quizzes?.length > 0) {
          quiz = {
            description: `${element.name} Quiz`,
            questions,
          };
        }

        lessons.push({
          name: element.name,
          text: element.text,
          videoUrl: element.videoUrl,
          quiz,
        });
      }

      let obj: any = {
        adventureId: createAdventure?.id,
        name: `Module ${item.index + 1}`,
        description: "",
        lessons,
      };

      if (
        item.task?.isActive &&
        item.task.link?.length! > 0 &&
        item.task.text?.length! > 0
      ) {
        obj.task = {
          linkUrl: item.task.link,
          description: item.task.text,
        };
      }

      return obj;
    });

    const formData = {
      modules: newModule,
    };
    await createModules(formData).then((res: any) => {
      if (res) {
        setTimeout(() => {
          window.location.href = "/dashboard/adventures";
        }, 5000);
      }
    });
  };

  const handleModuleAdd = () => {
    for (let i = 0; i < modules.length; i++) {
      const element = modules[i];
      element.show = false;
    }

    modules.push({
      index: modules.length,
      show: true,
      name: "",
      description: "",
      lessons: [
        {
          index: 0,
          show: true,
          name: "",
          text: "",
          videoUrl: "",
          quizzes: [],
        },
      ],
      task: {
        isActive: false,
        show: false,
        link: "",
        text: "",
      },
    });
    setModules([...modules]);
  };

  const handleModuleCollapse = (moduleIndex: number) => {
    for (let i = 0; i < modules.length; i++) {
      const element = modules[i];

      if (element.index !== moduleIndex) {
        element.show = false;
      } else {
        element.show = !element.show;
      }
    }
    setModules([...modules]);
  };

  const handleModuleDelete = (moduleIndex: number) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete this module?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, continue!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (modules.length === 1) {
          setErrorAlert("You must have at least one modules.");
          return;
        }
        const filteredModule = modules
          .filter((item) => item.index !== moduleIndex)
          .map((item, index) => {
            return {
              ...item,
              index,
            };
          });
        setModules(filteredModule);
      }
    });
  };

  const handleEditTask = (moduleIndex: number, type: string, e: any) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;
    const task = filteredModule.task!;
    if (type === "text") {
      task.text = e.target.value;
    } else if (type === "link") {
      task.link = e.target.value;
    }

    setModules([...modules]);
  };

  const handleTaskCollapse = (moduleIndex: number) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;
    const task = filteredModule.task!;
    task.show = !task.show;
    setModules([...modules]);
  };

  const handleTaskAdd = (moduleIndex: number) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;
    const task = filteredModule?.task!;
    task.isActive = true;

    setModules([...modules]);
  };

  const handleTaskDelete = (moduleIndex: number) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete this task?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, continue!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const filteredModule = modules.find(
          (item) => item.index === moduleIndex
        )!;
        const task = filteredModule?.task!;
        task.isActive = false;
        task.show = false;
        task.text = "";
        task.link = "";

        setModules([...modules]);
      }
    });
  };

  const handleLessonAdd = (moduleIndex: number) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;

    for (let i = 0; i < filteredModule.lessons.length; i++) {
      const element = filteredModule.lessons[i];
      element.show = false;
    }

    filteredModule.lessons.push({
      index: filteredModule.lessons.length,
      show: true,
      name: "",
      text: "",
      videoUrl: "",
      quizzes: [],
    });

    setModules([...modules]);
  };

  const handleLessonCollapse = (moduleIndex: number, lessonIndex: number) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;

    for (let i = 0; i < filteredModule.lessons.length; i++) {
      const element = filteredModule.lessons[i];

      if (element.index !== lessonIndex) {
        element.show = false;
      } else {
        element.show = !element.show;
      }
    }
    setModules([...modules]);
  };

  const handleLessonDelete = (moduleIndex: number, lessonIndex: number) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete this lesson?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, continue!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const filteredModule = modules.find(
          (item) => item.index === moduleIndex
        )!;

        if (filteredModule.lessons.length === 1) {
          setErrorAlert("You must have at least one lesson in a modules.");
          return;
        }

        const lessons = filteredModule.lessons
          .filter((item) => item.index !== lessonIndex)
          .map((item, index) => {
            return {
              ...item,
              index,
            };
          });
        filteredModule.lessons = lessons;

        setModules([...modules]);
      }
    });
  };

  const handleLessonTextChange = (
    moduleIndex: number,
    lessonIndex: number,
    type: string,
    e: any
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    )!;
    if (type === "body") {
      lesson.text = e.target.value;
    } else if (type === "name") {
      lesson.name = e.target.value;
    }
    setModules([...modules]);
  };

  const handleAddQuiz = (moduleIndex: number, lessonIndex: number) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;
    const lesson = filteredModule.lessons.find(
      (lesson) => lesson.index === lessonIndex
    )!;
    lesson.quizzes?.push({
      index: lesson.quizzes.length,
      show: false,
      text: "",
      options: [],
    });
    setModules([...modules]);
  };

  const handleDeleteQuiz = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number
  ) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete this quiz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, continue!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const filteredModule = modules.filter(
          (item) => item.index === moduleIndex
        );
        const lesson = filteredModule[0].lessons.filter(
          (lesson) => lesson.index === lessonIndex
        );
        const filteredQuiz = lesson[0].quizzes
          ?.filter((item) => item.index !== quizIndex)
          .map((item, index) => {
            return {
              ...item,
              index,
            };
          });
        lesson[0].quizzes = filteredQuiz;
        setModules([...modules]);
      }
    });
  };

  const handleQuizAddOption = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex);
    quiz?.options.push({
      text: "",
      isCorrect: false,
    });
    setModules([...modules]);
  };

  const handleQuizDeleteOption = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number,
    optionIndex: number
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex)!;
    const filteredOptions = quiz?.options.filter(
      (option, index) => index !== optionIndex
    )!;
    quiz.options = filteredOptions;
    setModules([...modules]);
  };

  const handleQuizCollapse = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex)!;
    quiz.show = !quiz?.show;

    setModules([...modules]);
  };

  const handleQuizOptionChange = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number,
    optionIndex: number,
    e: CheckboxChangeEvent
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex);
    const selectedOption = quiz?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.isCorrect = e.target.checked;
    setModules([...modules]);
  };

  const handleQuizOptionTextChange = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number,
    optionIndex: number,
    e: any
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex);
    const selectedOption = quiz?.options.find(
      (option, index) => index === optionIndex
    )!;
    selectedOption.text = e.target.value;
    setModules([...modules]);
  };

  const handleQuizTextChange = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number,
    e: any
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex)!;
    quiz.text = e.target.value;
    setModules([...modules]);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={styles["module"]}>
      <div className={styles["module__info_tab"]}>
        <BsInfoCircle /> &nbsp; Every module must have at least one lesson
      </div>
      <div className={styles["module__another_option"]}>
        <span onClick={() => handleModuleAdd()}>
          <AiFillPlusCircle fill="#1579E4" /> Add another module
        </span>
      </div>
      {modules.map((module) => (
        <>
          <div className={styles["module__module_tab"]}>
            <h1 className={styles["module__module_tab__title"]}>
              Module {module.index + 1}
            </h1>
            <div>
              <span onClick={() => handleModuleCollapse(module.index)}>
                {module.show ? (
                  <IoIosArrowDropup size="1.3rem" cursor="pointer" />
                ) : (
                  <IoIosArrowDropdown size="1.3rem" cursor="pointer" />
                )}
              </span>{" "}
              &nbsp;&nbsp;
              <MdDelete
                size="1.3rem"
                cursor="pointer"
                onClick={() => handleModuleDelete(module.index)}
              />
            </div>
          </div>
          {module.show && (
            <>
              <div className={styles["module__module_display"]}>
                {module.lessons.map((lesson) => (
                  <>
                    <div className={styles["module__module_tab"]}>
                      <h1 className={styles["module__module_tab__title"]}>
                        Lesson {lesson.index + 1}
                      </h1>
                      <div>
                        <span
                          onClick={() =>
                            handleLessonCollapse(module.index, lesson.index)
                          }
                        >
                          {lesson.show ? (
                            <IoIosArrowDropup size="1.3rem" cursor="pointer" />
                          ) : (
                            <IoIosArrowDropdown
                              size="1.3rem"
                              cursor="pointer"
                            />
                          )}
                        </span>{" "}
                        &nbsp;&nbsp;
                        <MdDelete
                          size="1.3rem"
                          cursor="pointer"
                          onClick={() =>
                            handleLessonDelete(module.index, lesson.index)
                          }
                        />
                      </div>
                    </div>
                    {lesson.show && (
                      <>
                        <div
                          className={
                            styles["module__module_display__input_container"]
                          }
                        >
                          <label htmlFor="name">Lesson Name</label>
                          <input
                            type="text"
                            placeholder="Lesson name"
                            className={styles["module__module_display_input"]}
                            defaultValue={lesson.name}
                            onChange={(e) =>
                              handleLessonTextChange(
                                module.index,
                                lesson.index,
                                "name",
                                e
                              )
                            }
                          />
                        </div>
                        <div
                          className={
                            styles["module__module_display__input_container"]
                          }
                        >
                          <label htmlFor="body">Body Text</label>
                          <textarea
                            className={
                              styles["module__module_display_text_area"]
                            }
                            rows={8}
                            name="text"
                            defaultValue={lesson.text}
                            onChange={(e) =>
                              handleLessonTextChange(
                                module.index,
                                lesson.index,
                                "body",
                                e
                              )
                            }
                          ></textarea>
                        </div>

                        <div
                          className={
                            styles["module__module_display__under_tab"]
                          }
                        >
                          <p
                            onClick={() =>
                              handleAddQuiz(module.index, lesson.index)
                            }
                          >
                            <MdQuiz /> Add Quiz
                          </p>
                          <p
                            onClick={() =>
                              handleShowUpload(module.index, lesson.index)
                            }
                          >
                            <MdVideoLibrary /> Add Video
                          </p>
                        </div>

                        {/* QUIZ */}
                        {lesson.quizzes?.map((quiz) => (
                          <QuizAdventure
                            quiz={quiz}
                            moduleIndex={module.index}
                            lessonIndex={lesson.index}
                            handleQuizAddOption={handleQuizAddOption}
                            handleQuizOptionChange={handleQuizOptionChange}
                            handleQuizOptionTextChange={
                              handleQuizOptionTextChange
                            }
                            handleQuizTextChange={handleQuizTextChange}
                            handleDeleteQuiz={handleDeleteQuiz}
                            handleQuizCollapse={handleQuizCollapse}
                            handleQuizDeleteOption={handleQuizDeleteOption}
                          />
                        ))}
                      </>
                    )}

                    {module.lessons.length === lesson.index + 1 ? (
                      <></>
                    ) : (
                      <hr className={styles["module__line"]} />
                    )}
                  </>
                ))}
                <br />

                <hr className={styles["module__line"]} />

                {/* TASK */}
                {module.task?.isActive && (
                  <div className={styles["module__module_tab"]}>
                    <h1 className={styles["module__module_tab__title"]}>
                      Task
                    </h1>
                    <div>
                      <span onClick={() => handleTaskCollapse(module.index)}>
                        {module?.task?.show ? (
                          <IoIosArrowDropup size="1.3rem" cursor="pointer" />
                        ) : (
                          <IoIosArrowDropdown size="1.3rem" cursor="pointer" />
                        )}
                      </span>{" "}
                      &nbsp;&nbsp;
                      <MdDelete
                        size="1.3rem"
                        cursor="pointer"
                        onClick={() => handleTaskDelete(module.index)}
                      />
                    </div>
                  </div>
                )}
                {/* TASK INPUTS*/}
                {module.task?.isActive && module.task?.show && (
                  <>
                    <div
                      className={
                        styles["module__module_display__input_container"]
                      }
                    >
                      <label htmlFor="name">Task description</label>
                      <input
                        type="text"
                        placeholder="Task description"
                        className={styles["module__module_display_input"]}
                        defaultValue={module.task?.text}
                        onChange={(e) =>
                          handleEditTask(module.index, "text", e)
                        }
                      />
                    </div>
                    <div
                      className={
                        styles["module__module_display__input_container"]
                      }
                    >
                      <label htmlFor="body">Task link</label>
                      <input
                        type="text"
                        placeholder="Task link URL"
                        className={styles["module__module_display_input"]}
                        defaultValue={module.task?.link}
                        onChange={(e) =>
                          handleEditTask(module.index, "link", e)
                        }
                      />
                    </div>
                  </>
                )}

                <br />
                <div className={styles["module__add__another"]}>
                  {!module.task?.isActive && (
                    <span onClick={() => handleTaskAdd(module.index)}>
                      <AiFillPlusCircle fill="#1579E4" /> Add task
                    </span>
                  )}
                  <span onClick={() => handleLessonAdd(module.index)}>
                    <AiFillPlusCircle fill="#1579E4" /> Add another lesson
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      ))}
      <br />
      <button
        onClick={() => handleSubmit()}
        className={styles["module__button"]}
      >
        Continue
      </button>
    </div>
  );
};

export default ModuleAdventure;
