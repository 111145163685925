import { useContext, useState, useEffect } from "react";
import styles from "./adventure_module.module.scss";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { UploadImage, validationSchema } from "@/utils/helpers";
import { ICreateAdventure, ILesson, IQuestion, ITask } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete, MdQuiz, MdVideoLibrary } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsArrowLeft, BsInfoCircle } from "react-icons/bs";
import QuizForLesson from "./QuizForLesson";
import AlertContext from "@/context/Alert/AlertContext";
import { openUploadWidget } from "@/utils/cloudinaryService";

interface IProps {
  moduleId: string;
  task: ITask;
  isChanged: boolean;
  setIsChanged: any;
  setEditType: any;
}

const Task = ({
  moduleId,
  task,
  isChanged,
  setIsChanged,
  setEditType,
}: IProps) => {
  const { addModuleTask, updateModuleTask } = useContext(AdventureContext);
  const { setErrorAlert } = useContext(AlertContext);

  const initialState = {
    index: 0,
    isActive: false,
    show: true,
    link: task.link,
    text: task.text,
  };
  const [formData, setFormData] = useState<ITask>(initialState);

  const handleEditTask = async (type: string, e: any) => {
    if (type === "text") {
      setFormData({
        ...formData,
        text: e.target.value,
      });
    } else if (type === "link") {
      setFormData({
        ...formData,
        link: e.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    if (formData.link === "" || !formData.link) {
      setErrorAlert(`Task does not have a link URL.`);
      return;
    } else if (formData.text === "" && !formData.text) {
      setErrorAlert(`Task must have a description.`);
      return;
    }

    let taskToSubmit = {
      description: formData.text,
      linkUrl: formData.link,
    };
    if (task.isActive) {
      await updateModuleTask(task.id, taskToSubmit).then((res: any) => {
        setIsChanged(!isChanged);
      });
    } else {
      const data = {
        ...taskToSubmit,
        moduleId,
      };
      await addModuleTask(data).then((res: any) => {
        setIsChanged(!isChanged);
      });
    }
  };

  return (
    <>
      <h5
        className={styles["module__back"]}
        onClick={() => setEditType("normal")}
      >
        <BsArrowLeft /> Back
      </h5>
      <br />
      <h3>{task?.isActive ? "Edit Task" : "Add Task"}</h3>
      <div className={styles["module__module_display"]}>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="taskDescription"> Task description</label>
          <input
            type="text"
            placeholder="Task description"
            className={styles["module__module_display_input"]}
            defaultValue={formData?.text}
            onChange={(e) => handleEditTask("text", e)}
          />
        </div>
        <div className={styles["module__module_display__input_container"]}>
          <label htmlFor="taskLink">Task link</label>
          <input
            type="text"
            placeholder="Task link URL"
            className={styles["module__module_display_input"]}
            defaultValue={formData?.link}
            onChange={(e) => handleEditTask("link", e)}
          />
        </div>

        <button onClick={handleSubmit} className={styles["module__button"]}>
          Save task
        </button>
      </div>
    </>
  );
};

export default Task;
