import { createContext, useReducer, useContext } from "react";
import axios from "../../utils/axios";
import BadgeReducers from "./BadgeReducers";
import AlertContext from "../Alert/AlertContext";
import { toast } from "react-toastify";

const BadgeContext = createContext();

export const BadgeProvider = ({ children }) => {
  const initialState = {
    loading: false,
    allBadges: [],
  };

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(BadgeReducers, initialState);

  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext);

  // Fetch All Adventures
  const fetchAllBadges = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .get(`/admin/badge`)
      .then((res) => {
        const data = res.data.data.result;
        dispatch({
          type: "SET_BADGES",
          payload: data,
        });
        return data;
      })
      .finally(() => {
        // setLoadingToFalse();
      });
  };


  return (
    <BadgeContext.Provider
      value={{
        loading: state.loading,
        allBadges: state.allBadges,
        fetchAllBadges
      }}
    >
      {children}
    </BadgeContext.Provider>
  );
};

export default BadgeContext;
