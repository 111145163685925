import React from 'react';
import styles from './product.module.scss';

const OwnerInfo = ({ owner, countries }) => {

  return (
    <div className={styles['owner__info']}>
      <div className={styles['by']}>
        <h5>Composed by:</h5>
        <p>{owner?.fullName}</p>
      </div>

      <div className={styles['countries']}>
        <h5>countries available</h5>

        <div className={styles['owner_countries']}>

        {countries && countries.length > 0 ? (
          countries.map((country, index) => (
            <h4 key={country.name}>
              {country.name}
              {index < countries.length - 1 ? ',' : '.'}
            </h4>
          ))
        ) : null}

        </div>
        
      </div>
    </div>
  );
};

export default OwnerInfo
