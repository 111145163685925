import { useState, useEffect, useContext } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import PointAllocationModal from "@/components/PointAllocationModal";
import ModalComponent from "@/components/ModalComponent";
import styles from "./settings.module.scss";
import CreateWalletModal from "@/components/CreateWalletModal";
import SettingsWrapper from "@/components/SettingsWrapper";

const Settings = () => {
  const [showPointModal, setShowPointModal] = useState(false);
  const [showCreatePointModal, setShowCreatePointModal] = useState(false);

  const { getExchangeRates, getConfigs } = useContext(AdminContext);

  useEffect(() => {
    getExchangeRates();
    getConfigs();
  }, []);

  const handleCloseModal = () => {
    setShowPointModal(false);
  };

  const handleCreatePointCloseModal = () => {
    setShowCreatePointModal(false);
  };

  return (
    <div className={styles["settings"]}>
      <h2>Settings</h2>
      <p>Manage your settings</p>
      <div className={styles["settings__card_container"]}>
        <div
          onClick={() => setShowPointModal(true)}
          className={styles["settings__card"]}
        >
          <h2>Point</h2>
          <h5>Click to change Point value</h5>
        </div>

        <div
          onClick={() => setShowCreatePointModal(true)}
          className={styles["settings__card"]}
        >
          <h2>Create Wallet</h2>
          <h5>Click to create wallet-point value</h5>
        </div>
      </div>

      <div>
        <SettingsWrapper />
      </div>

      {showPointModal ? (
        <ModalComponent show={showPointModal} closeModal={handleCloseModal}>
          <PointAllocationModal />
        </ModalComponent>
      ) : null}

      {showCreatePointModal ? (
        <ModalComponent
          show={showCreatePointModal}
          closeModal={handleCreatePointCloseModal}
        >
          <CreateWalletModal />
        </ModalComponent>
      ) : null}
    </div>
  );
};

export default Settings;
