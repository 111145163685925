import { useState, useContext, useEffect } from "react";
import AdventuresDetails from "@/components/CreateAdventure/AdventureDetails";
import AboutAdventure from "@/components/CreateAdventure/AboutAdeventure";


import styles from "./create.module.scss";
import {
  AdventureDifficultyLevel,
  ICreateAdventure,
  IModule,
} from "@/utils/interface";
import ModuleAdventure from "@/components/CreateAdventure/ModuleAdventure";
import AdventureContext from "@/context/Adventure/AdventureContext";
import BadgeContext from "@/context/Badge/BadgeContext";

const initialModule: IModule[] = [
  {
    index: 0,
    show: true,
    name: "",
    description: "",
    lessons: [
      {
        index: 0,
        show: true,
        name: "",
        text: "",
        videoUrl: "",
        quizzes: [],
      },
    ],
    task: {
      isActive: false,
      show: false,
      link: "",
      text: "",
    },
  },
];

const Create = () => {
  const { adventure } = useContext(AdventureContext);
  const { allBadges, fetchAllBadges } = useContext(BadgeContext);

  useEffect(() => {
    fetchAllBadges()
  }, [])
  

  const [activeTab, setActiveTab] = useState<"details" | "about" | "modules">(
    "details"
  );

  const [adventureImage, setAdventureImage] = useState({
    imageUrl: "",
    image: {},
    imageChanged: false,
  });

  const [createAdventure, setCreateAdventure] = useState<ICreateAdventure>({
    id: null,
    name: "",
    imageUrl: "",
    description: "",
    difficultyLevel: AdventureDifficultyLevel.BEGINNER,
    gains: "",
    earnings: "",
    expectations: "",
    rewardPoint: 0,
    badgeId: ""
  });

  const [modules, setModules] = useState<IModule[]>(initialModule);

  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const handleActiveTab = (tab: "details" | "about" | "modules") => {
    setActiveTab(tab);
  };

  return (
    <div className={styles["create"]}>
      <h2 className={styles["create__header"]}>Create adventure</h2>

      <div className={styles["create__content"]}>
        <div className={styles["create__tabs"]}>
          <span
            onClick={() => handleActiveTab("details")}
            data-active={activeTab === "details"}
          >
            Adventure Details
          </span>
          <span
            onClick={() => {
              isAboutOpen && handleActiveTab("about");
            }}
            data-active={activeTab === "about"}
          >
            About Adventure
          </span>
          <span
            onClick={() => adventure?.id && handleActiveTab("modules")}
            data-active={activeTab === "modules"}
          >
            Modules
          </span>
        </div>

        <div className={styles["create__form"]}>
          {activeTab === "details" && (
            <AdventuresDetails
              createAdventure={createAdventure}
              setCreateAdventure={setCreateAdventure}
              handleActiveTab={handleActiveTab}
              setIsAboutOpen={setIsAboutOpen}
              adventureImage={adventureImage}
              setAdventureImage={setAdventureImage}
            />
          )}
          {activeTab === "about" && (
            <AboutAdventure
              createAdventure={createAdventure}
              setCreateAdventure={setCreateAdventure}
              handleActiveTab={handleActiveTab}
              adventureImage={adventureImage}
              setAdventureImage={setAdventureImage}
            />
          )}
          {activeTab === "modules" && (
            <ModuleAdventure
              createAdventure={createAdventure}
              modules={modules}
              setModules={setModules}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;
