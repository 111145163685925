import { useContext, useEffect } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";

const CurrentUser = () => {
  const { currentUser, getUserWallet, wallets } = useContext(AdminContext);

  useEffect(() => {
    getUserWallet();
  }, []);

  return (
    <div>
      <h2>Welcome, {currentUser?.fullName}</h2>
      <p>
        This is the dashboard overview section. Gain insights into the
        performance of your project on the go{" "}
      </p>
      <br />
      {wallets.length > 0 && (
        <div>
          <h2>Wallet Info</h2>
          {wallets.map((wallet) => (
            <div>
              <br />
              <h3>Wallet: {wallet?.name}</h3>
              <p>Wallet address: {wallet?.address}</p>
              <br />
            </div>
          ))}
          <br />
        </div>
      )}
    </div>
  );
};

export default CurrentUser;
