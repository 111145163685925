import { useContext, useState } from "react";
import QuizAdventure from "./quiz";
import styles from "./adventure_module.module.scss";
import { validationSchema } from "@/utils/helpers";
import { ILesson, IModule, IQuestion, ITask } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete, MdModeEditOutline, MdQuiz } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import AlertContext from "@/context/Alert/AlertContext";
import Loader from "@/components/Loader";
import Module from "./module";
import EditQuiz from "./EditQuiz";
import EditLesson from "./EditLesson";
import AddLesson from "./AddLesson";
import Swal from "sweetalert2";
import Task from "./Task";

interface IProps {
  modules: IModule[];
  setModules: any;
  isChanged: boolean;
  setIsChanged: any;
  adventureId: string;
}

const initialQuiz = {
  index: 0,
  show: true,
  text: "",
  options: [],
};

const initialLesson = {
  index: 0,
  show: true,
  name: "",
  text: "",
  videoUrl: "",
};

const initialTask = {
  index: 0,
  show: true,
  isActive: false,
  text: "",
  link: "",
};

const ModuleAdventure = ({
  modules,
  setModules,
  adventureId,
  isChanged,
  setIsChanged,
}: IProps) => {
  const { loading, deleteModule, deleteLesson, deleteQuizQuestion } =
    useContext(AdventureContext);
  const { setErrorAlert } = useContext(AlertContext);

  const [editType, setEditType] = useState<
    "normal" | "quiz" | "add_lesson" | "edit_lesson" | "module" | "task"
  >("normal");

  // QUIZ INFO
  const [currentQuizQuestion, setCurrentQuizQuestion] =
    useState<IQuestion>(initialQuiz);
  const [quizQuestionType, setQuizQuestionType] = useState<"edit" | "add" | "">(
    ""
  );

  const [currentLesson, setCurrentLesson] = useState<ILesson>(initialLesson);

  const [task, setTask] = useState<ITask>(initialTask);

  const [currentModuleId, setCurrentModuleId] = useState<string>("");

  const { dynamic, dynamicNumber } = validationSchema;

  const handleSubmit = () => {
    window.location.href = "/dashboard/adventures";
  };

  const handleModuleAdd = () => {
    setEditType("module");
  };

  const handleModuleCollapse = (moduleIndex: number) => {
    for (let i = 0; i < modules.length; i++) {
      const element = modules[i];

      if (element.index !== moduleIndex) {
        element.show = false;
      } else {
        element.show = !element.show;
      }
    }
    setModules([...modules]);
  };

  const handleModuleDelete = async (moduleId: string, moduleIndex: number) => {
    if (modules.length === 1) {
      setErrorAlert("You must have at least one modules.");
      return;
    }
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteModule(moduleId).then((res: any) => {
          if (res) {
            setIsChanged(!isChanged);
          }
        });
      }
    });
  };

  const handleLessonAdd = (moduleId: string) => {
    setCurrentModuleId(moduleId);
    setEditType("add_lesson");
  };

  const handleTaskAdd = (moduleId: string) => {
    const filteredModule = modules.find((item) => item.id === moduleId)!;
    setTask(filteredModule.task!);
    setCurrentModuleId(filteredModule.id!);
    setEditType("task");
  };

  const handleTaskCollapse = (moduleIndex: number) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;
    const task = filteredModule.task!;
    task.show = !task.show;
    setModules([...modules]);
  };

  const handleLessonCollapse = (moduleIndex: number, lessonIndex: number) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;

    for (let i = 0; i < filteredModule.lessons.length; i++) {
      const element = filteredModule.lessons[i];

      if (element.index !== lessonIndex) {
        element.show = false;
      } else {
        element.show = !element.show;
      }
    }
    setModules([...modules]);
  };

  const handleLessonDelete = (moduleIndex: number, lessonId: string) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex)!;

    if (filteredModule.lessons.length === 1) {
      setErrorAlert("You must have at least one lesson in a modules.");
      return;
    }
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteLesson(lessonId).then((res: any) => {
          if (res) {
            setIsChanged(!isChanged);
          }
        });
      }
    });
  };

  const handleDeleteQuiz = (quizId: string) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteQuizQuestion(quizId).then((res: any) => {
          if (res) {
            setIsChanged(!isChanged);
          }
        });
      }
    });
  };

  const handleQuizCollapse = (
    moduleIndex: number,
    lessonIndex: number,
    quizIndex: number
  ) => {
    const filteredModule = modules.find((item) => item.index === moduleIndex);
    const lesson = filteredModule?.lessons.find(
      (lesson) => lesson.index === lessonIndex
    );
    const quiz = lesson?.quizzes?.find((item) => item.index === quizIndex)!;
    quiz.show = !quiz?.show;

    setModules([...modules]);
  };

  return loading ? (
    <Loader />
  ) : editType === "normal" ? (
    <div className={styles["module"]}>
      <div className={styles["module__info_tab"]}>
        <BsInfoCircle /> &nbsp; Every module must have at least one lesson
      </div>
      <div className={styles["module__another_option"]}>
        <span onClick={() => handleModuleAdd()}>
          <AiFillPlusCircle fill="#1579E4" /> Add another module
        </span>
      </div>
      {modules.map((module) => (
        <>
          <div className={styles["module__module_tab"]}>
            <h1 className={styles["module__module_tab__title"]}>
              Module {module.index + 1}
            </h1>
            <div>
              <span onClick={() => handleModuleCollapse(module.index)}>
                {module.show ? (
                  <IoIosArrowDropup size="1.3rem" cursor="pointer" />
                ) : (
                  <IoIosArrowDropdown size="1.3rem" cursor="pointer" />
                )}
              </span>{" "}
              &nbsp;&nbsp;
              <MdDelete
                size="1.3rem"
                cursor="pointer"
                onClick={() => handleModuleDelete(module.id!, module.index)}
              />
            </div>
          </div>
          {module.show && (
            <>
              <div className={styles["module__module_display"]}>
                {module.lessons.map((lesson) => (
                  <>
                    <div className={styles["module__module_tab"]}>
                      <h1 className={styles["module__module_tab__title"]}>
                        Lesson {lesson.index + 1}
                      </h1>
                      <div>
                        <span
                          onClick={() =>
                            handleLessonCollapse(module.index, lesson.index)
                          }
                        >
                          {lesson.show ? (
                            <IoIosArrowDropup size="1.3rem" cursor="pointer" />
                          ) : (
                            <IoIosArrowDropdown
                              size="1.3rem"
                              cursor="pointer"
                            />
                          )}
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <MdModeEditOutline
                          size="1.3rem"
                          cursor="pointer"
                          onClick={() => {
                            setCurrentLesson(lesson);
                            setEditType("edit_lesson");
                          }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <MdDelete
                          size="1.3rem"
                          cursor="pointer"
                          onClick={() =>
                            handleLessonDelete(module.index, lesson.id!)
                          }
                        />
                      </div>
                    </div>
                    {lesson.show && (
                      <>
                        <div
                          className={
                            styles["module__module_display__input_container"]
                          }
                        >
                          <label htmlFor="name">Lesson Name</label>
                          <input
                            type="text"
                            placeholder="Lesson name"
                            className={styles["module__module_display_input"]}
                            defaultValue={lesson.name}
                            disabled={true}
                          />
                        </div>
                        <div
                          className={
                            styles["module__module_display__input_container"]
                          }
                        >
                          <label htmlFor="body">Body Text</label>
                          <textarea
                            className={
                              styles["module__module_display_text_area"]
                            }
                            rows={8}
                            name="text"
                            defaultValue={lesson.text}
                            disabled={true}
                          ></textarea>
                        </div>

                        <div
                          className={
                            styles["module__module_display__under_tab"]
                          }
                        >
                          <p
                            onClick={() => {
                              setCurrentLesson(lesson);
                              setQuizQuestionType("add");
                              setEditType("quiz");
                            }}
                          >
                            <MdQuiz /> Add Quiz
                          </p>
                        </div>

                        {/* QUIZ */}
                        {lesson.quizzes?.map((quizQuestion) => (
                          <QuizAdventure
                            quizQuestion={quizQuestion}
                            setCurrentQuizQuestion={setCurrentQuizQuestion}
                            setEditType={setEditType}
                            setQuizQuestionType={setQuizQuestionType}
                            moduleIndex={module.index}
                            lessonIndex={lesson.index}
                            handleDeleteQuiz={handleDeleteQuiz}
                            handleQuizCollapse={handleQuizCollapse}
                          />
                        ))}
                      </>
                    )}

                    {module.lessons.length === lesson.index + 1 ? (
                      <></>
                    ) : (
                      <hr className={styles["module__line"]} />
                    )}
                  </>
                ))}
                <br />

                <hr className={styles["module__line"]} />

                {/* TASK */}
                {module.task?.isActive && (
                  <div className={styles["module__module_tab"]}>
                    <h1 className={styles["module__module_tab__title"]}>
                      Task
                    </h1>
                    <div>
                      <span onClick={() => handleTaskCollapse(module.index)}>
                        {module?.task?.show ? (
                          <IoIosArrowDropup size="1.3rem" cursor="pointer" />
                        ) : (
                          <IoIosArrowDropdown size="1.3rem" cursor="pointer" />
                        )}
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <MdModeEditOutline
                        size="1.3rem"
                        cursor="pointer"
                        onClick={() => {
                          handleTaskAdd(module.id!);
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* TASK INPUTS*/}
                {module.task?.isActive && module.task?.show && (
                  <>
                    <div
                      className={
                        styles["module__module_display__input_container"]
                      }
                    >
                      <label htmlFor="name">Task description</label>
                      <input
                        type="text"
                        placeholder="Task description"
                        className={styles["module__module_display_input"]}
                        defaultValue={module.task?.text}
                        disabled={true}
                      />
                    </div>
                    <div
                      className={
                        styles["module__module_display__input_container"]
                      }
                    >
                      <label htmlFor="body">Task link</label>
                      <input
                        type="text"
                        placeholder="Task link URL"
                        className={styles["module__module_display_input"]}
                        defaultValue={module.task?.link}
                        disabled={true}
                      />
                    </div>
                  </>
                )}

                <br />

                <div className={styles["module__add__another"]}>
                  {!module.task?.isActive && (
                    <span onClick={() => handleTaskAdd(module.id!)}>
                      <AiFillPlusCircle fill="#1579E4" /> Add task
                    </span>
                  )}
                  <span onClick={() => handleLessonAdd(module.id!)}>
                    <AiFillPlusCircle fill="#1579E4" /> Add another lesson
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      ))}
      <br />
      <button
        onClick={() => handleSubmit()}
        className={styles["module__button"]}
      >
        Continue
      </button>
    </div>
  ) : editType === "module" ? (
    <Module
      adventureId={adventureId}
      isChanged={isChanged}
      setIsChanged={setIsChanged}
      setEditType={setEditType}
    />
  ) : editType === "quiz" ? (
    <EditQuiz
      quizQuestionType={quizQuestionType}
      currentQuizQuestion={currentQuizQuestion}
      currentLesson={currentLesson}
      isChanged={isChanged}
      setIsChanged={setIsChanged}
      setEditType={setEditType}
    />
  ) : editType === "edit_lesson" ? (
    <EditLesson
      isChanged={isChanged}
      setIsChanged={setIsChanged}
      currentLesson={currentLesson}
      setEditType={setEditType}
    />
  ) : editType === "add_lesson" ? (
    <AddLesson
      isChanged={isChanged}
      setIsChanged={setIsChanged}
      moduleId={currentModuleId}
      setEditType={setEditType}
    />
  ) : editType === "task" ? (
    <Task
      task={task}
      isChanged={isChanged}
      setIsChanged={setIsChanged}
      moduleId={currentModuleId}
      setEditType={setEditType}
    />
  ) : null;
};

export default ModuleAdventure;
