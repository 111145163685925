import { useCallback } from "react";
import styles from "./search.module.scss";
import debounce from "lodash.debounce";

const Search = ({ handleChange, label }) => {
  const handleSearchTerm = (e) => {
    handleChange(e.target.value);
  };

  const debouncedProductSearch = useCallback(
    debounce(handleSearchTerm, 800),
    []
  );

  return (
    <form className={styles["search"]}>
      <input
        type="text"
        placeholder={`Search for a ${label}`}
        onChange={(e) => debouncedProductSearch(e)}
      />
    </form>
  );
};

export default Search;
