import { useContext, useState } from "react";
import styles from "./create_quiz.module.scss";
import { IQuestion } from "@/utils/interface";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { Checkbox } from "antd";

interface IProps {
  question: IQuestion;
  questionIndex: number;
  handleDeleteQuestion: any;
  handleQuestionCollapse: any;
  handleQuestionTextChange: any;
  handleQuestionOptionTextChange: any;
  handleQuestionDeleteOption: any;
  handleQuestionAddOption: any;
  handleQuestionOptionChange: any;
}

const QuizQuestion = ({
  question,
  questionIndex,
  handleDeleteQuestion,
  handleQuestionCollapse,
  handleQuestionTextChange,
  handleQuestionOptionTextChange,
  handleQuestionDeleteOption,
  handleQuestionAddOption,
  handleQuestionOptionChange,
}: IProps) => {
  return (
    <>
      <div className={styles["module__module_tab"]}>
        <h1 className={styles["module__module_tab__title"]}>
          Question {question.index + 1}
        </h1>
        <div>
          {question.show ? (
            <IoIosArrowDropup
              size="1.3rem"
              cursor="pointer"
              onClick={() => handleQuestionCollapse(question.index)}
            />
          ) : (
            <IoIosArrowDropdown
              size="1.3rem"
              cursor="pointer"
              onClick={() => handleQuestionCollapse(question.index)}
            />
          )}
          &nbsp;&nbsp;&nbsp;
          <MdDelete
            size="1.3rem"
            cursor="pointer"
            fill="red"
            onClick={() => handleDeleteQuestion(question.index)}
          />
        </div>
      </div>

      {/* question */}
      {question.show && (
        <div className={styles["module__module_display"]}>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Question </label>
            <input
              type="text"
              placeholder="What is your question?"
              className={styles["module__module_display_input"]}
              defaultValue={question.text}
              onChange={(e) => handleQuestionTextChange(question.index, e)}
            />
          </div>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Setup options for users to select from</label>
            {question.options.map((option, optionIndex) => (
              <>
                <input
                  type="text"
                  placeholder="Option 1"
                  className={styles["module__module_display_input"]}
                  defaultValue={option.text}
                  onChange={(e) =>
                    handleQuestionOptionTextChange(
                      question.index,
                      optionIndex,
                      e
                    )
                  }
                />
                <div className={styles["module__remove_option"]}>
                  <span
                    onClick={() =>
                      handleQuestionDeleteOption(question.index, optionIndex)
                    }
                  >
                    Remove option
                  </span>
                </div>
              </>
            ))}
          </div>
          <div className={styles["module__another_option"]}>
            <span onClick={() => handleQuestionAddOption(question.index)}>
              <AiFillPlusCircle fill="#1579E4" /> Add option
            </span>
          </div>
          <div>
            {question.options.length > 0 ? (
              <>
                <label htmlFor="name">Choose answer(s)</label>
                <div className={styles["quiz__option"]}>
                  {question.options.map((item, index) => (
                    <Checkbox
                      onChange={(e) =>
                        handleQuestionOptionChange(question.index, index, e)
                      }
                      checked={item.isCorrect}
                    >
                      {item.text}
                    </Checkbox>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default QuizQuestion;
