const AlertReducers = (state, action) => {
  switch (action.type) {
    case "SET_SUCCESS_ALERT":
      return {
        ...state,
        toggleAlert: true,
        message: action.payload,
        type: "success",
      };

    case "SET_ERROR_ALERT":
      return {
        ...state,
        toggleAlert: true,
        message: action.payload,
        type: "error",
      };

    case "CLOSE_ALERT":
      return {
        ...state,
        toggleAlert: false,
      };
    default:
      return state;
  }
};

export default AlertReducers;
