import React from "react";
import styles from "./point_allocation-modal.module.scss";
import { useContext, useState, useEffect } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import AlertContext from "@/context/Alert/AlertContext";

const PointAllocationModal = () => {
  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);

  const { getExchangeRates, exchangeRates, setExchangeRates } =
    useContext(AdminContext);

  const [formData, setFormData] = useState({
    token: "",
    value: "",
    network: "",
  });

  useEffect(() => {
    getExchangeRates();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      value: exchangeRates[0]?.value,
      network: exchangeRates[0]?.network,
      token: exchangeRates[0]?.token,
    });
  }, [exchangeRates]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!formData?.value || formData.value.length === 0) {
      setErrorAlert("Please input a value");
      setIsLoading(false);
      return;
    } else if (!formData?.token) {
      setErrorAlert("Please select an address");
      setIsLoading(false);
      return;
    }
    setExchangeRates(formData).then((res) => {
      getExchangeRates().then((res) => {
        setSuccessAlert("Point updated successfully");
        setIsLoading(false);
      });
    });
  };

  // handle change for the input field, where user input the amount to redeem
  const handleChange = (e) => {
    setFormData({
      ...formData,
      value: e.target.value,
    });
  };

  // handle change for when user select new network/token
  const handleNetworkChange = (e) => {
    const theSelectedNetwork = exchangeRates.find(
      (item) => item.network.toLowerCase() === e.target.value.toLowerCase()
    );
    setFormData({
      ...formData,
      token: theSelectedNetwork?.token,
      value: theSelectedNetwork?.value,
      network: theSelectedNetwork?.network,
    });
  };

  return (
    <div className={styles["prompt"]}>
      <div className={styles["prompt__content"]}>
        <div className={styles["prompt__content__detaills"]}>
          <h4>Change Points</h4>

          <div className={styles["prompt__content__detaills"]}>
            <select id="network" onChange={handleNetworkChange}>
              {exchangeRates?.map((rate) => (
                <option value={rate.network}>
                  {rate.network.toUpperCase()}
                </option>
              ))}
            </select>
          </div>

          <div className={styles["prompt__content__detaills--input"]}>
            <div
              className={styles["prompt__content__detaills--input--details"]}
            >
              <input
                type="number"
                id={formData.value}
                name="value"
                defaultValue={formData?.value}
                onChange={handleChange}
                min={0}
              />
            </div>
            <div>
              <h3
                className={styles["prompt__content__detaills--input__network"]}
              >
                {formData?.network?.toUpperCase()}
              </h3>
            </div>
          </div>

          <div className={styles["prompt__content__detaills--conversions"]}>
            <div>
              <p>Redeem conversion rate:</p>
              <p>
                1 Point = {formData?.value} {formData?.network}
              </p>
            </div>
          </div>
        </div>
        <div>
          <button
            className={styles["prompt__content__detaills--success"]}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PointAllocationModal;
