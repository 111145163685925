const AdventureReducers = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "CLEAR_LOADING":
      return {
        ...state,
        loading: false,
      };

    case "SET_ADVENTURE":
      return {
        ...state,
        adventure: action.payload,
      };

    case "SET_ADVENTURES":
      return {
        ...state,
        allAdventures: action.payload,
      };

    case "SET_TASKS":
      return {
        ...state,
        tasks: action.payload,
      };

    case "SET_QUIZZES":
      return {
        ...state,
        quizzes: action.payload,
      };

    case "SET_SUBMISSIONS":
      return {
        ...state,
        submissions: action.payload,
      };

    case "SET_QUIZ":
      return {
        ...state,
        quiz: action.payload,
      };

    case "SET_DAILY_QUIZ":
      return {
        ...state,
        dailyQuiz: action.payload,
      };

    case "SET_PAGINATION":
      return {
        ...state,
        pagination: action.payload,
      };

    default:
      return state;
  }
};

export default AdventureReducers;
