import { useContext } from "react";
import styles from "./task-modal.module.scss";
import { MdCancel } from "react-icons/md";
import user from "../../assets/images/user1.svg";
import AdventureContext from "@/context/Adventure/AdventureContext";
import AlertContext from "@/context/Alert/AlertContext";

const TaskSubmittedModal = ({ task, handleClick }) => {
  const { verifyTask } = useContext(AdventureContext);
  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext);

  const verifyUserTasks = async () => {
    await verifyTask({
      taskId: task.id,
      userId: task?.user?.id,
    }).then((res) => {
      if (res) {
        setSuccessAlert("Task verified successfully");
      } else {
        setErrorAlert("Error verifying task. Try again");
      }
    });
    handleClick();
  };

  return (
    <div className={styles["prompt"]}>
      <div className={styles["prompt__content"]}>
        <span onClick={() => handleClick()}>
          <MdCancel size="1.4rem" fill="#333" />
        </span>
        <div className={styles["prompt__content__detaills"]}>
          <h4>User submitted Task</h4>
          <div className={styles["prompt__content__detaills--user"]}>
            <div>
              {task?.user?.avatar === "" ? (
                <img
                  src={user}
                  alt="user"
                  className={styles["prompt__content__detaills--user--img"]}
                />
              ) : (
                <img
                  src={task?.user?.avatar}
                  alt="user"
                  className={styles["prompt__content__detaills--user--img"]}
                />
              )}
            </div>
            <div>
              <h5>{task?.user?.fullName}</h5>
              <h6>{task?.user?.email}</h6>
            </div>
          </div>

          <div className={styles["prompt__content__detaills--input"]}>
            <p>Task description: </p>
            <h4>{task?.task?.description}</h4>
            <p>Task Link URL: </p>
            <h4>{task?.task?.linkUrl}</h4>
            <p>Response: </p>
            <h4>{task?.response}</h4>
          </div>
          <div>
            <p>Task Status: {task.isVerified ? "Verified" : "Not verified"}</p>
          </div>
          {!task.isVerified && (
            <div
              onClick={verifyUserTasks}
              type="submit"
              className={styles["prompt__content__detaills--success"]}
            >
              Verify Task
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskSubmittedModal;
