import React, { useContext, useEffect, useState } from 'react'
// import AddProductComment from '../ProductPosts/AddProductComment'
// import ProductCommentReplies from '../ProductPosts/ProductCommentReplies'
import ProductContext from '@/context/Product/ProductContext'
import AddProductComment from './AddProductComment'
import ProductCommentReplies from './ProductCommentReplies'

const ShowComment = ({comment, productHuntId}) => {
    const [allReply, setAllReply] = useState()
    const {  getCommentsOnProductHuntComment } = useContext(ProductContext)

    useEffect(() => {
        const ReplyFxn = async () => {
            const rply = await getCommentsOnProductHuntComment(comment?.id)
            setAllReply(rply)
          }
        ReplyFxn()

    }, [comment?.id])



    const handleCommentAdded = async () => {
        const replies = await getCommentsOnProductHuntComment(comment?.id);
        setAllReply(replies)
    }
    
   

    return (
        <>
            <AddProductComment 
                productHuntId={productHuntId} 
                parentId={comment?.id} 
                onCommentAdded={handleCommentAdded}
            />

            {allReply &&
                allReply?.result &&
                allReply?.result?.map((reply) => <ProductCommentReplies reply={reply} />)}
        </>
    )
}

export default ShowComment