import React from "react";
import styles from "./blog.module.scss";
import moment from "moment";
import { AiOutlineRead } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Blog = ({ blog }) => {
  const navigate = useNavigate();

  const {
    id,
    title,
    user: { fullName },
    description,
    likes,
    commentCount,
    slug,
  } = blog;
  return (
    <div className={styles["blog"]}>
      <div
        className={styles["blog__header"]}
        style={{
          background: `linear-gradient(
        80.64deg,
        rgba(0, 0, 0, 0.7) 7.59%,
        #000000 200.48%
      ),
      url(${blog?.thumbnailUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1> {title}</h1>
      </div>
      <div className={styles["blog__body"]}>
        <small>
          Created At: {moment(blog?.createdAt).format("Do MMMM, YYYY")}
        </small>
        <span className={styles["blog__body__counts"]}>
          <span className={styles["blog__body__counts--comments"]}>
            Comments: {commentCount}
          </span>
          <span className={styles["blog__body__counts--likes"]}>
            Likes: {likes}
          </span>
        </span>
        <h4>Created By: {fullName}</h4>
        <p>{description}</p>
        <span className={styles["blog__body__tabs"]}>
          <span
            onClick={() => navigate(`/dashboard/blogs/${slug}`)}
            className={styles["blog__body__tabs--read"]}
          >
            <AiOutlineRead size="1.2rem" />
            &nbsp;&nbsp; Post Details
          </span>
          {/* <span onClick={ () => navigate(`/dashboard/blogs/edit/${ slug }`) } className={ styles["blog__body__tabs--edit"] }><FaRegEdit size="1rem" />&nbsp;Edit Post</span> */}
          {/* <span className={ styles["blog__body__tabs--delete"] }><MdDeleteForever size="1rem" />&nbsp;Delete</span> */}
        </span>
      </div>
    </div>
  );
};

export default Blog;
