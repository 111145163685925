import React from 'react'
import CommunityCardWrapper from '../CommunityCardWrapper'
const AllCommunities = () => {

  return (
    <div>
      <CommunityCardWrapper />
    </div>
  )
}

export default AllCommunities