import { useState } from "react";
import styles from "./task.module.scss";
import moment from "moment";
import { useContext } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import { toast } from "react-toastify";
import TaskSubmittedModal from "../TaskSubmittedModal";

const TaskSubmission = ({ task }) => {
  const { disableUserAccount, enableUserAccount, fetchAllUsers, makeAdmin } =
    useContext(AdminContext);

  const pushToSingleUser = (id) => {
    window.location.href = `/dashboard/users/${id}`;
  };

  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(false);
  };

  return (
    <div className={styles["task"]}>
      {task ? (
        <>
          <span className={styles["task__first__element"]}>
            {task?.user?.fullName}
          </span>
          <span>{task?.user?.email}</span>
          <span>{task?.task?.description.slice(0, 20)}</span>
          <span>{moment(task?.createdAt).format("DD MMMM YYYY")}</span>
          <span
            onClick={() => setShowModal(true)}
            className={styles["task__action--success"]}
          >
            View
          </span>

          {showModal ? (
            <TaskSubmittedModal task={task} handleClick={handleClick} />
          ) : null}
        </>
      ) : (
        <p>No task yet</p>
      )}
    </div>
  );
};

export default TaskSubmission;
