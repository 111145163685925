const GameReducers = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      }

      case "SET_SINGLE_GAME_LOADING":
        return {
          ...state,
          singleGameLoading: true,
      }
  

    case "SET_LIVE_SESSIONS_LOADING":
      return {
        ...state,
        liveSessionsLoading: true,
    }

    case "SET_SINGLE_GAME" : 
      return {
        ...state,
        singleGameData: action.payload
      }
    case "SET_ALL_ACTIVITIES" : 
      return {
        ...state,
        allActivities: action.payload
      }

    case "CLEAR_LOADING":
      return {
        ...state,
        loading: false,
        liveSessionsLoading: false,
        singleGameLoading: false
      }


    case "SET_ALL_GAMES":
      return {
        ...state,
        allGames: action.payload,
      }

      case "SET_GAME_STATS":
        return {
          ...state,
          gameStats: action.payload,
        }

      case "SET_LIVE_SESSIONS":
        return {
          ...state,
          liveSessions: action.payload,
        }

    default:
      return state
  }
}

export default GameReducers
