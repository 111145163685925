import { IoIosArrowForward } from "react-icons/io";
import styles from "./games-card.module.scss";
import { SlOptions } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

const GamesCard = ({ games }) => {
  // const { makeFeaturedAdventure } = useContext(AdventureContext);
  const navigate = useNavigate();

  const { id, name, imageUrl, sessionsNumber} = games;



  const handleViewGame = (event, id) => {
    event.stopPropagation(); 
    navigate('/dashboard/games/' + id); 
  };
  

  return (
    <>
    
      <div className={styles["card"]} key={id} >
        <div className={styles["card__image"]} style={{ backgroundImage: `url(${imageUrl})` }}  />
        <div className={styles["card__body"]}>
          <div className={styles["card__body__name"]}>
            <h4>{name}</h4>
            <SlOptions />
          </div>
          <p className={styles["card__body__level"]}>{sessionsNumber} Game Sessions</p>
          
          
          <div className={styles["card__body__view"]} onClick={(event) => handleViewGame(event, id)}>
            View Game
            <IoIosArrowForward size="1.2rem" fill='#1579E4'/>
          </div>
        
          <br />
          <br />
        </div>
      </div>



    </>
    
  );
};

export default GamesCard;
