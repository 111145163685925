import { useState, useContext } from "react";
import styles from "./settings-wrapper.module.scss";
import AdminContext from "@/context/AdminContext/AdminContext";
import { toast } from "react-toastify";

const Config = ({ config }) => {
  const { updateConfig, getConfigs, deleteConfig } = useContext(AdminContext);

  const [edit, setEdit] = useState(false);

  const [formData, setFormData] = useState({
    key: config.key || "",
    value: config.value || "",
  });

  //Update Keys
  const updateConfigById = async () => {
    await updateConfig(config.id, formData).then(async (res) => {
      if (res) {
        toast.success("Key updated successfully!");
        await getConfigs();
      } else {
        toast.error("Error updating config..!");
      }
    });
  };

  //Delete Keys
  const deleteConfigById = async () => {
    await deleteConfig(config.id).then(async (res) => {
      if (res) {
        toast.success("Config deleted successfully!");
        await getConfigs();
      } else {
        toast.error("Error deleting config..!");
      }
    });
  };

  const handleChange = (type, e) => {
    if (type === "key") {
      setFormData({
        ...formData,
        key: e.target.value,
      });
    } else if (type === "value") {
      setFormData({
        ...formData,
        value: e.target.value,
      });
    }
  };

  return (
    <div className={styles["config"]}>
      <>
        {edit ? (
          <input
            type="text"
            defaultValue={formData.key}
            className={styles["config__first__element"]}
            onChange={(e) => handleChange("key", e)}
          />
        ) : (
          <span className={styles["config__first__element"]}>
            {config?.key}
          </span>
        )}
        {edit ? (
          <input
            type="text"
            defaultValue={formData.value}
            className={styles["config__first__element"]}
            onChange={(e) => handleChange("value", e)}
          />
        ) : (
          <span className={styles["config__first__element"]}>
            {config?.value}
          </span>
        )}
        <span
          onClick={() => {
            if (edit) {
              updateConfigById();
            } else {
              setEdit(!edit);
            }
          }}
          style={{ cursor: "pointer" }}
          className={styles["config__action--success"]}
        >
          {edit ? "Save" : "Edit"}
        </span>

        <span
          onClick={async () => {
            if (edit) {
              setEdit(!edit);
            } else {
              deleteConfigById();
            }
          }}
          style={{ cursor: "pointer" }}
          className={styles["config__action--danger"]}
        >
          {edit ? "Cancel" : "Delete"}
        </span>
      </>
    </div>
  );
};

export default Config;
