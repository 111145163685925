import React, { useContext } from 'react';
import { Link, useMatch } from 'react-router-dom';
import styles from './sidebar.module.scss';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
// import thumbnail from "../../public/assets/user1.svg"
import { MdOutlineDashboardCustomize, MdQuiz } from 'react-icons/md';
import { HiUsers } from 'react-icons/hi';
import { FaUsers, FaBlog, FaGamepad } from 'react-icons/fa';
import { HiOutlineBars3BottomLeft } from 'react-icons/hi2';
import { HiShoppingBag } from 'react-icons/hi2';
import { BiLogOutCircle } from 'react-icons/bi';
import { AiFillSetting } from 'react-icons/ai';
// import bag from '../../assets/icons/bag.svg';

import AuthContext from '@/context/Auth/AuthContext';

export interface SidebarProps {
  label: string;
  path: string;
  icon: any;
}

const SideBar = () => {
  const { adminLogout } = useContext(AuthContext);

  const SideBarLink: React.FC<SidebarProps> = ({ label, path, icon }) => {
    const location = useLocation();

    // Match the path including potential dynamic segments
    const match = useMatch({
      path: `/dashboard/${path}/*`, // Match any sub-route
      end: false, // Allow partial matching
    });
  
    const isActive = !!match; // Match is truthy if the current path starts with `/dashboard/${path}`
  
    const linkClassName = classNames({
      [styles['sidebar-link--label']]: true,
      [styles['sidebar-link--label-active']]: isActive,
    });

    return (
      <Link to={`/dashboard/${path}`}>
        <div className={linkClassName}>
          {icon}&nbsp;&nbsp;{label}
        </div>
      </Link>
    );
  };
  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__linkholder']}>
        <SideBarLink
          path='overview'
          label='Dashboard'
          icon={<MdOutlineDashboardCustomize size='1.2rem' />}
        />
        <SideBarLink
          path='users'
          label='Users'
          icon={<HiUsers size='1.2rem' />}
        />
        <SideBarLink
          path='communities'
          label='Communities'
          icon={<FaUsers size='1.2rem' />}
        />
        <SideBarLink
          path='adventures'
          label='Adventures'
          icon={<HiOutlineBars3BottomLeft size='1.2rem' />}
        />

        <SideBarLink
          path='products'
          label='Products'
          icon={<HiShoppingBag size='1.2rem' />}
        />
        <SideBarLink
          path='blogs'
          label='Blogs'
          icon={<FaBlog size='1.2rem' />}
        />
        <SideBarLink
          path='daily-quiz'
          label='Daily Quiz'
          icon={<MdQuiz size='1.2rem' />}
        />

        <SideBarLink
          path="games"
          label="Games"
          icon={<FaGamepad size="1.2rem" />}
        />


        <SideBarLink
          path='settings'
          label='Settings'
          icon={<AiFillSetting size='1.2rem' />}
        />

        <div className={styles['sidebar-link--label']} onClick={adminLogout}>
          <BiLogOutCircle size='1.2rem' />
          &nbsp;&nbsp;Logout
        </div>
      </div>
    </div>
  );
};

export default SideBar;
