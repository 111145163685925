import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import styles from "./tasks-wrapper.module.scss";
import TaskSubmission from "../TaskSubmission";
import Spinner from "../Spinner";
import AdventureContext from "@/context/Adventure/AdventureContext";
import Search from "../Search";

const TasksSubmissionWrapper = ({ adventureId }) => {
  const { loading, fetchAdventureTask, pagination, adventure, tasks } =
    useContext(AdventureContext);
  const [result, setResult] = useState([]);

  useEffect(() => {
    setResult(tasks);
  }, [tasks]);

  const handleSearch = (name) => {
    const filteredTasks = tasks.filter((user) => {
      return Object.values(user)
        .join(" ")
        .toLowerCase()
        .includes(name.toLowerCase());
    });

    setResult(filteredTasks);
  };

  const handlePaginationPrev = async () => {
    const query = `pageNumber=${pagination?.currentPage - 1}&pageSize=${
      pagination?.pageSize
    }`;
    await fetchAdventureTask(adventureId, query);
  };

  const handlePaginationNext = async () => {
    const query = `pageNumber=${pagination?.currentPage + 1}&pageSize=${
      pagination?.pageSize
    }`;
    await fetchAdventureTask(adventureId, query);
  };

  const exportHeaders = [
    { label: "Full Name", key: "fullName" },
    { label: "Email", key: "email" },
    { label: "Task Description", key: "taskDescription" },
    { label: "Date", key: "date" },
  ];

  const data = () => {
    return tasks?.map((task) => {
      return {
        fullName: task.user.fullName,
        email: task.user.email,
        taskDescription: task.description,
        date: moment(task?.createdAt).format("DD MMMM YYYY"),
      };
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className={styles["tasks__wrapper"]}>
      <div>
        {tasks.length > 0 && (
          <CSVLink
            data={data()}
            headers={exportHeaders}
            filename={`${adventure?.name}_task_submissions.csv`}
          >
            <button className={styles["tasks__wrapper__pagination__export"]}>
              Export as CSV
            </button>
          </CSVLink>
        )}
      </div>
      <Search
        handleChange={handleSearch}
        // searchTerm={searchTerm}
        label="task"
      />
      <div className={styles["tasks__wrapper__container"]}>
        <div className={styles["tasks__wrapper__grid"]}>
          <div>User Name</div>
          <div>Email Address</div>
          <div>Task description</div>
          <div>Date submitted</div>
          <div>View</div>
        </div>
        {loading && tasks?.length === 0 ? (
          <Spinner />
        ) : result.length > 0 ? (
          result.map((task) => <TaskSubmission key={task.id} task={task} />)
        ) : tasks.length === 0 ? (
          <>No tasks submitted on this adventure</>
        ) : (
          <>No tasks</>
        )}
      </div>
      {pagination?.totalCount > 0 ? (
        <>
          <div className={styles["tasks__wrapper__pagination"]}>
            <span>
              Page {pagination?.currentPage} - {pagination?.totalPages}
            </span>
            <span>
              {(pagination?.currentPage - 1) * pagination?.pageSize + 1} -
              {pagination?.hasNext
                ? pagination?.pageSize * pagination?.currentPage
                : pagination?.totalCount}
            </span>
          </div>
          <div className={styles["tasks__wrapper__pagination"]}>
            {pagination?.hasPrevious ? (
              <button
                className={styles["tasks__wrapper__pagination__button"]}
                onClick={handlePaginationPrev}
                disabled={!pagination?.hasPrevious}
              >
                Prev
              </button>
            ) : null}
            {pagination?.hasNext ? (
              <button
                className={styles["tasks__wrapper__pagination__button"]}
                onClick={handlePaginationNext}
                disabled={!pagination?.hasNext}
              >
                Next
              </button>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TasksSubmissionWrapper;
