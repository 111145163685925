import { createContext, useReducer, useContext } from "react";
import axios from "../../utils/axios";
import BlogsReducer from "./BlogsReducer";
import AlertContext from "../Alert/AlertContext";

const BlogsContext = createContext();

export const BlogsProvider = ({ children }) => {
  const initialState = {
    loading: false,
    blog: {},
    blogs: [],
    // postCover: {},
  };

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(BlogsReducer, initialState);

  const { setSuccessAlert } = useContext(AlertContext);

  // Fetch All Blogs
  const fetchAllBlogs = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .get(`/admin/post`)
      .then((res) => {
        const data = res.data.data.result;

        dispatch({
          type: "SET_BLOGS",
          payload: data,
        });
        // setSuccessAlert("Adventure created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Fetch Single Blog
  const fetchSingleBlog = async (blogId) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .get(`/admin/post/${blogId}`)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_BLOG",
          payload: data,
        });
        // setSuccessAlert("Adventure created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Fetch Single Blog By slug
  const fetchSingleBlogBySlug = async (slug) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .get(`/admin/post/slug/${slug}`)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_BLOG",
          payload: data,
        });
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Create New Blog Post
  const createNewPost = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/post`, formData)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_BLOG",
          payload: data,
        });
        setSuccessAlert("Blog created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Update Blog
  const updateBlog = async (blogId, formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/post/${blogId}`, formData)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_BLOG",
          payload: data,
        });
        setSuccessAlert("Blog updated successfully.");

        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Delete A Blog Post
  const deleteBlogPost = async (blogId) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .delete(`/admin/post/${blogId}`)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_BLOG",
          payload: data,
        });
        setSuccessAlert("Blog successfully deleted.");

        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // // Upload Image
  // const uploadPostCover = async (image) => {
  //   dispatch({
  //     type: "SET_LOADING",
  //   });
  //   // await beforeUpload(image)
  //   const res = await UploadImage(image);

  //   dispatch({
  //     type: "SET_POST_COVER",
  //     payload: res,
  //   });

  //   setLoadingToFalse();
  // };

  const setLoadingToFalse = (payload) => {
    dispatch({ type: "CLEAR_LOADING", payload: payload ? payload : null });
  };

  return (
    <BlogsContext.Provider
      value={{
        loading: state.loading,
        blog: state.blog,
        blogs: state.blogs,
        postCover: state.postCover,
        fetchAllBlogs,
        fetchSingleBlog,
        fetchSingleBlogBySlug,
        createNewPost,
        // uploadPostCover,
        updateBlog,
        deleteBlogPost,
        setLoadingToFalse,
      }}
    >
      {children}
    </BlogsContext.Provider>
  );
};

export default BlogsContext;
