import React from "react";
import styles from "./settings-wrapper.module.scss";
import Spinner from "../Spinner";
import AdminContext from "@/context/AdminContext/AdminContext";
import { useContext, useEffect, useState } from "react";
import Search from "../Search";
import ModalComponent from "../ModalComponent";
import CreateConfig from "../CreateConfig";
import Config from "./Config";

const SettingsWrapper = () => {
  const { loading, pagination, configs, getConfigs } = useContext(AdminContext);
  const [addConfig, setAddConfig] = useState(false);

  useEffect(() => {
    getConfigs();
    // eslint-disable-next-line
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleModalClose = () => {
    setAddConfig(false);
  };

  const handleSearch = (name) => {
    setSearchTerm(name);

    const filteredConfig = configs.filter((config) => {
      return Object.values(config)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setSearchResult(filteredConfig);
  };

  const handlePaginationPrev = async () => {
    const query = `pageNumber=${pagination?.currentPage - 1}&pageSize=${
      pagination?.pageSize
    }`;
    await getConfigs(query);
  };

  const handlePaginationNext = async () => {
    const query = `pageNumber=${pagination?.currentPage + 1}&pageSize=${
      pagination?.pageSize
    }`;
    await getConfigs(query);
  };

  const newConfigs = searchTerm === "" ? configs : searchResult;

  return loading ? (
    <Spinner />
  ) : (
    <div className={styles["configs__wrapper"]}>
      <Search handleChange={handleSearch} label="configuration" />
      <h3>Admin Configurations</h3>
      <div className={styles["configs__wrapper__container"]}>
        <div className={styles["configs__wrapper__grid"]}>
          <div>Key</div>
          <div>Value</div>
          <div>Edit</div>
          <div>Delete</div>
        </div>
        {loading && configs?.length === 0 ? (
          <Spinner />
        ) : (
          newConfigs.map((config) => <Config key={config.id} config={config} />)
        )}
      </div>
      <div>
        <span
          onClick={() => {
            setAddConfig(true);
          }}
          style={{ cursor: "pointer" }}
          className={styles["config__active"]}
        >
          Add a new config
        </span>
      </div>
      <br />
      <div className={styles["configs__wrapper__pagination"]}>
        <span>
          Page {pagination?.currentPage} - {pagination?.totalPages}
        </span>
        <span>
          {(pagination?.currentPage - 1) * pagination?.pageSize + 1} -
          {pagination?.hasNext
            ? pagination?.pageSize * pagination?.currentPage
            : pagination?.totalCount}
        </span>
      </div>
      <div className={styles["configs__wrapper__pagination"]}>
        {pagination?.hasPrevious ? (
          <button
            className={styles["configs__wrapper__pagination__button"]}
            onClick={handlePaginationPrev}
            disabled={!pagination?.hasPrevious}
          >
            Prev
          </button>
        ) : null}
        {pagination?.hasNext ? (
          <button
            className={styles["configs__wrapper__pagination__button"]}
            onClick={handlePaginationNext}
            disabled={!pagination?.hasNext}
          >
            Next
          </button>
        ) : null}
      </div>
      {addConfig ? (
        <ModalComponent show={addConfig} closeModal={handleModalClose}>
          <CreateConfig closeModal={handleModalClose} />
        </ModalComponent>
      ) : null}
    </div>
  );
};

export default SettingsWrapper;
