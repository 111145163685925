import { useContext } from "react";
import styles from "./alert.module.scss";
import AlertContext from "@/context/Alert/AlertContext";
import { FaTimes } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";

const Alert = () => {
  const { toggleAlert, type, message, closeAlert } = useContext(AlertContext);

  //Function to clear alert
  const removeAlert = () => {
    closeAlert();
  };

  // toast(state.message, {
  //   toastId: state.message,
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   theme: "light",
  // });

  return toggleAlert ? (
    <span className={type === "success" ? styles["success"] : styles["error"]}>
      <span
        className={
          type === "success"
            ? styles["success__helper"]
            : styles["error__helper"]
        }
      >
        {type === "success" ? <TiInfo /> : <TiInfo />}
      </span>
      <p>{message}</p>&nbsp;&nbsp;
      <button style={{ cursor: "pointer" }} onClick={removeAlert}>
        <FaTimes size=".8rem" fill="#fff" />
      </button>
    </span>
  ) : null;
};

export default Alert;
