import CommunitiesTableHead from "../CommunitiesTableHead";
import styles from "./community.module.scss";
import Community from "../Community";
import Spinner from "../Spinner";
import { useContext } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";

const PendingCommunities = () => {
  const { loading, pendingCommunities } = useContext(AdminContext);

  return (
    <div>
      <h3 style={{ fontFamily: "Cera Pro Bold", color: "#000" }}>
        Pending Communities
      </h3>
      <div className={styles["communities__table__container"]}>
        <CommunitiesTableHead />
        
        {loading ? (
          <Spinner />
        ) : !loading && pendingCommunities.length > 0 ? (
          pendingCommunities.map((community) => (
            <Community key={community?.id} community={community} />
          ))
        ) : (
          <p>There are no pending community request at the moment</p>
        )}
      </div>
    </div>
  );
};

export default PendingCommunities;
