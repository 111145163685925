const BadgeReducers = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "CLEAR_LOADING":
      return {
        ...state,
        loading: false,
      };

    // case "SET_BADGES":
    //   return {
    //     ...state,
    //     badge: action.payload,
    //   };

    case "SET_BADGES":
      return {
        ...state,
        allBadges: action.payload,
      };

    default:
      return state;
  }
};

export default BadgeReducers;
