import { createContext, useReducer, useContext } from "react";
import axios from "../../utils/axios";
import AdventureReducers from "./AdventureReducers";
import AlertContext from "../Alert/AlertContext";
import { toast } from "react-toastify";

const AdventureContext = createContext();

export const AdventureProvider = ({ children }) => {
  const initialState = {
    loading: false,
    adventure: {},
    allAdventures: [],
    tasks: [],
    pagination: {},
    submissions: [],
    quizzes: [],
    quiz: {},
    dailyQuiz: {},
  };

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(AdventureReducers, initialState);

  const { setSuccessAlert, setErrorAlert } = useContext(AlertContext);

  // Fetch All Adventures
  const fetchAllAdventures = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .get(`/admin/adventure`)
      .then((res) => {
        const data = res.data.data.result;

        dispatch({
          type: "SET_ADVENTURES",
          payload: data,
        });
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Make an Adventure a Featured Adventure
  const makeFeaturedAdventure = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/adventure/feature`, formData)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_ADVENTURE",
          payload: data,
        });
        toast.success("Adventure is now a featured adventure");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Fetch Single Adventure
  const fetchSingleAdventure = async (adventureId) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .get(`/admin/adventure/${adventureId}`)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_ADVENTURE",
          payload: data,
        });
        // setSuccessAlert("Adventure created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return null;
      });
  };

  // update Adventure
  const createNewAdventure = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/adventure`, formData)
      .then((res) => {
        const data = res.data.data;

        setSuccessAlert("Adventure created successfully.");
        return data;
      })
      .catch(() => {
        setErrorAlert("Error creating adventure.");
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // update Adventure
  const updateAdventure = async (adventureId, formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/adventure/${adventureId}`, formData)
      .then((res) => {
        const data = res.data.data;

        dispatch({
          type: "SET_ADVENTURE",
          payload: data,
        });
        setSuccessAlert("Adventure updated successfully.");

        return data;
      })
      .catch(() => {
        setErrorAlert("Error updating adventure.");
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // feature adventure
  const featureAdventure = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/adventure/feature`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Adventure featured successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  // delete Adventure
  const deleteAdventure = async (adventureId) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .delete(`/admin/adventure/${adventureId}`)
      .then((res) => {
        setSuccessAlert("Adventure deleted successfully.");
        return true;
      })
      .catch(() => {
        setErrorAlert("Error deleting adventure, try again.");
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // create module
  const createModules = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/modules`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Modules created successfully.");
        return data;
      })
      .catch((err) => {
        setErrorAlert(
          err?.response?.data?.message || "Error creating modules..."
        );
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  // create module
  const createModule = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/module`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Module created successfully.");
        return data;
      })
      .catch((err) => {
        setErrorAlert(
          err?.response?.data?.message || "Error creating module..."
        );
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  // create quiz
  const createQuiz = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/quiz`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Quiz created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  // create quiz question
  const createQuizQuestion = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/quiz/question`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Quiz question created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  // delete module
  const deleteModule = async (moduleId) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .delete(`/admin/module/${moduleId}`)
      .then((res) => {
        setSuccessAlert("Module deleted successfully.");
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // delete lesson
  const deleteLesson = async (lessonId) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .delete(`/admin/lesson/${lessonId}`)
      .then((res) => {
        setSuccessAlert("Lesson deleted successfully.");
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // update quiz question
  const updateQuizQuestion = async (quizQuestionId, formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/quiz/question/${quizQuestionId}`, formData)
      .then((res) => {
        setSuccessAlert("Quiz question updated successfully.");
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // delete quiz question
  const deleteQuizQuestion = async (quizQuestionId) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .delete(`/admin/quiz/question/${quizQuestionId}`)
      .then((res) => {
        setSuccessAlert("Quiz question deleted successfully.");
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // delete quiz question option
  const deleteQuizQuestionOption = async (quizQuestionOptionId) => {
    return await axios
      .delete(`/admin/quiz/question/option/${quizQuestionOptionId}`)
      .then((res) => {
        setSuccessAlert("Quiz question option deleted successfully.");
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // add lesson from module
  const addLessonFromModule = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/lesson/module`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Lesson created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  const fetchModulesByAdventure = async (adventureId) => {
    return await axios
      .get(`/admin/module/adventure/${adventureId}`)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((res) => {
        return null;
      })
      .finally(() => {
        setLoadingToFalse();
        return null;
      });
  };

  const fetchLessonsByModule = async (moduleId) => {
    return await axios
      .get(`/admin/lesson/module/${moduleId}`)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((res) => {
        return null;
      })
      .finally(() => {
        setLoadingToFalse();
        return null;
      });
  };

  const fetchTaskByModule = async (moduleId) => {
    return await axios
      .get(`/admin/task/module/${moduleId}`)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((res) => {
        return null;
      })
      .finally(() => {
        setLoadingToFalse();
        return null;
      });
  };

  // add task from module
  const addModuleTask = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .post(`/admin/task`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Task created successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  // update task from module
  const updateModuleTask = async (taskId, formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/task/${taskId}`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Task updated successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return false;
      });
  };

  // update Adventure
  const updateLesson = async (lessonId, formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/lesson/${lessonId}`, formData)
      .then((res) => {
        const data = res.data.data;
        setSuccessAlert("Lesson updated successfully.");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // fetch tasks
  const fetchAdventureTask = async (adventureId, query) => {
    dispatch({
      type: "SET_LOADING",
    });
    const queryText = query ? query : "pageNumber=1&pageSize=20";
    await axios
      .get(`/admin/task/submissions/adventure/${adventureId}?${queryText}`)
      .then((response) => {
        const data = response.data.data.result;
        dispatch({
          type: "SET_TASKS",
          payload: data,
        });
        const pagination = {
          currentPage: response.data.data.currentPage,
          pageSize: response.data.data.pageSize,
          totalPages: response.data.data.totalPages,
          totalCount: response.data.data.totalCount,
          hasPrevious: response.data.data.hasPrevious,
          hasNext: response.data.data.hasNext,
        };
        dispatch({
          type: "SET_PAGINATION",
          payload: pagination,
        });
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // verify tasks
  const verifyTask = async (formData) => {
    dispatch({
      type: "SET_LOADING",
    });

    const response = await axios
      .put(`/admin/task/verify`, formData)
      .then((response) => {
        const data = response.data.data;
        return data;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setLoadingToFalse();
      });

    return response;
  };

  // fetch daily quizzes
  const fetchDailyQuizzes = async (query) => {
    dispatch({
      type: "SET_LOADING",
    });
    const queryText = query ? query : "pageNumber=1&pageSize=20";
    await axios
      .get(`/quiz/daily/quizzes?${queryText}`)
      .then((response) => {
        const data = response.data.data.result;
        dispatch({
          type: "SET_QUIZZES",
          payload: data,
        });
        const pagination = {
          currentPage: response.data.data.currentPage,
          pageSize: response.data.data.pageSize,
          totalPages: response.data.data.totalPages,
          totalCount: response.data.data.totalCount,
          hasPrevious: response.data.data.hasPrevious,
          hasNext: response.data.data.hasNext,
        };
        dispatch({
          type: "SET_PAGINATION",
          payload: pagination,
        });
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // fetch quiz submissions
  const fetchQuizSubmissions = async (id, query) => {
    dispatch({
      type: "SET_LOADING",
    });
    const queryText = query ? query : "pageNumber=1&pageSize=20";
    await axios
      .get(`/admin/quiz/submissions/${id}?${queryText}`)
      .then((response) => {
        const data = response.data.data.result;
        dispatch({
          type: "SET_SUBMISSIONS",
          payload: data,
        });
        const pagination = {
          currentPage: response.data.data.currentPage,
          pageSize: response.data.data.pageSize,
          totalPages: response.data.data.totalPages,
          totalCount: response.data.data.totalCount,
          hasPrevious: response.data.data.hasPrevious,
          hasNext: response.data.data.hasNext,
        };
        dispatch({
          type: "SET_PAGINATION",
          payload: pagination,
        });
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Make a quiz a daily quiz
  const makeDailyQuiz = async (id) => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/quiz/daily/feature/${id}`)
      .then((res) => {
        const data = res.data.data;

        toast.success("Quiz is now a set as the quiz for the day");
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // Switch daily quiz on and off
  const switchDailyQuiz = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    return await axios
      .put(`/admin/quiz/daily/switch`)
      .then((res) => {
        const data = res.data.data;

        toast.success("Daily quiz status updated.");
        getDailyQuiz();
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
      });
  };

  // fetch daily quizzes
  const getDailyQuiz = async () => {
    dispatch({
      type: "SET_LOADING",
    });

    const data = await axios
      .get(`/quiz/daily/current`)
      .then((response) => {
        const data = response.data.data;
        dispatch({
          type: "SET_DAILY_QUIZ",
          payload: data,
        });
        return data;
      })
      .catch(() => {
        dispatch({
          type: "SET_DAILY_QUIZ",
          payload: {},
        });
        return null;
      })
      .finally(() => {
        setLoadingToFalse();
      });
    return data;
  };

  // fetch daily quizzes
  const getQuizById = async (id) => {
    dispatch({
      type: "SET_LOADING",
    });

    const data = await axios
      .get(`/admin/quiz/${id}`)
      .then((response) => {
        const data = response.data.data;
        dispatch({
          type: "SET_QUIZ",
          payload: data,
        });
        return data;
      })
      .finally(() => {
        setLoadingToFalse();
        return null;
      });
    return data;
  };

  const setLoadingToFalse = (payload) => {
    dispatch({ type: "CLEAR_LOADING", payload: payload ? payload : null });
  };

  return (
    <AdventureContext.Provider
      value={{
        loading: state.loading,
        adventure: state.adventure,
        allAdventures: state.allAdventures,
        tasks: state.tasks,
        submissions: state.submissions,
        quizzes: state.quizzes,
        quiz: state.quiz,
        dailyQuiz: state.dailyQuiz,
        pagination: state.pagination,
        fetchAllAdventures,
        makeFeaturedAdventure,
        fetchSingleAdventure,
        createNewAdventure,
        createModule,
        createModules,
        createQuiz,
        createQuizQuestion,
        featureAdventure,
        deleteModule,
        deleteLesson,
        updateQuizQuestion,
        deleteQuizQuestion,
        deleteQuizQuestionOption,
        addLessonFromModule,
        updateAdventure,
        deleteAdventure,
        updateLesson,
        fetchLessonsByModule,
        fetchTaskByModule,
        addModuleTask,
        updateModuleTask,
        fetchModulesByAdventure,
        fetchAdventureTask,
        verifyTask,
        fetchDailyQuizzes,
        makeDailyQuiz,
        switchDailyQuiz,
        getDailyQuiz,
        getQuizById,
        fetchQuizSubmissions,
        setLoadingToFalse,
      }}
    >
      {children}
    </AdventureContext.Provider>
  );
};

export default AdventureContext;
