import React from "react";
import styles from "./community.module.scss";
import { useContext } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";
import { toast } from "react-toastify";

const showCommunityDetails = (id) => {
  window.location.href = `/dashboard/communities/${id}`;
};

const Community = ({ community }) => {
  const {
    fetchApprovedCommunities,
    declineApprovedCommunities,
    fetchDeclinedCommunities,
    fetchAllCommunities,

    fetchPendingCommunities
  } = useContext(AdminContext);



  return (
    <div className={styles["community"]}>
      <span>{community?.community?.name}</span>
      <span>{community?.owner?.fullName}</span>
      <span>{community?.numberOfMembers}</span>
      <span>{community?.status}</span>
      <span>
        <span
          onClick={() => showCommunityDetails(community?.communityId)}
          className={styles["community__btn--details"]}
        >
          See Details
        </span>
        &nbsp;&nbsp;
        <span
          className={styles["community__btn--danger"]}
          onClick={async () => {
            if (community?.status === "APPROVED") {
              await declineApprovedCommunities({
                communityId: community?.communityId,
                status: "DECLINED",
              });
              toast.success("Community declined successfully");
            } else if (
              community?.status === "PENDING" ||
              community?.status === "DECLINED"
            ) {
              await declineApprovedCommunities({
                communityId: community?.communityId,
                status: "APPROVED",
              });


              toast.success("Community approved successfully");
              await fetchPendingCommunities()
            }
            await fetchDeclinedCommunities();
            await fetchApprovedCommunities();
            await fetchAllCommunities();
          }}
        >
          {community?.status === "APPROVED"
            ? "Decline"
            : community?.status === "PENDING"
            ? "Approve"
            : "Approve"}
        </span>
      </span>
    </div>
  );
};

export default Community;
