import React from 'react'
import styles from "./stats-card.module.scss"

export interface StatsCardProps {
  icon: any;
  title: string;
  value: any,
  bgColor?: string
}

const StatsCard: React.FC<StatsCardProps> = ({
    icon,
    title,
    value,
    bgColor
}) => {
  return (
    <div className={ styles["stats__card"] } >
      {bgColor ? (
        <div style={{background: `${bgColor}`}} className={ styles["icon"] } >
          <p>{ icon }</p> 
        </div>
      ) 
      : (
        <span style={{background: `${bgColor}`}}>{ icon }</span>   
      )}
      
      <h4>{ title }</h4>
      <h2>{ value }</h2>
    </div>
  )
}

export default StatsCard