import React from "react";
import CommunitiesTableHead from "../CommunitiesTableHead";
import styles from "./community.module.scss";
import AdminContext from "@/context/AdminContext/AdminContext";
import { useContext, useEffect } from "react";
import Spinner from "../Spinner";
import Community from "../Community";

const ApprovedCommunities = () => {
  const { approvedCommunities, loading } = useContext(AdminContext);

  // useEffect(() => {
  //  fetchApprovedCommunities()
  //  // eslint-disable-next-line
  // }, [])

  return (
    <div>
      <h3 style={{ fontFamily: "Cera Pro Bold", color: "#000" }}>
        Approved Communities
      </h3>
      <div className={styles["communities__table__container"]}>
        <CommunitiesTableHead />
        {loading ? (
          <Spinner />
        ) : !loading && approvedCommunities.length > 0 ? (
          approvedCommunities.map((community) => (
            <Community key={community?.id} community={community} />
          ))
        ) : (
          <p>There are no approved communities at the moment</p>
        )}
      </div>
    </div>
  );
};

export default ApprovedCommunities;
