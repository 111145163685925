import styles from "./overlay.module.scss";
import { RiCloseCircleFill } from "react-icons/ri";

const ModalComponent = ({ show, closeModal, children }) => {
  return (
    <div className={styles["modal"]}>
      <div
        className={styles["modal__bg_overlay"]}
        style={{ display: show ? "block" : "none" }}
        // onClick={closeModal}
      >
        <div className={styles["modal__centered"]}>
          <div className={styles["modal__content_wrapper"]}>
            <div
              onClick={() => closeModal(!show)}
              className={styles["modal__closebtn"]}
            >
              <RiCloseCircleFill size={"1.3em"} />
            </div>
            <div className={styles["modal__content"]}> {children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
