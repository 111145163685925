
import styles from "./comment.module.scss";
import UserMetaInfo from "./UserMetaInfo";
// import UserMetaInfo from "./UserMetaInfo";

const ProductCommentReplies = ({ reply }) => {
  
  return (
    <div className={styles["comments"]}>
      <div className={styles["comment"]}>
        <UserMetaInfo user={reply?.user} date={reply?.createdAt} />
        <p>{reply?.content}</p>
        <div>
          {/* <span onClick={async () => await likeComment(reply?.id)}>Like</span> */}
          {/* <span>Delete</span> */}
        </div>
      </div>
    </div>
  );
};

export default ProductCommentReplies;
