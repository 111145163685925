export interface ICreateAdventure {
    id: string | null;
    name: string;
    imageUrl: string;
    description: string;
    difficultyLevel: AdventureDifficultyLevel;
    gains: string;
    expectations: string;
    earnings: string;
    rewardPoint: number;
    badgeId: string
}

export enum AdventureDifficultyLevel {
    BEGINNER = "BEGINNER",
    INTERMEDIATE = "INTERMEDIATE",
    EXPERT = "EXPERT",
}


export interface IQuizOption {
    id?: string;
    text: string;
    isCorrect: boolean;
}

export interface IQuestion {
    id?: string;
    index: number;
    show: boolean;
    text: string;
    options: IQuizOption[];
}

export interface ILesson {
    id?: string;
    index: number;
    show: boolean;
    name: string;
    text?: string;
    videoUrl?: string;
    quizId?: string;
    quizzes?: IQuestion[];
}

export interface IModule {
    id?: string;
    index: number;
    show: boolean;
    name: string;
    description?: string;
    lessons: ILesson[];
    task?: ITask
}

export interface ITask {
    id?: string;
    isActive: boolean;
    show: boolean;
    link?: string,
    text?: string
}