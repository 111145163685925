import React from "react";
import AdventureCard from "@/components/AdventureCard";
import styles from "./adventures.module.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import AdventureContext from "@/context/Adventure/AdventureContext";
import Spinner from "@/components/Spinner";

const Adventures = () => {
  const { loading, allAdventures, fetchAllAdventures } =
    useContext(AdventureContext);

  useEffect(() => {
    fetchAllAdventures();
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const handleAction = () => {
    navigate("/dashboard/adventure/create");
  };

  return (
    <div className={styles["adventures"]}>
      <div className={styles["adventures__header"]}>
        <div>
          <h2>Adventures</h2>
          <p className={styles["adventures__text"]}>
            Create new modules, lessons or tasks for your adventures
          </p>
        </div>

        <button className={styles["adventures__action"]} onClick={handleAction}>
          Create Adventure
        </button>
      </div>

      <div className={styles["adventures__list"]}>
        {loading ? (
          <Spinner />
        ) : allAdventures?.length > 0 ? (
          allAdventures.map((adventure) => (
            <AdventureCard key={adventure?.id} adventure={adventure} />
          ))
        ) : (
          <>
            <h3>No adventures available</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default Adventures;
