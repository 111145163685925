import { useState, useContext, useEffect } from "react";
import styles from "./details.module.scss";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { getBase64, UploadImage, validationSchema } from "@/utils/helpers";
import Loader from "@/components/Loader";
import { BiCamera } from "react-icons/bi";
import { BsPencil } from "react-icons/bs";
import { ICreateAdventure } from "@/utils/interface";
import AlertContext from "@/context/Alert/AlertContext";
import AdventureContext from "@/context/Adventure/AdventureContext";
import BadgeContext from "@/context/Badge/BadgeContext";

interface IProps {
  createAdventure: ICreateAdventure;
  setCreateAdventure: any;
  handleActiveTab: any;
  setIsAboutOpen: any;
  adventureImage: any;
  setAdventureImage: any;
}

const AdventuresDetails = ({
  createAdventure,
  setCreateAdventure,
  handleActiveTab,
  setIsAboutOpen,
  adventureImage,
  setAdventureImage,
}: IProps) => {
  const { adventure } = useContext(AdventureContext);

   useContext(AdventureContext);

  const { allBadges, fetchAllBadges } = useContext(BadgeContext);

  
  useEffect(() => {
    fetchAllBadges()
  }, [])


  const [imageLoading, setIsImageLoading] = useState(false);

  const { setErrorAlert } = useContext(AlertContext);

  const { dynamic } = validationSchema;
  const initialState = {
    name: createAdventure.name,
    description: createAdventure.description,
    level: createAdventure.difficultyLevel,
    badgeId: createAdventure.badgeId
  };

  const handleSubmit = (values: typeof initialState) => {
    if (!adventureImage.imageUrl || !adventureImage.image) {
      setErrorAlert("No image, please provide an image.");
      setIsAboutOpen(false);
      return;
    }

    // const data = {
    //   ...createAdventure,
    //   name: values.name,
    //   description: values.description,
    //   difficultyLevel: values.level,
    //   badgeId: values.badgeId
    // }
    
    // console.log({data})

    setCreateAdventure({
      ...createAdventure,
      name: values.name,
      description: values.description,
      difficultyLevel: values.level,
      badgeId: values.badgeId
    });
    handleActiveTab("about");
    setIsAboutOpen(true);
  };

  const handleImageChange = async (info: any) => {
    getBase64(info, (url: any) => {
      setIsImageLoading(false);
      setAdventureImage({
        ...adventureImage,
        image: info,
        imageUrl: url,
        imageChanged: true,
      });
    });
  };

  return (
    <div className={styles["details__container"]}>
      <Formik
        initialValues={initialState}
        validationSchema={yup.object({
          name: dynamic(),
          description: dynamic(),
          level: dynamic(),
          badgeId: dynamic()
        })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className={styles["details__content"]}>
            <h3 className={styles["details__content__header"]}>
              Adventure Details
            </h3>

            <div>
              <Form>
                <br />
                <div className={styles["create__adventure__content__inputs"]}>
                  <div
                    className={styles["create__adventure__content__container"]}
                  >
                    <p>Adventure Image</p>
                    <label htmlFor="displayPhoto">
                      <div
                        className={styles["create__adventure__content__img"]}
                      >
                        {imageLoading ? (
                          <Loader />
                        ) : (
                          <>
                            {adventureImage.imageUrl.length > 0 ? (
                              <img
                                src={adventureImage.imageUrl}
                                height={120}
                                width={120}
                                alt="cover"
                              />
                            ) : (
                              <BiCamera size={`1.5em`} />
                            )}
                          </>
                        )}
                        <span
                          className={
                            styles["create__adventure__content__img__edit"]
                          }
                        >
                          <BsPencil />
                        </span>
                        <input
                          type="file"
                          className="form-control"
                          name="displayPhoto"
                          id="displayPhoto"
                          onChange={async (e: any) => {
                            await handleImageChange(e.currentTarget.files[0]);
                          }}
                        />
                      </div>
                    </label>
                  </div>

                  <div
                    className={styles["create__adventure__content__container"]}
                  >
                    <label>Adventure Name</label>
                    <Input
                      id="name"
                      type="text"
                      name="name"
                      label=""
                      placeholder="e.g Blockchain Introduction"
                    />
                  </div>

                  <div
                    className={styles["create__adventure__content__container"]}
                  >
                    <label>Adventure Description</label>
                    <Input
                      id="description"
                      type="text"
                      name="description"
                      label=""
                      placeholder="e.g This adventure will take you on the journey to becoming a better blockchain user."
                    />
                  </div>

                  <div
                    className={styles["create__adventure__content__container"]}
                  >
                    <label>Difficulty Level</label>
                    <select
                      name="level"
                      id=""
                      className={styles["level_select"]}
                      onChange={(e) => {
                        props.setFieldValue("level", e.target.value);
                      }}
                    >
                      <option value="BEGINNER">BEGINNER</option>
                      <option value="INTERMEDIATE">INTERMEDIATE</option>
                      <option value="EXPERT">EXPERT</option>
                    </select>
                  </div>


                  {/* adding this field */}

                  <div className={styles["create__adventure__content__container"]}>
                    <label>Badge Type</label>
                    <select
                      name="badgeId"
                      id=""
                      className={styles["level_select"]}
                      onChange={(e) => {
                        props.setFieldValue("badgeId", e.target.value);
                      }}
                    >
                      {allBadges?.map((badge: any) => (
                        <option value={badge?.id}>{badge?.title}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className={styles["details__form-button"]}>
                  <Button type="submit" label="Continue" variant="primary" />
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AdventuresDetails;
