import { useContext } from "react";
import styles from "./adventure_module.module.scss";
import { Checkbox } from "antd";
import { IQuestion } from "@/utils/interface";
import AdventureContext from "@/context/Adventure/AdventureContext";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";

interface IProps {
  quiz: IQuestion;
  quizIndex: number;
  handleDeleteQuiz: any;
  handleQuizCollapse: any;
  handleQuizTextChange: any;
  handleQuizOptionTextChange: any;
  handleQuizDeleteOption: any;
  handleQuizAddOption: any;
  handleQuizOptionChange: any;
}

const QuizForLesson = ({
  quiz,
  quizIndex,
  handleDeleteQuiz,
  handleQuizCollapse,
  handleQuizTextChange,
  handleQuizOptionTextChange,
  handleQuizDeleteOption,
  handleQuizAddOption,
  handleQuizOptionChange,
}: IProps) => {
  const { loading, adventure, createNewAdventure, updateAdventure } =
    useContext(AdventureContext);

  return (
    <>
      <div className={styles["module__module_tab"]}>
        <h1 className={styles["module__module_tab__title"]}>
          Quiz {quiz.index + 1}
        </h1>
        <div>
          {quiz.show ? (
            <IoIosArrowDropup
              size="1.3rem"
              cursor="pointer"
              onClick={() => handleQuizCollapse(quiz.index)}
            />
          ) : (
            <IoIosArrowDropdown
              size="1.3rem"
              cursor="pointer"
              onClick={() => handleQuizCollapse(quiz.index)}
            />
          )}
          &nbsp;&nbsp;&nbsp;
          <MdDelete
            size="1.3rem"
            cursor="pointer"
            fill="red"
            onClick={() => handleDeleteQuiz(quiz.index)}
          />
        </div>
      </div>

      {/* QUIZ */}
      {quiz.show && (
        <div className={styles["module__module_display"]}>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Quiz {quiz.index + 1}</label>
            <input
              type="text"
              placeholder="What is your question?"
              className={styles["module__module_display_input"]}
              defaultValue={quiz.text}
              onChange={(e) => handleQuizTextChange(quiz.index, e)}
            />
          </div>
          <div className={styles["module__module_display__input_container"]}>
            <label htmlFor="name">Setup options for users to select from</label>
            {quiz.options.map((option, optionIndex) => (
              <>
                <input
                  type="text"
                  placeholder="Option 1"
                  className={styles["module__module_display_input"]}
                  defaultValue={option.text}
                  onChange={(e) =>
                    handleQuizOptionTextChange(quiz.index, optionIndex, e)
                  }
                />
                <div className={styles["module__remove_option"]}>
                  <span
                    onClick={() =>
                      handleQuizDeleteOption(quiz.index, optionIndex)
                    }
                  >
                    Remove option
                  </span>
                </div>
              </>
            ))}
          </div>
          <div className={styles["module__another_option"]}>
            <span onClick={() => handleQuizAddOption(quiz.index)}>
              <AiFillPlusCircle fill="#1579E4" /> Add option
            </span>
          </div>
          <div>
            {quiz.options.length > 0 ? (
              <>
                <label htmlFor="name">Choose answer(s)</label>
                <div className={styles["quiz__option"]}>
                  {quiz.options.map((item, index) => (
                    <Checkbox
                      onChange={(e) =>
                        handleQuizOptionChange(quiz.index, index, e)
                      }
                      checked={item.isCorrect}
                    >
                      {item.text}
                    </Checkbox>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          {/* <button className={styles["module__button"]}>Save Quiz</button> */}
        </div>
      )}
    </>
  );
};

export default QuizForLesson;
