import React from "react";
import styles from "./single-blog.module.scss";
import { useContext, useEffect, useState } from "react";
import BlogsContext from "@/context/Blogs/BlogsContext";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import moment from "moment";
// import thumbnail from "../../assets/images/blog.png"
import blogBanner from "../../assets/images/blog-banner.webp";
import Spinner from "@/components/Spinner";
import Prompt from "@/components/Prompt";
import EditModal from "@/components/EditModal";
import ModalComponent from "@/components/ModalComponent";

const SingleBlog = () => {
  const params = useParams();
  const slug = params.slug;

  const navigate = useNavigate();

  const { blog, fetchSingleBlogBySlug, deleteBlogPost, fetchAllBlogs } =
    useContext(BlogsContext);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    fetchSingleBlogBySlug(slug);
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    navigate("/dashboard/blogs");
  };

  const goToEdit = (slug) => {
    navigate(`/dashboard/blogs/edit/${slug}`);
  };

  const handleClick = () => {
    setShowModal((showModal) => !showModal);
  };

  const handleModalClick = () => {
    setShowEditModal((showEditModal) => !showEditModal);
  };

  const showEdit = () => {
    setShowEditModal((showEditModal) => !showEditModal);
  };

  const handleDelete = async () => {
    await deleteBlogPost(blog?.id);
    await fetchAllBlogs();
    navigate("/dashboard/blogs");
    // alert(`Blog post with ID ${ id } was succeddully deleted from the database...!!`)
  };

  return (
    <>
      {blog ? (
        <div className={styles["single__adventure"]}>
          {showModal ? (
            <Prompt
              id={blog?.id}
              title="Delete Blog Post"
              body="Your are about to delete this blog post and this action is irreversible as the post will be erased from the databse"
              handleClick={handleClick}
              handleDelete={handleDelete}
              buttonText="Confirm"
            />
          ) : null}

          <ModalComponent show={showEditModal} closeModal={handleModalClick}>
            <EditModal
              id={blog?.id}
              handleClick={handleModalClick}
              blog={blog}
            />
          </ModalComponent>

          <span
            onClick={() => goBack()}
            className={styles["single__adventure--back"]}
          >
            <BsArrowLeft /> &nbsp; Go Back
          </span>
          <div className={styles["single__adventure__header"]}>
            {/* <div className={ styles["single__adventure__header__img"] }>

                { blog?.thumbnailUrl === "empty" ? <img src={ blogBanner } className={ styles["single__adventure__header__img--img"] } alt='Blog thumbnail' /> : <img src={ blog?.thumbnailUrl } className={ styles["single__adventure__header__img--img"] } alt='Blog thumbnail' /> }
            </div> */}
            <div className={styles["single__adventure__header__body"]}>
              <h1>{blog?.title}</h1>
              <div className={styles["single__adventure__header__body--tabs"]}>
                <span>Likes: {blog?.likes}</span>
                <span>Comments: {blog?.commentCount}</span>
                <span>Author: {blog?.user?.fullName}</span>
                <span>
                  Created At: {moment(blog?.createdAt).format("Do MMMM, YYYY")}
                </span>
              </div>
              <h3>Description</h3>
              <p>{blog?.description}</p>
              <br />
              <br />
              {blog?.thumbnailUrl === "empty" ? (
                <img
                  className={styles["single__adventure__header__helperImg"]}
                  src={blogBanner}
                  alt="Blog"
                />
              ) : (
                <img
                  src={blog?.thumbnailUrl}
                  alt={blog?.description?.slice(0, 10) || ""}
                  className={styles["single__adventure__header__helperImg"]}
                />
              )}

              <h3>Content</h3>
              <div dangerouslySetInnerHTML={{ __html: blog?.content }}></div>
              <br />
              <br />
              <div className={styles["single__adventure__header__body--btn"]}>
                <span
                  onClick={() => goToEdit(blog?.id)}
                  className={
                    styles["single__adventure__header__body--btn-success"]
                  }
                >
                  Edit Post
                </span>
                <span
                  onClick={handleClick}
                  className={
                    styles["single__adventure__header__body--btn-danger"]
                  }
                >
                  Delete Post
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default SingleBlog;
