import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./sign-in.module.scss";
import Onboarding from "../../components/Onboarding";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { validationSchema } from "@/utils/helpers";
import AuthContext from "@/context/Auth/AuthContext";
import { gatewayCaptchaToken } from "@/utils/constants";
import AlertContext from "@/context/Alert/AlertContext";

const SignIn = () => {
  const { loading, adminLogin } = useContext(AuthContext);
  const { setErrorAlert } = useContext(AlertContext);

  const [buttonLoading, setButtonLoading] = useState(true);
  const [token, setToken] = useState("");

  const initialState = { email: "", password: "" };

  const { password, email } = validationSchema;

  const handleLoaded = (_: any) => {
    window.grecaptcha.ready((_: any) => {
      window.grecaptcha
        .execute(gatewayCaptchaToken, { action: "homepage" })
        .then((token: any) => {
          if (token) {
            setButtonLoading(false);
            setToken(token);
          } else {
            setErrorAlert("Recaptcha error, reload the page.");
          }
        })
        .catch((err: any) => {
          setErrorAlert("Recaptcha error, reload the page.");
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${gatewayCaptchaToken}`;
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  const handleSubmit = async (
    values: typeof initialState,
    { setSubmitting }: FormikHelpers<typeof initialState>
  ) => {
    if (!token || token.length === 0) {
      setErrorAlert("Verify reCaptcha");
      return;
    }
    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
      captchaToken: token,
    };

    // console.log("this is the data ", {data})
    await adminLogin(data);
  };

  return (
    <div className={styles["sign-in"]}>
      <Onboarding />
      <div className={styles["sign-in__container"]}>
        <Formik
          initialValues={initialState}
          validationSchema={yup.object({ password, email })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className={styles["sign-in__content"]}>
              <div className={styles["sign-in__content-container"]}>
                <div className={styles["sign-in__content-header"]}>
                  <div className={styles["sign-in__content-header-container"]}>
                    <h3>Welcome Back</h3>
                  </div>
                  <p className={styles["sign-in__content-header-title"]}>
                    Login to your Gateway Admin Dashboard
                  </p>
                </div>

                <div>
                  <Form>
                    <div className={styles["sign-in__form"]}>
                      <div className={styles["sign-in__form-input"]}>
                        <Input
                          id="email"
                          type="text"
                          name="email"
                          label="Email"
                          placeholder="Enter your Email"
                        />

                        <Input
                          id="password"
                          type="password"
                          name="password"
                          label="Password"
                          placeholder="Enter your Password"
                        />
                      </div>
                      {/* CAPTCHA */}
                      <div className="d-flex w-100 m-auto justify-content-center">
                        <div
                          className="g-recaptcha"
                          data-sitekey={gatewayCaptchaToken}
                          data-size="invisible"
                        ></div>
                      </div>

                      <Link to="/">Forgot Password?</Link>
                      <div className={styles["sign-in__form-button"]}>
                        <Button
                          type="submit"
                          label="Sign In"
                          variant="primary"
                          loading={loading || buttonLoading}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignIn;
