const ProductReducers = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "CLEAR_LOADING":
      return {
        ...state,
        loading: false,
        approveLoading: false,
        declineLoading: false,
        singleProductLoading: false,
        commentLoading: false,
        likeLoading: false,
      }


      case "SET_SINGLE_PRODUCT_LOADING":
        return {
          ...state,
          singleProductLoading: true,
      }

      case "APPROVE_LOADING":
        return {
          ...state,
          approveLoading: true,
        }
        case "DECLINE_LOADING":
          return {
            ...state,
            declineLoading: true,
          }
    case "SET_PRODUCT_HUNT":
      return {
        ...state,
        allProducts: action.payload,
      }

      case "SET_SINGLE_PRODUCT":
      return {
        ...state,
        singleProduct: action.payload,
      }



      case "SET_COMMENT_LOADING":
        return {
          ...state,
          commentLoading: true,
        }
  
        case "SET_LIKE_LOADING":
          return {
            ...state,
            likeLoading: true,
          }
          
          case "SET_COMMENT":
            return {
              ...state,
              comment: action.payload,
            }
          
          case "SET_PRODUCT_HUNT_COMMENT":
            return {
              ...state,
              productComment: action.payload,
            }
      
          case "SET_PRODUCT_COMMENTS_ON_COMMENTS":
            return {
              ...state,
              productCommentsOnComments: action.payload,
            };
      
      
      
          case "SET_COMMENTS":
            return {
              ...state,
              comments: action.payload,
            };

    default:
      return state
  }
}

export default ProductReducers
