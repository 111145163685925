import React from "react";
import styles from "./daily-quiz-submission-modal.module.scss";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import user from "../../assets/images/user1.svg";

const DailyQuizSubmissionModal = ({ submission }) => {
  return (
    <div className={styles["prompt__content"]}>
      <div className={styles["prompt__content__detaills"]}>
        <h3>Quiz submission</h3>

        <h4>User's details:</h4>
        <div className={styles["prompt__content__detaills--user"]}>
          <div>
            {submission?.user?.avatar === "" ? (
              <img
                src={user}
                alt="user"
                className={styles["prompt__content__detaills--user--img"]}
              />
            ) : (
              <img
                src={submission?.user?.avatar}
                alt="user"
                className={styles["prompt__content__detaills--user--img"]}
              />
            )}
          </div>
          <div>
            <h5>{submission?.user?.fullName}</h5>
            <h6>{submission?.user?.email}</h6>
          </div>
        </div>

        <div className={styles["prompt__content__detaills"]}>
          <div>
            <h4>Quiz (question and answer)</h4>
            {submission?.submissionOptions?.map((item) => (
              <div
                className={
                  item.isCorrect
                    ? styles["prompt__content__detaills__answer__success"]
                    : styles["prompt__content__detaills__answer__error"]
                }
              >
                <p>{item?.question?.text}</p>
                <span>
                  {item?.isCorrect ? (
                    <BsFillCheckCircleFill fill="green" />
                  ) : (
                    <AiFillCloseCircle fill="red" />
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyQuizSubmissionModal;
