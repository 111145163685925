import React from "react";
import { useContext, useEffect, useState } from "react";
import { Switch } from "antd";
import styles from "./quizzes-wrapper.module.scss";
import DailyQuiz from "../DailyQuiz";
import Spinner from "../Spinner";
import Search from "../Search";
import AdventureContext from "@/context/Adventure/AdventureContext";

const DailyQuizWrapper = () => {
  const {
    loading,
    dailyQuiz,
    fetchDailyQuizzes,
    getDailyQuiz,
    switchDailyQuiz,
    pagination,
    quizzes,
  } = useContext(AdventureContext);

  useEffect(() => {
    fetchDailyQuizzes();
    getDailyQuiz();
    // eslint-disable-next-line
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleSearch = (name) => {
    setSearchTerm(name);

    const filteredQuizzes = quizzes.filter((user) => {
      return Object.values(user)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setSearchResult(filteredQuizzes);
  };

  const onChange = () => {
    switchDailyQuiz();
  };

  const handlePaginationPrev = async () => {
    const query = `pageNumber=${pagination?.currentPage - 1}&pageSize=${
      pagination?.pageSize
    }`;
    await fetchDailyQuizzes(query);
  };

  const handlePaginationNext = async () => {
    const query = `pageNumber=${pagination?.currentPage + 1}&pageSize=${
      pagination?.pageSize
    }`;
    await fetchDailyQuizzes(query);
  };

  const quizLists = searchTerm === "" ? quizzes : searchResult;

  return loading ? (
    <Spinner />
  ) : (
    <div className={styles["quiz__wrapper"]}>
      <div>
        <h3 className={styles["quiz__wrapper__header"]}>
          Daily Quiz status: &nbsp;&nbsp;&nbsp;
          <Switch
            checked={dailyQuiz && dailyQuiz?.id}
            onChange={onChange}
          />{" "}
        </h3>
        <h3>Current Daily Quiz</h3>
        {dailyQuiz && dailyQuiz?.id ? (
          <div className={styles["quiz__wrapper__container"]}>
            <div className={styles["quiz__wrapper__grid"]}>
              <div>Quiz Desc.</div>
              <div>Date Added</div>
              <div>No. of Submissions</div>
              <div>Action</div>
            </div>
            <DailyQuiz quiz={dailyQuiz} />
          </div>
        ) : (
          <>
            <br />
            <h4>No Daily Quiz.</h4>
          </>
        )}
        <br />
        <br />
      </div>
      <Search handleChange={handleSearch} label="quiz" />
      <div className={styles["quiz__wrapper__container"]}>
        <div className={styles["quiz__wrapper__grid"]}>
          <div>Quiz Desc.</div>
          <div>Date Added</div>
          <div>No. of Submissions</div>
          <div>Action</div>
        </div>
        {loading ? (
          <Spinner />
        ) : quizzes?.length > 0 ? (
          quizLists?.map((quiz) => <DailyQuiz key={quiz.id} quiz={quiz} />)
        ) : (
          <>No quizzes available</>
        )}
      </div>
      <div className={styles["quiz__wrapper__pagination"]}>
        <span>
          Page {pagination?.currentPage} - {pagination?.totalPages}
        </span>
        <span>
          {(pagination?.currentPage - 1) * pagination?.pageSize + 1} -
          {pagination?.hasNext
            ? pagination?.pageSize * pagination?.currentPage
            : pagination?.totalCount}
        </span>
      </div>
      <div className={styles["quiz__wrapper__pagination"]}>
        {pagination?.hasPrevious ? (
          <button
            className={styles["quiz__wrapper__pagination__button"]}
            onClick={handlePaginationPrev}
            disabled={!pagination?.hasPrevious}
          >
            Prev
          </button>
        ) : null}
        {pagination?.hasNext ? (
          <button
            className={styles["quiz__wrapper__pagination__button"]}
            onClick={handlePaginationNext}
            disabled={!pagination?.hasNext}
          >
            Next
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default DailyQuizWrapper;
