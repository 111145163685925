import React from 'react';
import styles from "./loader.module.scss";

const Loader = () => {
    return (
        <div className={styles['loader']}>
            <div className={styles['loader__spinner-container']}>
                <div className={styles['loader__loading-spinner']}></div>
            </div>
        </div>
    );
};

export default Loader;