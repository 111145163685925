import React from 'react'
import styles from "./prompt.module.scss"
import { MdCancel, MdOutlineWarning } from "react-icons/md"

const Prompt = ({ handleClick, handleDelete, title, body, buttonText }) => {

  return (
    <div className={ styles["prompt"] }>
        <div className={ styles["prompt__content"] }>
        <span onClick={ () => handleClick() }><MdCancel size="1.5rem" /></span>
        <div className={ styles["prompt__content__detaills"] }>
            <MdOutlineWarning size="3rem" />
            <h2>{ title }</h2>
            <p style={{ color: "#FFF", borderBottom: "none", paddingBottom: "0" }}>{ body }</p>
            <div onClick={ handleDelete } className={ styles["prompt__content__detaills--btn"] }>{ buttonText }</div>
        </div>
        </div>
    </div>
  )
}

export default Prompt