import React from 'react'
import styles from "./overview.module.scss"
import StatsCardWrapper from '@/components/StatsCardWrapper'
import CurrentUser from '@/components/CurrentUser'

const Overview = () => {

  return (
    <div className={ styles["overview"] }>
      {/* <h2>Welcome, { currrentUser?.fullName }</h2>
      <p>This is the dashboard overview section. Gain insights into the performance of your project on the go </p><br /> */}
      <CurrentUser />
      <StatsCardWrapper />
    </div>
  )
}

export default Overview