const AdminReducers = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "CLEAR_LOADING":
      return {
        ...state,
        loading: false,
      }

    case "SET_ADMIN_DETAILS":
      return {
        ...state,
        adminDashboard: action.payload,
      }

    case "SET_CURRENT_ADMIN":
      return {
        ...state,
        currentUser: action.payload,
      }

    case "SET_WALLET":
      return {
        ...state,
        wallets: action.payload,
      }

    case "SET_ALL_USERS":
      return {
        ...state,
        users: action.payload,
      }
    
    case "SET_ALL_USERS_BY_EMAIL":
      return {
        ...state,
        usersByEmail: action.payload,
      }

    case "SET_ALL_USERS_BY_ADDRESS":
      return {
        ...state,
        usersByAddress: action.payload,
      }

    case "RESET_USERS_BY_ADDRESS":
      return {
        ...state,
        usersByAddress: action.payload, // or initialState value for usersByAddress
      }
  
    case "SET_CONFIGS":
      return {
        ...state,
        configs: action.payload,
      }

    case "SET_PAGINATION":
      return {
        ...state,
        pagination: action.payload,
      }

    case "SET_SINGLE_USER":
      return {
        ...state,
        user: action.payload,
      }

    case "SET_PENDING_COMMUNITIES":
      return {
        ...state,
        pendingCommunities: action.payload,
      }

    case "SET_APPROVED_COMMUNITIES":
      return {
        ...state,
        approvedCommunities: action.payload,
      }

    case "SET_DECLINED_COMMUNITIES":
      return {
        ...state,
        declinedCommunities: action.payload,
      }

    case "SET_COMMUNITY":
      return {
        ...state,
        singleCommunity: action.payload,
      }

    case "SET_ALL_COMMUNITIES":
      return {
        ...state,
        allCommunities: action.payload,
      }
    case "SET_EXCHANGE_RATES":
      return {
        ...state,
        exchangeRates: action.payload,
      }

    default:
      return state
  }
}

export default AdminReducers
