import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styles from "./product.module.scss"
import { IoArrowBack } from 'react-icons/io5';
import { SiTwitter } from "react-icons/si";
import { FaFacebookF, FaTelegramPlane } from 'react-icons/fa';
import { TbWorld } from "react-icons/tb";
import { BiSolidLike } from 'react-icons/bi';
import About from './About';
import ProductContext from '@/context/Product/ProductContext';
import Steps from './Steps';
import AlertContext from '@/context/Alert/AlertContext';
import Spinner from '@/components/Spinner';
import CommentsContainer from '@/components/CommentContainer';





const socialMediaIcons = {
    twitter: SiTwitter,
    telegram: FaTelegramPlane,
    facebook: FaFacebookF,
  };


const formatUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
};





const ProductDetails = () => {

    const { 
        declineProject, 
        approveProject, 
        approveLoading, 
        declineLoading,
        
        allProducts,
        getSingleProduct, 
        singleProductLoading,
        singleProduct,

        upvoteProject,
        // downVoteProject,
        productHuntId
    }  = useContext(ProductContext)

    const params = useParams();
    const id = params.id;
    const navigate = useNavigate()

    const [singleProducts, setSingleProduct] = useState(singleProduct);


    useEffect(() => {
        if (!singleProductLoading) {
            setSingleProduct(singleProduct);
        }
    }, [singleProductLoading, singleProduct]);


  const { setErrorAlert } = useContext(AlertContext);


  const handleWebsiteUrl = (url) => {
    if (url) {
      // Ensure the URL has the proper protocol
      const formattedUrl = url.startsWith('http://') || url.startsWith('https://') || url.startsWith('HTTPS://') ? url : `https://${url}`;
      window.open(formattedUrl, '_blank');
    } else {
        setErrorAlert("Website URL is not provided");
    }
  };

    const handleBack = () => {
        if (window.history.length > 1) {
          navigate(-1);
        } else {
          navigate('/dashboard/overview'); // Fallback route
        }
      };

    


    useEffect(() => {

        if (id) {
            getSingleProduct(id)
        }



    }, [id])
    


    const [activeTab, setActiveTab] = useState('description');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };



    const handleApprove = async (id) => {
  
        try {
          const response = await approveProject(id);
          return response;
        } catch (error) {
          return false;
        }
      };
      
    
      const handleDeny= async (id) => {
        try {
          const response = await declineProject(id);
          return response;
        } catch (error) {
         
        }
      };




    const [isLiked, setIsLiked] = useState(false);

    useEffect(() => {
        const likedStatus = localStorage.getItem(`isLiked-${singleProduct?.id}`);
        if (likedStatus === 'true') {
          setIsLiked(true);
        }
      }, [singleProduct?.id]);

    const handleThumbsUp = async (currentUpvotes, productId) => {
          // Optimistically increment the upvote count locally
          const updatedUpvotes = currentUpvotes + 1;
          setSingleProduct(prevProduct => ({
              ...prevProduct,
              _count: {
              ...prevProduct._count,
              upvotes: updatedUpvotes,
              },
          }));
          


          try {
              // Call the API to increment the upvote count on the backend
              const response = await upvoteProject(productId);
                  if (!response) {
                  throw new Error('Failed to upvote');
              }
              localStorage.setItem(`isLiked-${singleProduct?.id}`, 'true');
              setIsLiked(true);
          } catch (error) {
              // If the API call fails, revert the local upvote count and show an error alert
              setSingleProduct(prevProduct => ({
              ...prevProduct,
              _count: {
                  ...prevProduct._count,
                  upvotes: currentUpvotes,
              },
              }));
              alert('Failed to upvote. Please try again.');
          }


        // if(isLiked) {
        //     const updatedUpvotes = currentUpvotes - 1;
        //     setSingleProduct(prevProduct => ({
        //         ...prevProduct,
        //         _count: {
        //             ...prevProduct._count,
        //             upvotes: updatedUpvotes,
        //         },
        //     }));
        //     try {
        //         // Call the API to increment the upvote count on the backend
        //         const response = await downVoteProject(productId);
        //             if (!response) {
        //             throw new Error('Failed to downvote');
        //         }
        //         localStorage.setItem(`isLiked-${singleProduct?.id}`, 'false');
        //         setIsLiked(false);
        //     } catch (error) {
        //         // If the API call fails, revert the local upvote count and show an error alert
        //         setSingleProduct(prevProduct => ({
        //             ...prevProduct,
        //             _count: {
        //                 ...prevProduct._count,
        //                 upvotes: currentUpvotes,
        //             },
        //         }));
        //         alert('Failed to downvote. Please try again.');
        //     }

        // } else {
        //      // Optimistically increment the upvote count locally
        //     const updatedUpvotes = currentUpvotes + 1;
        //     setSingleProduct(prevProduct => ({
        //         ...prevProduct,
        //         _count: {
        //         ...prevProduct._count,
        //         upvotes: updatedUpvotes,
        //         },
        //     }));
            


        //     try {
        //         // Call the API to increment the upvote count on the backend
        //         const response = await upvoteProject(productId);
        //             if (!response) {
        //             throw new Error('Failed to upvote');
        //         }
        //         localStorage.setItem(`isLiked-${singleProduct?.id}`, 'true');
        //         setIsLiked(true);
        //     } catch (error) {
        //         // If the API call fails, revert the local upvote count and show an error alert
        //         setSingleProduct(prevProduct => ({
        //         ...prevProduct,
        //         _count: {
        //             ...prevProduct._count,
        //             upvotes: currentUpvotes,
        //         },
        //         }));
        //         alert('Failed to upvote. Please try again.');
        //     }
        // }

    
       
    };
    
      

    return (

        <div className={styles['product__details__container']}>
            <div className={styles['product__details__container__back']} onClick={handleBack}>
                <IoArrowBack size="1.2rem" fill="#999999" />
                <p>Go back</p>
            </div>
  

            {singleProductLoading ? (
                <div style={{
                    marginBottom: "50px"
                }}>
                    <Spinner />
                </div>
            ) : (
                <>
                
                    <div className={styles.topContainer}>
                        <div className={styles.productInfo}>
                            <div className={styles.productImage}>
                                <img src={singleProduct?.productLogo} alt="product logo" />
                            </div>

                            <div className={styles.productName}>
                                <p>{singleProduct?.name}</p>

                                <div className={styles.social}>
                                    {/* <a href="">
                                        <FaTelegramPlane fill="#999999" />
                                    </a>
                                    <a href="">
                                        <SiTwitter fill="#999999" />
                                    </a>
                                    <a href="">
                                        <FaFacebookF fill="#999999"/>
                                    </a> */}


                                    {singleProduct?.socialMedia?.map((social, index) => {
                                        const Icon = socialMediaIcons[social.name.toLowerCase()];

                                        return (
                                            Icon && (
                                                <a href={formatUrl(social.url)} key={index} target="_blank" rel="noopener noreferrer"  >
                                                    <Icon fill="#999999" />
                                                </a>
                                            )
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    



                        <div className={styles.buttonContainer}>
                            <button onClick={() => handleWebsiteUrl(singleProduct?.websiteUrl)}>
                                <TbWorld />
                                <p>Visit Website</p>
                            </button>

                            {/* <button onClick={()  => handleThumbsUp(singleProduct?._count?.upvotes, singleProduct?.id)}>
                                <BiSolidLike />
                                <p>Thumbs up ({singleProduct?._count?.upvotes})</p>
                            </button> */}

                            <button 
                                onClick={() => handleThumbsUp(singleProduct?._count?.upvotes, singleProduct?.id)}

                                // style={{
                                //     backgroundColor: isLiked ? 'blue' : '#ECECEC',
                                //     color: isLiked ? 'white' : "#E01414"
                                    
                                //   }}
                            >
                                <BiSolidLike />
                                <p>Thumbs up ({singleProducts?._count?.upvotes})</p>
                            </button>

                        </div>
                    </div>
                

                    <div className={styles.productTabContainer}>
                        <div>
                            <div className={styles.productTabs}>
                                <span
                                    onClick={() => handleTabChange('description')}
                                    className={`${styles["products__tabs--description"]} ${activeTab === 'description' ? styles.active : ''}`}
                                >
                                    Description
                                </span>
                                <span
                                    onClick={() => handleTabChange('steps')}
                                    className={`${styles["products__tabs--steps"]} ${activeTab === 'steps' ? styles.active : ''}`}
                                >
                                    Steps
                                </span>
                                <span
                                    onClick={() => handleTabChange('comments')}
                                    className={`${styles["products__tabs--comments"]} ${activeTab === 'comments' ? styles.active : ''}`}
                                >
                                    Comments
                                </span>
                                {/* <span
                                    onClick={() => handleTabChange('reports')}
                                    className={`${styles["products__tabs--reports"]} ${activeTab === 'reports' ? styles.active : ''}`}
                                >
                                    Reports
                                </span> */}
                            </div>

                            <div className={styles.buttonContainer}>
                                <button onClick={()=> handleApprove(productHuntId)}>{approveLoading ?  "approving. . .": "Approve"}</button>

                                <button onClick={()=> handleDeny(productHuntId)}>{declineLoading ? "Declining. . ." :  "Deny"}</button>
                            </div>
                        </div>

                        {activeTab === 'description' && <About  />}
                        {activeTab === 'steps' && <Steps  />}
                        {activeTab === 'comments' && <CommentsContainer />}
                        {/* {activeTab === 'reports' && "HELLO "} */}

                    </div>

                </>
                
            )}

           





        </div>
    )
}

export default ProductDetails