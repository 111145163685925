import * as yup from "yup";
import { phoneRegex } from "./constants";
import axios from "axios";

// import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

// import { storage } from "./firebase"; // Adjust the path as needed

export const validationSchema = {
  password: yup.string().required("Password is required"),
  dynamic: () =>
    yup
      .string()
      .required(
        "This field must not be empty"
      ),
  dynamicNumber: () =>
    yup
      .number()
      .required(
        "This field must not be empty and must be a number"
      ),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email address is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords do not match"),
  phoneNumber: yup.string().matches(phoneRegex, "Phone number is not valid"),
};

// Upload image
// export const UploadImage = async (image: any) => {

//   const formData = new FormData();
//   formData.append("file", image);
//   formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "ml_default");

//   const res = await axios.post(
//     `https://api.Cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`,
//     formData
//   );
//   const data = res.data;
//   return data;
// };


export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

// export const beforeUpload = (file: any) => {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     return "You can only upload JPG/PNG file!";
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     return "Image must smaller than 2MB!";
//   }
//   return isJpgOrPng && isLt2M;
// };


export const UploadImage = async (image:any) => {
  if (!image) throw new Error("No image file provided");

  const storageRef = ref(storage, `/images/${image.name}`);
  const uploadTask = uploadBytesResumable(storageRef, image);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot:any) => {
        // Handle progress if needed
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
      },
      (error:any) => {
        // Handle errors
        // console.log({error})
        reject(error);
      },
      async () => {
        // Handle successful uploads on complete
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve({ url: downloadURL });
      }
    );
  });
};
