import React from "react";
import styles from "./user-meta-info.module.scss";
import moment from "moment";

const UserMetaInfo = ({ user, date }) => {
  return (

    <>
 
      <div className={styles["user__meta__info"]}>
        <div className={styles["user__meta__info__intro"]}>
          <img
            src={user?.avatar ? user?.avatar : `/assets/images/muna.png`}
            style={{height: "30px", width: "30px", borderRadius: "50%", overflow: "hidden", backgroundColor: "black"}}
            alt="Profile avatar"
          />
          <div>
            <h6> {user?.fullName ? user?.fullName : `John Snow`} </h6>
            <span> {date ? moment(date).format("Do MMMM, YYYY, h:mm a"): `10th May, 2022 at 11:37 AM `} </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMetaInfo;
