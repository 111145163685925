import CommunitiesTableHead from "../CommunitiesTableHead";
import Community from "../Community";
import styles from "./community.module.scss";
import Spinner from "../Spinner";
import { useContext } from "react";
import AdminContext from "@/context/AdminContext/AdminContext";

const DisabledCommunities = () => {
  const { declinedCommunities, loading } = useContext(AdminContext);

  return (
    <div>
      <h3 style={{ fontFamily: "Cera Pro Bold", color: "#000" }}>
        Declined Communities
      </h3>
      <div className={styles["communities__table__container"]}>
        <CommunitiesTableHead />
        {loading ? (
          <Spinner />
        ) : !loading && declinedCommunities?.length > 0 ? (
          declinedCommunities?.map((community) => (
            <Community key={community?.id} community={community} />
          ))
        ) : (
          <p>There are no declined communities at the moment</p>
        )}
      </div>
    </div>
  );
};

export default DisabledCommunities;
