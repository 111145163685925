import React from 'react'
import styles from "../StatsCardWrapper/stats-card-wrapper.module.scss"
import StatsCard from '@/components/StatsCard'
import { HiUsers } from "react-icons/hi2"
// import { MdOutlineDashboardCustomize } from "react-icons/md"
import { useContext, useEffect } from 'react'
import AdminContext from '@/context/AdminContext/AdminContext'

const UsersStatsCardWrapper = () => {

  const { adminDashboard, getAdminDashboard } = useContext(AdminContext)

  useEffect(() => {
    getAdminDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ])

  const { userStat } = adminDashboard


  return (
    <div className={ styles["stats__card__wrapper"] }>
         <div className={ styles["stats__card__wrapper--content"] }>
          <StatsCard 
            title='Total Numbers of Users' 
            value={ userStat?.allUsers } 
            icon={ <HiUsers size="1.5rem" fill='#22bb33' color='red' /> } 
          />
                <StatsCard 
                  title='Referred Users' 
                  value={ userStat?.referredUsers } 
                  icon={ <HiUsers size="1.5rem" fill='#721C24' color='red' /> } 
                />
                <StatsCard 
                title='Normal Users' 
                value={ userStat?.normalUsers } 
                icon={ <HiUsers size="1.5rem" fill='#3F434A' color='red' /> } 
                />
                <StatsCard 
                title='Total Users Last Month' 
                value={ userStat?.totalUsersLastMonth } 
                icon={ <HiUsers size="1.5rem" fill='#753FF5' color='red' /> } 
                />
                <StatsCard 
                title='Total Users This Month' 
                value={ userStat?.totalUsersThisMonth } 
                icon={ <HiUsers size="1.5rem" fill='#FF6B1B' color='red' /> } 
                />
                <StatsCard 
                title='Referred Users Percentage' 
                value={ userStat?.referredUsersPercentage } 
                icon={ <HiUsers size="1.5rem" fill='#22bb33' color='red' /> } 
                />
                <StatsCard 
                title='Normal Users Percentage' 
                value={ userStat?.normalUsersPercentage } 
                icon={ <HiUsers size="1.5rem" fill='#22bb33' color='red' /> } 
                />
         </div>
    </div>
  )
}

export default UsersStatsCardWrapper